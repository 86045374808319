export const EXPAND_INVOICE_ROW = 'EXPAND_INVOICE_ROW';

export const expandInvoiceRow = id => ({
  type: EXPAND_INVOICE_ROW,
  id
});

const initialState = {
  expandedRowId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPAND_INVOICE_ROW:
      return {
        ...state,
        expandedRowId: state.expandedRowId === action.id ? null : action.id
      };
    default:
      return state;
  }
};
