import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import Icon from '../../components/Icon';

export const headerHeight = '50px';
export const mobileHeaderHeight = '58px';

const borderRight = css`
  ${props =>
    props.borderRight &&
    css`
      position: relative;
      ::after {
        content: '';
        position: absolute;
        height: 2rem;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        background-color: ${props => props.theme.dustierGray};
      }
    `}
`;

export const NavbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${props => (props.sticky ? 'fixed' : 'absolute')};
  right: ${props => (!props.open ? '0' : '7.6rem')};
  top: 0;
  height: 40px;
  z-index: ${props =>
    props.sticky
      ? '4'
      : !props.open
      ? '2'
      : '5'}; // open state is related to LanguageDropDown component
  ${props => (props.desktop && !props.sticky) || 'background: #f3f3f3;'}
  ${props =>
    (props.desktop && !props.sticky) ||
    'box-shadow: 0px 2px 4px rgba(134, 134, 134, 0.36);'};
  @media screen and (min-width: 1024px) {
    height: 50px;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${props =>
    props.sticky &&
    css`
      animation: fade-in 0.5s forwards;
    `}
`;

export const NavButtonLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1rem;
  &:not(.active) {
    ${borderRight}
  }
  > h5 {
    margin: 0 5px 0 5px;
    font-weight: 400;
  }
  &.active {
    background-color: ${props => props.theme.brand};
    color: white;
    svg {
      fill: white;
    }
  }
  ${props =>
    props.hover &&
    css`
      transition: fill 0.1s ease-in;
      fill: ${props => props.theme.brand};
    `}
`;

export const NavButtonAction = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 12px;
  padding-left: 7px;
  cursor: pointer;
  ${borderRight}
  :hover {
    transition: fill 0.1s ease-in;
    fill: ${props => props.theme.brand};
  }
`;

export const NavIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 5px;
`;
