//@flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation, Trans, TFunction } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import Input from '../../components/Input';
import handleSubmit from './handleSubmit';
import { requiredField } from '../../utilities/validations';
import { type FormProps } from 'redux-form';
import SubmitButton from '../../components/SubmitButton';
import {
  SignInDiv,
  SignInBackground,
  ActionsDiv,
  SignUpP,
  GirlImg,
  SignInMessageP
} from './SignInStyles';
import { BrandedStyledLink, ActionLink } from '../../components/LinkStyles';
import signInGirl from '../../assets/images/sign-in-girl.png';
import { withTheme } from 'styled-components';
import LanguageDropDown from '../../components/LanguageDropDown/LanguageDropDown';
import { PositionOnSignInPage } from '../../components/LanguageDropDown/LanguageListItemStyles';

type Props = {
  signInMessage?: string,
  theme: { isWhiteLabel: boolean },
  t: TFunction,
  languageDropDownOpen: boolean
} & FormProps;

class SignIn extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <SignInBackground>
        <SignInDiv>
          <h1>{t('signin.header')}</h1>
          {this.props.signInMessage && (
            <SignInMessageP>{this.props.signInMessage}</SignInMessageP>
          )}
          <form onSubmit={this.props.handleSubmit}>
            <Field
              name="email"
              label={t('signin.form.email')}
              type="email"
              autocomplete="email"
              component={Input}
              validate={requiredField}
            />
            <Field
              name="password"
              label={t('signin.form.password')}
              type="password"
              autocomplete="current-password"
              component={Input}
              validate={requiredField}
            />
            <SubmitButton
              dataCy="signin-submit"
              value={t('signin.form.button')}
              disabled={this.props.submitting}
              error={this.props.error || this.props.signInErrorMessage}
              marginTop="3rem"
            />
          </form>
          <div>
            <ActionsDiv>
              <ActionLink to="/password_resets">
                {t('signin.actionButtons.passwordReset')}
              </ActionLink>
              <ActionLink to="/resend_confirmation">
                {t('signin.actionButtons.confirmationEmail')}
              </ActionLink>
            </ActionsDiv>
            <SignUpP>
              <Trans t={t} i18nKey="signin.actionButtons.signup">
                Don&lsquo;t have an account?{' '}
                <BrandedStyledLink to="/sign-up" underline>
                  Sign up
                </BrandedStyledLink>
              </Trans>
            </SignUpP>
          </div>
          {!this.props.theme.isWhiteLabel && (
            <GirlImg src={signInGirl} alt="" />
          )}
        </SignInDiv>
        <PositionOnSignInPage open={this.props.languageDropDownOpen}>
          <LanguageDropDown />
        </PositionOnSignInPage>
      </SignInBackground>
    );
  }
}

const mapStateToProps = state => ({
  signInErrorMessage: state.signIn.signInErrorMessage,
  signInMessage: state.signIn.signInMessage,
  languageDropDownOpen: state.global.language.languageDropDownOpen
});

export default withTheme(
  connect(mapStateToProps)(
    reduxForm({ form: 'signIn', onSubmit: handleSubmit })(
      withTranslation(['unauthenticated'])(SignIn)
    )
  )
);
