// @flow
import React, { Component } from 'react';
import { CollateralItemDiv } from './MarketingStyles';

type Props = {
  label: string,
  description: ?string,
  href?: string,
  onClick?: () => void,
  linkText: string
};

export default class CollateralItem extends Component<Props> {
  render() {
    return (
      <CollateralItemDiv>
        <h4>{this.props.label}</h4>
        <p>{this.props.description}</p>
        <a href={this.props.href} onClick={this.props.onClick}>
          {this.props.linkText}
        </a>
      </CollateralItemDiv>
    );
  }
}
