export const SET_LIVE_CHAT_STARTED = 'SET_LIVE_CHAT_STARTED';

export const setLiveChatStarted = () => ({
  type: SET_LIVE_CHAT_STARTED
});

const initalState = {
  liveChatStarted: false
};

export default (state = initalState, action) => {
  switch (action.type) {
    case SET_LIVE_CHAT_STARTED:
      return {
        ...state,
        liveChatStarted: true
      };

    default:
      return state;
  }
};
