import { takeLatest, put } from 'redux-saga/effects';
import { getRequest, handleSagaError } from '../../utilities/requests';
import {
  LOAD_REFUND_DATA,
  setNewRefundData,
  LOAD_MANAGE_REFUND_DATA,
  setManageRefundData
} from './reducer';
import { history } from '../../utilities/history';

function doRefunds(id) {
  const url = `/refund/${id}/new`;
  return getRequest(url);
}

function* handleLoadRefunds(action) {
  try {
    const response = yield doRefunds(action.id);
    yield put(setNewRefundData({ ...response.data, transactionId: action.id }));
  } catch (error) {
    history.goBack();
    yield handleSagaError(error);
  }
}

export function* loadRefundsSaga() {
  yield takeLatest([LOAD_REFUND_DATA], handleLoadRefunds);
}

function doLoadManageRefundData(id) {
  const url = `/refund/${id}`;
  return getRequest(url);
}

function* handleLoadManageRefundData(action) {
  try {
    const response = yield doLoadManageRefundData(action.id);
    const data = {
      refund_history: response.data.refund_history,
      iso4217: response.data.iso4217,
      new_refund: response.data.new_refund,
      settlement_method: response.data.settlement_method
    };
    yield put(setManageRefundData(data));
  } catch (error) {
    history.goBack();
    yield handleSagaError(error);
  }
}

export function* loadManageRefundDataSaga() {
  yield takeLatest([LOAD_MANAGE_REFUND_DATA], handleLoadManageRefundData);
}
