//@flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  routes,
  formatRoute,
  hasReadPermission
} from '../../../utilities/sidebarItems';
import { withRouter } from 'react-router-dom';
import { SidebarClosedContainerDiv } from './SidebarClosedStyles';
import SidebarItem from './SidebarItem.jsx';
import { type Permissions, type User } from '../../../utilities/types';
import { type TooltipProps } from '.';
type Props = {
  ...$Exact<TooltipProps>,
  location: Location,
  permissions: Permissions,
  user: User,
  sidebarToggle: boolean,
  userType: string,
  t: TFunction
};

class SidebarClosed extends Component<Props> {
  render() {
    return (
      <SidebarClosedContainerDiv>
        {routes(this.props.t).map((navItem, i) => {
          if (
            hasReadPermission(
              navItem.key,
              this.props.permissions,
              this.props.user
            )
          ) {
            const route = formatRoute(navItem.key);
            const { pathname } = this.props.location;
            const active =
              route === '/' ? pathname === route : pathname.startsWith(route);
            return (
              <SidebarItem
                key={navItem.key}
                index={i}
                iconName={navItem.key}
                name={navItem.value}
                formattedNavItem={route}
                active={active}
                tooltip={this.props.tooltip}
                showTooltip={this.props.showTooltip}
                hideTooltip={this.props.hideTooltip}
                sidebarToggle={this.props.sidebarToggle}
                userType={this.props.userType}
              />
            );
          }
          return null;
        })}
      </SidebarClosedContainerDiv>
    );
  }
}

export default withRouter(withTranslation(['global'])(SidebarClosed));
