import { SET_INVOICES } from './invoices';
import { ROUTE_CHANGED } from '../../../../reducer';
export const CHANGE_PAGE = 'invoices/CHANGE_PAGE';
export const SET_STATUS_FILTER = 'invoices/SET_STATUS_FILTER';
export const SET_EMAIL_STATUS_FILTER = 'invoices/SET_EMAIL_STATUS_FILTER';
export const SET_SEARCH_FILTER = 'invoices/SET_SEARCH_FILTER';
export const TOGGLE_CALENDAR_FILTER = 'invoices/TOGGLE_CALENDAR_FILTER';
export const CLEAR_CALENDAR_FILTER = 'invoices/CLEAR_CALENDAR_FILTER';
export const SET_CALENDAR_START_FILTER = 'invoices/SET_CALENDAR_START_FILTER';
export const SET_CALENDAR_END_FILTER = 'invoices/SET_CALENDAR_END_FILTER';
export const SET_CALENDAR_SAGA = 'invoices/SET_CALENDAR_SAGA';
export const SET_PER_PAGE_FILTER = 'invoices/SET_PER_PAGE_FILTER';

export const changePage = page => ({
  type: CHANGE_PAGE,
  page
});

export const setStatusFilter = status => ({
  type: SET_STATUS_FILTER,
  status
});

export const setEmailStatusFilter = status => ({
  type: SET_EMAIL_STATUS_FILTER,
  status
});

export const setSearchFilter = searchValue => ({
  type: SET_SEARCH_FILTER,
  searchValue
});

export const toggleCalendarFilter = () => ({
  type: TOGGLE_CALENDAR_FILTER
});

export const clearCalendarFilter = () => ({
  type: CLEAR_CALENDAR_FILTER
});

export const setCalendarStartFilter = calendarStart => ({
  type: SET_CALENDAR_START_FILTER,
  calendarStart
});

export const setCalendarEndFilter = calendarEnd => ({
  type: SET_CALENDAR_END_FILTER,
  calendarEnd
});

export const setCalendarSaga = () => ({
  type: SET_CALENDAR_SAGA
});

export const setPerPageFilter = perPage => ({
  type: SET_PER_PAGE_FILTER,
  perPage
});

const initialState = {
  transactionStatus: null,
  emailStatus: null,
  search: '',
  calendarFilterState: false,
  startDate: null,
  endDate: null,
  perPage: 10,
  page: 1,
  visitedPages: [1],
  pageCount: 0
};

const resetPageState = {
  page: 1,
  visitedPages: [1]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICES:
      return {
        ...state,
        pageCount: Math.ceil(action.payload.count / state.perPage)
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        visitedPages: state.visitedPages.includes(action.page)
          ? state.visitedPages
          : [...state.visitedPages, action.page]
      };
    case SET_STATUS_FILTER:
      return {
        ...state,
        transactionStatus: action.status,
        ...resetPageState
      };
    case SET_EMAIL_STATUS_FILTER:
      return {
        ...state,
        emailStatus: action.status,
        ...resetPageState
      };
    case SET_SEARCH_FILTER:
      return {
        ...state,
        search: action.searchValue,
        ...resetPageState
      };
    case TOGGLE_CALENDAR_FILTER:
      return {
        ...state,
        calendarFilterState: !state.calendarFilterState,
        ...resetPageState
      };
    case SET_CALENDAR_START_FILTER:
      return {
        ...state,
        startDate: action.calendarStart
      };
    case SET_CALENDAR_END_FILTER:
      return {
        ...state,
        endDate: action.calendarEnd,
        ...resetPageState
      };
    case CLEAR_CALENDAR_FILTER:
      return {
        ...state,
        startDate: null,
        endDate: null,
        ...resetPageState
      };
    case SET_PER_PAGE_FILTER:
      return {
        ...state,
        perPage: action.perPage,
        ...resetPageState
      };
    case ROUTE_CHANGED:
      if (
        action.to.match(/\/invoices\/\d+/) ||
        action.from.match(/\/invoices\/\d+/)
      ) {
        return state;
      }
      return {
        ...state,
        ...resetPageState
      };
    default:
      return state;
  }
};
