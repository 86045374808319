import { takeLatest, put, select, debounce } from 'redux-saga/effects';
import { setInvoices, LOAD_INVOICES } from './reducer/invoices';
import { getRequest } from '../../../../utilities/requests';
import { history } from '../../../../utilities/history';
import {
  CHANGE_PAGE,
  SET_STATUS_FILTER,
  SET_EMAIL_STATUS_FILTER,
  SET_SEARCH_FILTER,
  SET_CALENDAR_SAGA,
  CLEAR_CALENDAR_FILTER,
  SET_PER_PAGE_FILTER
} from './reducer/filters';
import { ROUTE_CHANGED } from '../../../reducer';

function* invoicesFilters() {
  const limit = yield select(
    state => state.invoices.invoices.index.filters.perPage
  );
  const offset = yield select(
    state => state.invoices.invoices.index.filters.page
  );
  const transactionStatus = yield select(
    state => state.invoices.invoices.index.filters.transactionStatus
  );
  const emailStatus = yield select(
    state => state.invoices.invoices.index.filters.emailStatus
  );
  const search = yield select(
    state => state.invoices.invoices.index.filters.search
  );
  const startDate = yield select(
    state => state.invoices.invoices.index.filters.startDate
  );
  const endDate = yield select(
    state => state.invoices.invoices.index.filters.endDate
  );
  const config = {
    limit,
    offset
  };
  Object.assign(config, {
    transaction_status: transactionStatus,
    email_status: emailStatus,
    search
  });
  if (startDate && endDate) {
    config.start_date = startDate;
    config.end_date = endDate;
  }
  if (history.location.pathname.endsWith('sandbox')) {
    config.sandbox_mode = true;
  }
  return config;
}

function* handleLoadInvoices() {
  try {
    const url = '/invoices';
    const params = yield invoicesFilters();
    const response = yield getRequest(url, params);
    yield put(setInvoices(response.data));
  } catch (error) {
    history.push('/error');
  }
}

export function* invoicesSaga() {
  yield takeLatest(
    [
      LOAD_INVOICES,
      CHANGE_PAGE,
      SET_STATUS_FILTER,
      SET_EMAIL_STATUS_FILTER,
      SET_CALENDAR_SAGA,
      CLEAR_CALENDAR_FILTER,
      SET_PER_PAGE_FILTER
    ],
    handleLoadInvoices
  );
}

export function* invoicesSearchSaga() {
  yield debounce(200, SET_SEARCH_FILTER, handleLoadInvoices);
}

function* handleRouteChange(action) {
  const verified = yield select(state => (state.global.user || {}).verified);
  if (
    verified &&
    ((action.to === '/invoices' && action.from === '/invoices/sandbox') ||
      (action.to === '/invoices/sandbox' && action.from === '/invoices'))
  ) {
    yield handleLoadInvoices(action);
  }
}

export function* invoicesRouteSaga() {
  yield takeLatest(ROUTE_CHANGED, handleRouteChange);
}
