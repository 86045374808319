//@flow
import React from 'react';
import PageDropdown from '.';
import { FilledSelectContainer, FilledSelectWrapper } from './styles';
import IconComponent from '../../Icon';

type Props = {
  children?: Node,
  Icon?: Node,
  label?: string,
  optionData: Array<{ text: string, value: string }>,
  disabled?: boolean,
  height?: number,
  className?: string
} & HOCProps;

type HOCProps = {
  handleClickOption: (option: any) => void,
  toggleDropdown?: () => void,
  activeOption?: { text: string, value: string },
  initialOption?: { text: string, value: string }
};

export default function FilledDropdown({
  Icon,
  label,
  optionData,
  handleClickOption,
  disabled,
  height = 47,
  className,
  initialOption
}: Props) {
  const handleClick = toggleDropdown => {
    if (disabled) return;
    toggleDropdown();
  };
  return (
    <PageDropdown
      Select={props => (
        <FilledSelectContainer
          className={className}
          onClick={() => handleClick(props.toggleDropdown)}
        >
          <FilledSelectWrapper>
            <h3>{label || props.activeOption?.text || 'default text'}</h3>
            {Icon || <IconComponent name="chevronDown" />}
          </FilledSelectWrapper>
          {props.children}
        </FilledSelectContainer>
      )}
      height={height}
      optionData={optionData}
      handleClickOption={handleClickOption}
      initialOption={initialOption}
    />
  );
}
