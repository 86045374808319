//@flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import Profile from '../Profile';
import {
  routes,
  formatRoute,
  hasReadPermission
} from '../../../utilities/sidebarItems';
import { withRouter } from 'react-router-dom';
import {
  SidebarOpenedContainerDiv,
  ProfileWrapperDiv
} from './SidebarOpenedStyles';
import SidebarItem from './SidebarItem.jsx';
import { type Permissions, type User } from '../../../utilities/types';

type Props = {
  location: Location,
  permissions: Permissions,
  user: User,
  brandLogo: ?string,
  userType: string,
  t: TFunction
};

class SidebarOpened extends Component<Props> {
  render() {
    return (
      <SidebarOpenedContainerDiv>
        <ProfileWrapperDiv>
          <Profile logo={this.props.brandLogo} />
        </ProfileWrapperDiv>
        {routes(this.props.t).map((navItem, i) => {
          if (
            hasReadPermission(
              navItem.key,
              this.props.permissions,
              this.props.user
            )
          ) {
            const route = formatRoute(navItem.key);
            const { pathname } = this.props.location;
            const active =
              route === '/' ? pathname === route : pathname.startsWith(route);
            return (
              <SidebarItem
                key={i}
                index={i}
                iconName={navItem.key}
                name={navItem.value}
                opened
                formattedNavItem={route}
                active={active}
                userType={this.props.userType}
              />
            );
          }
          return null;
        })}
      </SidebarOpenedContainerDiv>
    );
  }
}

export default withRouter(withTranslation(['global'])(SidebarOpened));
