import { combineReducers } from 'redux';
import plugins from './plugins';
import view from './view';

export const ADD_HP_PLUGIN = 'ADD_HP_PLUGIN';
export const LOAD_HP_PLUGINS = 'LOAD_HP_PLUGINS';
export const DEACTIVATE_HP_PLUGIN = 'DEACTIVATE_HP_PLUGIN';
export const DELETE_HP_PLUGIN = 'DELETE_HP_PLUGIN';
export const SET_HP_PLUGIN_DELETED = 'SET_HP_PLUGIN_DELETED';
export const UPDATE_HP_PLUGIN = 'UPDATE_HP_PLUGIN';
export const ACTIVATE_HP_PLUGIN = 'ACTIVATE_HP_PLUGIN';

export const activateHpPlugin = id => ({
  type: ACTIVATE_HP_PLUGIN,
  id
});
export const updateHpPlugin = device => ({
  type: UPDATE_HP_PLUGIN,
  device
});
export const setHpPluginDeleted = id => ({
  type: SET_HP_PLUGIN_DELETED,
  id
});
export const deleteHpPlugin = id => ({
  type: DELETE_HP_PLUGIN,
  id
});
export const deactivateHpPlugin = id => ({
  type: DEACTIVATE_HP_PLUGIN,
  id
});
export const loadHpPlugins = () => ({
  type: LOAD_HP_PLUGINS
});
export const addHpPlugin = device => ({
  type: ADD_HP_PLUGIN,
  device
});

const reducer = combineReducers({
  plugins,
  view
});

export default reducer;
