import { SET_INITIAL_STATE } from '../../reducer';

const TOGGLE_EDIT_BRANDING_MODE = 'TOGGLE_EDIT_BRANDING_MODE';

export const toggleBrandingEditMode = editModeStatus => ({
  type: TOGGLE_EDIT_BRANDING_MODE,
  editModeStatus
});

const initialState = {
  editMode: false,
  companyDisplayName: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_BRANDING_MODE:
      return {
        ...state,
        editMode: action.editModeStatus
      };
    case SET_INITIAL_STATE:
      return {
        ...state,
        companyDisplayName: action.payload.branding_company_display_name
      };
    default:
      return state;
  }
};
