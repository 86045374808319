//@flow
/* eslint-disable no-unsafe-finally */
import i18n from '../i18n';
import { type Currency } from './types';
type Value = {
  amount: number | string,
  currency: string,
  symbol: string,
  precision: number,
  formatToParts: boolean,
  fixed?: boolean
};

export function numberWithDelimiter(x: number) {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const formatI18nCurrency = (
  amount: number | string,
  currency: Currency,
  symbol: boolean = true,
  formatToParts: boolean = false,
  fixed: boolean = false
) => {
  if (!currency) {
    /**
     * @description For development. Currency should never be undefined in Production
     */
    return;
  }
  switch (currency.currency_type) {
    case 'fiat':
      return translateCurrency(
        amount,
        currency,
        'fiat',
        symbol,
        formatToParts,
        fixed
      );
    case 'custom':
    case 'crypto':
    case 'erc20_token':
      return translateCurrency(
        amount,
        currency,
        'crypto',
        symbol,
        formatToParts
      );
    default:
      return '';
  }
};

export const formatI18nAmount = (
  amount: number | string,
  currency: Currency,
  formatToParts: boolean = false,
  fixed: boolean = false
) => formatI18nCurrency(amount, currency, false, formatToParts, fixed);
// /**
//  * @description Returns formatI18nCurrency without currency prefix of suffix
//  */
// formatI18nCurrency(amount, currency, false, formatToParts);

export const formatI18nPercent = (value: string | number) =>
  i18n.t(`global:currency.percent`, { value: Number(value) / 100 });

const translateCurrency = (
  amount: number | string,
  currency: Currency,
  type: string,
  symbol: boolean,
  formatToParts: boolean,
  fixed?: boolean
) =>
  i18n.t(`global:currency.${type}`, {
    value: {
      amount,
      // netcents-web: currency.iso, gateway-web: currency.iso4217
      currency: currency.iso4217 || currency,
      symbol,
      precision: currency.precision,
      formatToParts,
      fixed
    }
  });

export const intlNumberFormat = ({
  currency = undefined,
  lng,
  minDigits = 0,
  maxDigits = 20,
  style = 'currency'
}: {
  currency?: string | typeof undefined,
  lng: string[],
  minDigits?: number,
  maxDigits?: number,
  style?: 'currency' | 'percent' | 'decimal'
} = {}) =>
  Intl.NumberFormat(lng, {
    style,
    currency,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits
  });

// $FlowFixMe
const handleFormatToParts = (intl, value) => intl.formatToParts(value.amount);
const handleFormatting = (intl, value) => intl.format(Number(value.amount));

const handleSupportedCurrencies = ({ intl, value, supported = true } = {}) => {
  const curArr = handleFormatToParts(intl, value);
  /**
   * @description Move Crypto Iso's for locales with prefix symbol to suffix
   */
  if (curArr[0].type === 'currency') {
    let cur;

    if (supported) {
      cur = curArr.shift();
    } else {
      curArr.shift();
      cur = { type: 'currency', value: value.currency };
    }
    const space = curArr.shift();
    curArr.push(space, cur);

    return curArr.map(({ value }) => value).reduce((acc, part) => acc + part);
  } else {
    /**
     * @description If locale has Iso as suffix, keep it that way
     */
    return handleFormatting(intl, value);
  }
};

const convertToIntl = (value, lng, currency = value.currency) => {
  let minDigits;
  if (Number(value.amount) > 0) {
    /**
     * @description Set a precision amount if the value is non-zero
     */
    minDigits = value.precision;
  }
  return intlNumberFormat({
    style: value.symbol ? 'currency' : 'decimal',
    currency,
    lng,
    minDigits
  });
};

const configureFormattedParts = parts => {
  let str = '';

  for (const p in parts) {
    if (parts[p].type === 'group') {
      str += parts[p].value;
    }
  }

  for (const p in parts) {
    if (parts[p].type === 'decimal') {
      str += parts[p].value;
    }
  }

  return str;
};

const handleReturnType = (value, intl, supported = true) => {
  if (value.symbol) {
    /**
     * @description Return i18n currency without Iso
     */
    return handleSupportedCurrencies({
      intl,
      value,
      supported
    });
  }

  if (value.formatToParts) {
    /**
     * @description Return Intl Formatted to Parts (React-HighCharts requirement)
     */
    const parts = handleFormatToParts(intl, value);
    return configureFormattedParts(parts);
  }
  return handleFormatting(intl, value);
};

export const handleCrypto = (value: Value, lng: string[]) => {
  try {
    var intl = convertToIntl(value, lng);
  } catch (e) {
    /**
     *  @description Catch currencies that aren't recognized by Intl API e.g. NCCO
     */
  } finally {
    if (intl) {
      return handleReturnType(value, intl);
    } else {
      /**
       * @description Format currency with properites similar to bitcoin if currency doesn't exist in Intl
       */
      const fallbackIntl = convertToIntl(value, lng, 'BTC');
      return handleReturnType(value, fallbackIntl, false);
    }
  }
};

export const handleFiat = (value: Value, lng: string[]) => {
  const intl = intlNumberFormat({
    style: value.symbol ? 'currency' : 'decimal',
    currency: value.currency,
    lng,
    maxDigits: 2,
    minDigits: value.fixed ? 0 : 2
  });

  if (value.formatToParts) {
    /**
     * @description Return Intl Formatted to Parts (React-HighCharts requirement)
     */
    const parts = handleFormatToParts(intl, value);
    return configureFormattedParts(parts);
  }

  return handleFormatting(intl, value);
};

export const handleSymbol = (value: Value, lng: string[]) => {
  const formattedCur = intlNumberFormat({ currency: value.currency, lng });
  // $FlowFixMe
  const curArr = handleFormatToParts(formattedCur, 0);
  let iso;
  for (const cur of curArr) {
    if (cur.type === 'currency') {
      iso = cur.value;
    }
  }
  return iso;
};
