import { createGlobalStyle } from 'styled-components/macro';
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * canada-type-gibson:
 *   - http://typekit.com/eulas/00000000000000003b9b0ba4
 *   - http://typekit.com/eulas/00000000000000003b9b0ba5
 *   - http://typekit.com/eulas/00000000000000003b9b0ba8
 * museo-sans:
 *   - http://typekit.com/eulas/00000000000000000000e7fe
 *   - http://typekit.com/eulas/00000000000000000000e802
 *   - http://typekit.com/eulas/00000000000000000000e803
 *   - http://typekit.com/eulas/00000000000000000000e805
 *
 * © 2009-2018 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2018-06-14 17:59:03 UTC"}*/
export default createGlobalStyle`

@import url('https://p.typekit.net/p.css?s=1&k=jds7wrn&ht=tk&f=2005.2006.2007.2009.24352.24354.24355&a=11382389&app=typekit&e=css');

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('opentype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/a28b50/00000000000000000000e803/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/a28b50/00000000000000000000e803/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/a28b50/00000000000000000000e803/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'museo-sans';
  src: url('https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'canada-type-gibson';
  src: url('https://use.typekit.net/af/b01614/00000000000000003b9b0ba4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b01614/00000000000000003b9b0ba4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b01614/00000000000000003b9b0ba4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'canada-type-gibson';
  src: url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'canada-type-gibson';
  src: url('https://use.typekit.net/af/795dd6/00000000000000003b9b0ba8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/795dd6/00000000000000003b9b0ba8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/795dd6/00000000000000003b9b0ba8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 600;
}
`;
