// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Transition } from 'react-transition-group';

import Conductor from './Conductor';
import { closeModal } from './reducer';

Modal.setAppElement('#root');

const overlayStyles = {
  zIndex: '110',
  transition: 'opacity 0.2s ease'
};
const contentStyles = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '0',
  border: 'none',
  borderTop: '2px solid black',
  boxShadow: '1px -1px 16px 1px #dcdcdc',
  maxHeight: '90vh',
  transition: 'transform 0.2s ease',
  opacity: '1',
  overflow: 'unset',
  padding: '0'
};

type Props = {
  modal: string,
  closeModal: () => void,
  payload: {},
  preventClose: boolean
};

class ModalConductor extends Component<Props> {
  modalStyles = state => {
    if (['entering', 'exiting'].includes(state)) {
      return {
        overlay: { ...overlayStyles, opacity: '0' },
        content: {
          ...contentStyles,
          opacity: 1,
          transform: 'translate(-50%, -30%) scale(0.8)'
        }
      };
    } else {
      return {
        overlay: overlayStyles,
        content: contentStyles
      };
    }
  };

  handleOnRequestClose = () => {
    if (this.props.preventClose) {
      return undefined;
    }
    return this.props.closeModal;
  };

  render() {
    return (
      <Transition
        in={Boolean(this.props.modal)}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: 200 }}
      >
        {state => (
          <Modal
            isOpen
            onRequestClose={this.handleOnRequestClose()}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            style={this.modalStyles(state)}
            contentLabel="Modal"
          >
            {/* $FlowFixMe */}
            <Conductor {...this.props} />
          </Modal>
        )}
      </Transition>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.modalConductor.modal,
  payload: state.modalConductor.payload,
  language: state.global.language.activeLanguage.locale
});

const mapDispatchToProps = {
  closeModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalConductor);
