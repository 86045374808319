import styled from 'styled-components/macro';
import backgroundMobile from '../../assets/images/signup-bg-375.jpg';
import backgroundTablet from '../../assets/images/signup-bg-768.jpg';
import backgroundDesktop from '../../assets/images/signup-bg-1440.jpg';
import { PrimaryButtonLink } from '../../components/ButtonStyles';
import { mobileHeaderHeight } from '../../modules/Navbar/NavbarStyles';

export const SignUpDiv = styled.div`
  width: 425px;
  max-width: 90vw;
  margin: 30px auto;
  @media screen and (min-width: 768px) {
    width: 650px;
    max-width: 75vw;
    padding: 6rem 8rem;
    border-top: 2px solid black;
    background-color: white;
    border: ${props => props.theme.defaultBorder};
  }
  @media screen and (min-width: 1024px) {
    padding: 6rem 10rem;
  }
`;

export const SignUpForm = styled.form`
  @media screen and (max-width: 767px) {
    background-color: white;
    border: ${props => props.theme.defaultBorder};
    padding: 2rem 3rem 6rem;
    margin-top: 2rem;
  }
`;

export const SignUpBackground = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: top center url(${backgroundMobile});
  background-size: cover;
  padding-top: ${mobileHeaderHeight};
  padding-bottom: 5rem;
  @media screen and (min-width: 768px) {
    padding-top: 5rem;
    background-image: url(${backgroundTablet});
  }
  @media screen and (min-width: 1024px) {
    background-image: url(${backgroundDesktop});
  }
`;

export const SignUpH1 = styled.h1`
  line-height: 2.5rem;
  text-align: center;
  text-transform: none;
  max-width: 32rem;
  margin: 0 auto;
  margin-top: 5rem;
  font-size: 2.2rem;
  @media screen and (min-width: 768px) {
    margin-bottom: 4rem;
    font-size: 3rem;
    line-height: 3.5rem;
  }
`;

export const SignInP = styled.p`
  color: ${props => props.theme.dustyGray};
  text-align: center;
  margin-top: 2rem;
  @media screen and (max-width: 767px) {
    max-width: 16rem;
    margin: 2rem auto 0;
  }
`;

export const ThankYouCardDiv = styled.div`
  @media screen and (max-width: 767px) {
    width: 325px;
    height: 500px;
    margin: auto;
    background: ${props => props.theme.white};
    padding: 45px;
    border: ${props => props.theme.defaultBorder};
  }
`;

export const ThankYouP = styled.p`
  text-align: center;
  margin: 20px 0;
`;

export const ThankYouGirlDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThankYouGirlImg = styled.img`
  margin-top: 40px;
  width: 80px;
  @media screen and (min-width: 768px) {
    width: 120px;
    margin-top: 60px;
    margin-bottom: 100px;
  }
`;

export const ThankYouButton = styled(PrimaryButtonLink)`
  margin-top: 20px;
`;
