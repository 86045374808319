export const LOAD_COMPANY_DETAILS = 'LOAD_COMPANY_DETAILS';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const UPDATE_BANK_INFO = 'UPDATE_BANK_INFO';
export const SET_DIFF_MAILING_ADDRESS = 'SET_DIFF_MAILING_ADDRESS';
export const UPDATE_MAILING_ADDRESS = 'UPDATE_MAILING_ADDRESS';
export const SKIP_LOAD = 'Company/SKIP_LOAD';
export const LOAD_COMPANY_CONTACT = 'LOAD_COMPANY_CONTACT';
export const SET_COMPANY_CONTACT = 'SET_COMPANY_CONTACT';
export const SELECT_TWO_FACTOR_METHOD = 'SELECT_TWO_FACTOR_METHOD';
export const UPDATE_USER_TWO_FACTOR_METHOD = 'UPDATE_USER_TWO_FACTOR_METHOD';
export const REQUEST_EMAIL_AUTH_CODE = 'REQUEST_EMAIL_AUTH_CODE';
export const SET_SUBMITTING_STATE = 'SET_SUBMITTING_STATE';
export const LOAD_USER_PROMO = 'LOAD_USER_PROMO';
export const SET_USER_PROMO = 'SET_USER_PROMO';
export const UPDATE_BANK_CURRENCY = 'UPDATE BANK CURRENCY';

export const setUserPromo = userPromo => ({
  type: SET_USER_PROMO,
  userPromo
});
export const loadUserPromo = () => ({
  type: LOAD_USER_PROMO
});
export const setCompanyContact = payload => ({
  type: SET_COMPANY_CONTACT,
  payload
});
export const loadCompanyContact = () => ({
  type: LOAD_COMPANY_CONTACT
});
export const skipLoad = () => ({
  type: SKIP_LOAD
});
export const loadCompanyDetails = () => ({
  type: LOAD_COMPANY_DETAILS
});
export const setCompanyDetails = (
  companyDetails,
  mailingAddress,
  companyContact,
  bankDetails,
  bankIdMethods
) => ({
  type: SET_COMPANY_DETAILS,
  companyDetails,
  mailingAddress,
  companyContact,
  bankDetails,
  bankIdMethods
});

export const updateContactInfo = newCompanyContact => ({
  type: UPDATE_CONTACT_INFO,
  newCompanyContact
});

export const updateBankInfo = newBankInfo => ({
  type: UPDATE_BANK_INFO,
  newBankInfo
});

export const updateBankCurrency = bankCurrency => ({
  type: UPDATE_BANK_CURRENCY,
  bankCurrency
});

export const setDiffMailingAddress = value => ({
  type: SET_DIFF_MAILING_ADDRESS,
  value
});

export const updateMailingAddress = newMailingAddress => ({
  type: UPDATE_MAILING_ADDRESS,
  newMailingAddress
});

export const setSubmittingState = submittingState => ({
  type: SET_SUBMITTING_STATE,
  submittingState
});

export const selectTwoFactorMethod = authType => ({
  type: SELECT_TWO_FACTOR_METHOD,
  authType
});

export const updateUserTwoFactorMethod = authType => ({
  type: UPDATE_USER_TWO_FACTOR_METHOD,
  authType
});

export const requestEmailAuthCode = () => ({
  type: REQUEST_EMAIL_AUTH_CODE
});

const initialState = {
  loading: true,
  companyDetails: {},
  diffMailingAddress: 'false',
  editFormMode: false,
  isSubmitting: false,
  userPromo: undefined
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: action.companyDetails,
        mailingAddress: action.mailingAddress,
        companyContact: action.companyContact,
        bankDetails: action.bankDetails || {},
        bankIdMethods: action.bankIdMethods,
        loading: false,
        diffMailingAddress:
          action.mailingAddress === undefined ? 'false' : 'true'
      };
    case SET_COMPANY_CONTACT:
      return {
        ...state,
        companyContact: action.payload
      };
    case SET_SUBMITTING_STATE:
      return {
        ...state,
        isSubmitting: action.submittingState
      };
    case UPDATE_CONTACT_INFO:
      return {
        ...state,
        companyContact: action.newCompanyContact
      };
    case UPDATE_BANK_INFO:
      return {
        ...state,
        bankDetails: action.newBankInfo
      };
    case UPDATE_BANK_CURRENCY:
      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          bankCurrency: action.bankCurrency
        }
      };
    case SET_DIFF_MAILING_ADDRESS:
      return {
        ...state,
        diffMailingAddress: action.value
      };
    case UPDATE_MAILING_ADDRESS:
      return {
        ...state,
        mailingAddress: action.newMailingAddress
      };
    case SKIP_LOAD:
      return {
        ...state,
        loading: false
      };
    case SET_USER_PROMO:
      return {
        ...state,
        userPromo: action.userPromo
      };
    default:
      return state;
  }
};
