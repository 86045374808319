// @flow
import React, { Component } from 'react';
import ConfirmationModal from './ConfirmationModal';
import SuccessModal from './SuccessModal';
import * as modalTypes from './modalTypes';
import { type ConfirmationModalPayload } from './ConfirmationModal';
import ErrorModal from './ErrorModal';
import SelectTwoFactorMethodModal from './TwoFactorAuthModals/SelectTwoFactorMethodModal';
import EnableEmailTwoFactorModal from './TwoFactorAuthModals/EnableEmailTwoFactorAuth';
import DisableTwoFactorAuthModal from './TwoFactorAuthModals/DisableTwoFactorAuthModal';
import EnableGoogleAuthModal from './TwoFactorAuthModals/EnableGoogleAuthModal';
import VerifyTwoFactorAuthModal from './TwoFactorAuthModals/VerifyTwoFactorAuthModal';
import ConditionallyApprovedModal from './ConditionallyApprovedModal';

type ConfirmationModalProps = {
  modal: modalTypes.CONFIRMATION_MODAL,
  payload: ConfirmationModalPayload,
  closeModal: () => void,
  language: string
};
type DefaultProps = {
  modal: string,
  closeModal: () => void,
  payload: {},
  language: string
};
type Props = ConfirmationModalProps | DefaultProps;

export default class Conductor extends Component<Props> {
  render() {
    switch (this.props.modal) {
      case modalTypes.CONFIRMATION_MODAL:
        return (
          // $FlowFixMe
          <ConfirmationModal
            {...this.props.payload}
            closeModal={this.props.closeModal}
          />
        );
      case modalTypes.SUCCESS_MODAL:
        return (
          // $FlowFixMe
          <SuccessModal
            {...this.props.payload}
            closeModal={this.props.closeModal}
          />
        );
      case modalTypes.ERROR_MODAL:
        return (
          // $FlowFixMe
          <ErrorModal
            {...this.props.payload}
            closeModal={this.props.closeModal}
          />
        );
      case modalTypes.SELECT_TWO_FACTOR_METHOD_MODAL:
        return (
          <SelectTwoFactorMethodModal
            closeModal={this.props.closeModal}
            lang={this.props.language}
          />
        );
      case modalTypes.ENABLE_EMAIL_TWO_FACTOR_AUTH_MODAL:
        return (
          <EnableEmailTwoFactorModal
            closeModal={this.props.closeModal}
            lang={this.props.language}
          />
        );
      case modalTypes.DISABLE_TWO_FACTOR_AUTH_MODAL:
        return (
          <DisableTwoFactorAuthModal
            {...this.props.payload}
            closeModal={this.props.closeModal}
          />
        );
      case modalTypes.ENABLE_GOOGLE_AUTH_MODAL:
        return (
          <EnableGoogleAuthModal
            {...this.props.payload}
            closeModal={this.props.closeModal}
            lang={this.props.language}
          />
        );
      case modalTypes.VERIFY_TWO_FACTOR_AUTH_MODAL:
        return (
          <VerifyTwoFactorAuthModal
            {...this.props.payload}
            closeModal={this.props.closeModal}
            lang={this.props.language}
          />
        );
      case modalTypes.CONDITIONALLY_APPROVED_MODAL:
        return (
          <ConditionallyApprovedModal
            {...this.props.payload}
            closeModal={this.props.closeModal}
          />
        );
      default:
        return null;
    }
  }
}
