import styled from 'styled-components/macro';
import background from '../../assets/images/sign-in-bg.jpg';
import { ActionLink, GreyTextReactLink } from '../../components/LinkStyles';

export const SignInDiv = styled.div`
  position: relative;
  background-color: white;
  max-width: 450px;
  width: 90vw;
  margin: 8rem auto;
  border-top: 2px solid black;
  padding: 6rem 4rem 6rem;
  @media screen and (min-width: 420px) {
    padding: 8rem 6rem 6rem;
  }
  border: ${props => props.theme.defaultBorder};
  h1 {
    text-align: center;
    text-transform: none;
  }
  ${GreyTextReactLink} {
    display: block;
    text-align: center;
    margin-top: 1.5rem;
    padding: 1rem;
  }
`;

export const SignInMessageP = styled.p`
  text-align: center;
  color: ${props => props.theme.green};
  margin-top: 1rem;
`;

export const SignInBackground = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: top center url(${background});
  background-size: cover;
  padding-top: 5rem;
  padding-bottom: 1rem;
`;

export const ActionsDiv = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    padding-bottom: 1rem;
    border-bottom: ${props => props.theme.defaultBorder};
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    a + a {
      border-left: 1px solid ${props => props.theme.dustierGray};
    }
  }
  ${ActionLink} {
    color: ${props => props.theme.dustyGray};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SignUpP = styled.p`
  color: ${props => props.theme.dustyGray};
  text-align: center;
  margin-top: 1rem;
  @media screen and (max-width: 767px) {
    max-width: 16rem;
    margin: 1rem auto 0;
  }
`;

export const GirlImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  height: 15rem;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
