import { takeLatest, put } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import i18n from '../../i18n';
import { getRequest } from '../../utilities/requests';
import { setSignInErrorMessage } from '../SignIn/reducer';
import { history } from '../../utilities/history';
import { setToken } from '../../utilities/tokenStorage';
import { setInitialState, setUser } from '../reducer';

export const VERIFY_AUTH_TOKEN = 'VERIFY_AUTH_TOKEN';

export const verifyAuthToken = token => ({
  type: VERIFY_AUTH_TOKEN,
  token
});

function* handleResponse(response) {
  try {
    const token = response.data.jwt;
    const userDetails = jwtDecode(token);
    setToken(token);
    yield put(setInitialState(response.data.initial_state));
    yield put(setUser(userDetails));
  } catch (error) {
    //
  }
}

function* handleVerifyAuthToken(action) {
  try {
    const url = '/sessions/verify_auth';
    const response = yield getRequest(url, { token: action.token });
    yield handleResponse(response);
    history.push('/');
  } catch (error) {
    if (error.response) {
      if (error.response.status === 500) {
        history.push('/error');
      } else {
        yield put(
          setSignInErrorMessage(
            error.response.details ||
              i18n.t('unauthenticated:modal.invalidTokenNotice')
          )
        );
        history.push('/signin');
      }
    } else {
      history.push('/error');
    }
  }
}

export function* verifyAuthTokenSaga() {
  yield takeLatest(VERIFY_AUTH_TOKEN, handleVerifyAuthToken);
}
