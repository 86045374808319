import { ROUTE_CHANGED } from '../../reducer';

export const SET_VIEW_OPTION = 'posTerminals/SET_VIEW_OPTION';
export const TOGGLE_SORT = 'posTerminals/TOGGLE_SORT';
export const HANDLE_SEARCH_CHANGE = 'posTerminals/HANDLE_SEARCH_CHANGE';
export const TOGGLE_SHOW_BLOCKED = 'posTerminals/TOGGLE_SHOW_BLOCKED';
export const FOCUS_SEARCH_INPUT = 'posTerminals/FOCUS_SEARCH_INPUT';
export const BLUR_SEARCH_INPUT = 'posTerminals/BLUR_SEARCH_INPUT';
export const SET_PER_PAGE = 'posTerminals/SET_PER_PAGE';
export const EXPAND_DEVICE_ROW = 'EXPAND_DEVICE_ROW';
export const CHANGE_PAGE = 'posTerminals/CHANGE_PAGE';
const SET_ACTIVE_TAB = 'posTerminals/SET_ACTIVE_TAB';

export const setActiveTab = activeTab => ({
  type: SET_ACTIVE_TAB,
  activeTab
});

export const changePage = page => ({
  type: CHANGE_PAGE,
  page
});

export const expandDeviceRow = id => ({
  type: EXPAND_DEVICE_ROW,
  id
});
const initialState = {
  viewOption: 'square',
  alphabeticalSortDirection: 'asc',
  searchValue: '',
  showBlocked: false,
  searchFocused: false,
  page: 1,
  perPage: 10,
  expandedRowId: null,
  previouslyVisitedPages: [1]
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEW_OPTION:
      return {
        ...state,
        viewOption: action.viewOption,
        expandedRowId: null
      };
    case TOGGLE_SORT:
      return {
        ...state,
        alphabeticalSortDirection:
          state.alphabeticalSortDirection === 'asc' ? 'desc' : 'asc'
      };
    case HANDLE_SEARCH_CHANGE:
      return {
        ...state,
        searchValue: action.searchValue,
        expandedRowId: null,
        page: 1,
        previouslyVisitedPages: [1]
      };
    case TOGGLE_SHOW_BLOCKED:
      return {
        ...state,
        showBlocked: !state.showBlocked,
        expandedRowId: null,
        page: 1,
        previouslyVisitedPages: [1]
      };
    case ROUTE_CHANGED:
      return {
        ...state,
        expandedRowId: null
      };
    case FOCUS_SEARCH_INPUT:
      return {
        ...state,
        searchFocused: true,
        expandedRowId: null
      };
    case BLUR_SEARCH_INPUT:
      return {
        ...state,
        searchFocused: false
      };
    case SET_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
        page: 1,
        previouslyVisitedPages: [1]
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        previouslyVisitedPages: state.previouslyVisitedPages.includes(
          action.page
        )
          ? state.previouslyVisitedPages
          : [...state.previouslyVisitedPages, action.page]
      };
    case EXPAND_DEVICE_ROW:
      return {
        ...state,
        expandedRowId: state.expandedRowId === action.id ? null : action.id
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };
    default:
      return state;
  }
};
