import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { headerHeight, mobileHeaderHeight } from '../Navbar/NavbarStyles';

const footerHeight = {
  mobile: '120px',
  tablet: '50px',
  desktop: '90px'
};

export const DesktopWrapperDiv = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.athensGray};
  position: fixed;
  top: 0;
  left: 0;
`;

export const LayoutDiv = styled.div`
  position: relative;
  @media screen and (max-width: 767px) {
    min-height: 100vh;
    margin-top: 0px;
    padding-top: ${mobileHeaderHeight};
    padding-bottom: ${footerHeight.mobile};
    ${props =>
      props.underlay &&
      css`
        background-color: ${props.theme.athensGray};
      `}
  }

  @media screen and (min-width: 768px) {
    margin-top: ${headerHeight};
    margin-left: 68px;
    min-height: calc(100vh - ${headerHeight});
    padding-bottom: ${footerHeight.tablet};
  }

  @media screen and (min-width: 1024px) {
    margin-left: ${props => (props.sidebarToggle ? '230px' : '68px')};
    padding-bottom: ${footerHeight.desktop};
  }
`;

export const ContentDiv = styled.div`
  position: relative;
  padding-top: 1px;
  padding-bottom: 10rem;
  background-color: white;
  ${props =>
    !props.underlay &&
    css`
      min-height: 76vh;
      /* hack ^ fix later */
    `}
  @media screen and (max-width: 767px) {
    ${props =>
      props.refunds &&
      css`
        background-color: ${props => props.theme.athensGray};
      `}
  }

  @media screen and (max-width: 767px) {
    ${props =>
      props.underlay &&
      css`
        padding-bottom: 0;
      `}
  }
  @media screen and (min-width: 1024px) {
    z-index: 3;
    min-height: calc(100vh - ${footerHeight.desktop} - ${headerHeight});
    border: ${props => props.theme.defaultBorder};
    border-right: none;
  }
`;

export const ProfileIconWrapperDiv = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${props => `1px solid ${props.theme.mercury}`};
  background-color: white;
  overflow: hidden;
  height: 82px;
  width: 82px;
  border-radius: 41px;
  @media screen and (min-width: 768px) {
    height: 112px;
    width: 112px;
    border-radius: 56px;
  }
`;

export const ProfileIconTitleP = styled.p`
  font-size: 1.3rem;
  color: ${props => props.theme.dustyGray};
  text-align: center;
`;

export const ProfileIconTitleDiv = styled.div``;

// Footer styles
export const FooterDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(100% - 80px);
  height: ${footerHeight.mobile};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.mercury};
  margin: 0 40px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    height: ${footerHeight.tablet};
  }
  @media screen and (min-width: 1024px) {
    width: calc(100% - 40px);
    margin-left: 0;
    justify-content: space-between;
  }
`;

export const LinkDiv = styled.div``;

const footerText = css`
  color: black;
  ${props => props.theme.fontStackHeader};
  font-weight: 400;
  font-size: 1.3rem;
  padding: 0 15px;
  text-align: center;
`;
export const CopyrightP = styled.p`
  ${footerText}

  &:lang(de) {
    font-size: 1rem;
    padding: 0 11px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
    font-size: 1.2rem;
  }
`;

const linkStyles = css`
  text-transform: uppercase;
  ${footerText}
  + a {
    border-left: ${props => props.theme.defaultBorder};
  }
  &:hover {
    text-decoration: underline;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    border-right: ${props => props.theme.defaultBorder};
  }
`;

export const FooterLink = styled(Link)`
  ${linkStyles}
`;
export const FooterA = styled.a`
  ${linkStyles}
`;

// SimpleLayout
export const BackgroundDiv = styled.div`
  min-height: 100vh;
  position: relative;
`;

export const SimpleLayoutDiv = styled.div`
  ${props =>
    props.acceptRefundsPage &&
    css`
      padding-bottom: 50px;
    `}
`;

export const LogoLink = styled(Link)`
  position: absolute;
  top: 3rem;
  left: 3rem;
  z-index: 1;
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

export const LogoImg = styled.img`
  width: 90px;
  max-height: 200px;
  @media screen and (min-width: 768px) {
    width: 120px;
  }
`;

export const SimpleFooterDiv = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  h4 {
    line-height: 16px;
    letter-spacing: 0.04em;
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
    h4 {
      margin-right: 5.2rem;
    }
  }
`;
