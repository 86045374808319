// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { SimpleFooterDiv } from './LayoutStyles';

type Props = { t: TFunction };

class SimpleFooter extends Component<Props> {
  render() {
    return (
      <SimpleFooterDiv>
        <h4>{this.props.t('footer.copyright.simplified')} </h4>
      </SimpleFooterDiv>
    );
  }
}
export default withTranslation(['global'])(SimpleFooter);
