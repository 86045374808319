import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import globalSagas from './globalSagas';

export default function configureStore() {
  let composeEnhancers = compose;
  if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  const sagaMiddleware = createSagaMiddleware();
  const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));
  const store = createStore(rootReducer, enhancers);

  // eslint-disable-next-line
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App/reducer', () => {
      const nextRootReducer = require('./App/reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  sagaMiddleware.run(globalSagas);

  return store;
}
