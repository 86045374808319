export const LOAD_BILLING_INVOICES = 'LOAD_BILLING_INVOICES';
export const SET_BILLING_INVOICES = 'SET_BILLING_INVOICES';
export const SET_PAGE = 'Billing/SET_PAGE';
export const FAILED_BILLING_INVOICES_LOAD = 'FAILED_BILLING_INVOICES_LOAD';
export const EXPAND_INVOICE_ROW = 'Billing/EXPAND_INVOICE_ROW';
export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';

export const downloadInvoice = invoice => ({
  type: DOWNLOAD_INVOICE,
  invoice
});
export const expandInvoiceRow = id => ({
  type: EXPAND_INVOICE_ROW,
  id
});
export const failedBillingInvoicesLoad = () => ({
  type: FAILED_BILLING_INVOICES_LOAD
});
export const setPage = page => ({
  type: SET_PAGE,
  page
});
export const setBillingInvoices = payload => ({
  type: SET_BILLING_INVOICES,
  payload
});
export const loadBillingInvoices = () => ({
  type: LOAD_BILLING_INVOICES
});

const initialState = {
  invoices: [],
  loading: true,
  expandedRowId: null,
  page: 1,
  failedLoad: false,
  visitedPages: []
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BILLING_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        loading: false
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
        visitedPages: state.visitedPages.includes(action.page)
          ? state.visitedPages
          : [...state.visitedPages, action.page]
      };
    case FAILED_BILLING_INVOICES_LOAD:
      return {
        ...state,
        failedLoad: true
      };
    case EXPAND_INVOICE_ROW:
      return {
        ...state,
        expandedRowId: state.expandedRowId === action.id ? null : action.id
      };
    default:
      return state;
  }
};
