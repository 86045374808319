export const SET_INVOICES = 'SET_INVOICES';
export const LOAD_INVOICES = 'LOAD_INVOICES';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';

export const loadInvoices = sandbox_mode => ({
  type: LOAD_INVOICES,
  sandbox_mode
});

export const setInvoices = payload => ({
  type: SET_INVOICES,
  payload
});
export const updateInvoice = invoice => ({
  type: UPDATE_INVOICE,
  invoice
});

export default (state = null, action) => {
  switch (action.type) {
    case SET_INVOICES:
      return action.payload.invoices;
    case UPDATE_INVOICE:
      return (
        state &&
        state.map(invoice =>
          invoice.id === action.invoice.id ? action.invoice : invoice
        )
      );
    default:
      return state;
  }
};
