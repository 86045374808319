export const SET_REFUND_FORM_DATA = 'SET_REFUND_FORM_DATA';

export const setRefundFormData = (refund, recentlyCompleted = false) => ({
  type: SET_REFUND_FORM_DATA,
  refund,
  recentlyCompleted
});

export default (state = { loading: true }, action) => {
  switch (action.type) {
    case SET_REFUND_FORM_DATA:
      return {
        ...state,
        acceptRefund: action.refund,
        loading: false,
        recentlyCompleted: action.recentlyCompleted
      };
    default:
      return state;
  }
};
