import styled, { css } from 'styled-components/macro';
import { DefaultIcon } from '../../components/Icon';

const BaseModal = css`
  p {
    line-height: 1.8rem;
  }
  a {
    ${props => props.theme.fontStackHeader};
  }
  @media screen and (min-width: 1024px) {
    p {
      line-height: 2.1rem;
    }
  }
`;

// ConfirmationModal
export const ConfirmationModalDiv = styled.div`
  ${BaseModal}
  width: 300px;
  max-width: 90vw;
`;
export const ConfirmationModalContentDiv = styled.div`
  padding: 5rem 3rem 4rem;
  text-align: center;
  h4 {
    margin-bottom: 1rem;
  }
`;
export const ConfirmationModalButtons = styled.div`
  display: flex;
`;
export const ConfirmationModalA = styled.a`
  /* display: inline-block;
   */
  flex-grow: 1;
  position: relative;
  padding: 25px 0;
  border: 1px solid transparent;
  border-top-color: ${props => props.theme.mercury};
  text-transform: uppercase;
  ${props => props.theme.fontStackHeader};
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;
  &:first-child {
    width: calc(50% + 1px);
    color: ${props => props.theme.brand};
    border-right-color: ${props => props.theme.mercury};
  }
  &:last-child {
    width: 50%;
    color: ${props => props.theme.dustyGray};
    border-left-color: ${props => props.theme.mercury};
    margin-left: -1px;
  }
  &:hover {
    border-color: ${props => props.theme.brand};
    color: ${props => props.theme.brand};
    z-index: 1;
  }
  &:lang(de) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 25px;
  }
`;
export const SuccessModalA = styled.a`
  display: inline-block;
  position: relative;
  padding: 25px 0;
  border: 1px solid transparent;
  border-top-color: ${props => props.theme.mercury};
  text-transform: uppercase;
  ${props => props.theme.fontStackHeader};
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;
  &:first-child {
    /* width: calc(50% + 1px); */
    width: 100%;
    color: ${props => props.theme.brand};
    border-right-color: ${props => props.theme.mercury};
  }
  /* &:last-child {
    width: 50%;
    color: ${props => props.theme.dustyGray};
    border-left-color: ${props => props.theme.mercury};
    margin-left: -1px;
  } */
  &:hover {
    border-color: ${props => props.theme.brand};
    color: ${props => props.theme.brand};
    z-index: 1;
  }
`;

export const SuccessLottieDiv = styled.div`
  width: 115px;
  margin: 15px auto;
`;

// ErrorModal
export const ErrorModalDiv = styled.div`
  width: 250px;
  max-width: 90vw;
  svg {
    width: 2.2rem;
    height: 2.2rem;
    margin-bottom: 1rem;
    fill: ${props => props.theme.red};
  }
`;

// EnableTwoFactorModal
export const EnableTwoFactorModalDiv = styled.div`
  ${BaseModal}
  width: 325px;
  /* padding: 5rem; */
  h4 {
    margin-bottom: 1rem;
    text-align: center;
  }
  @media screen and (min-width: 768px) {
    width: 450px;
    /* padding: 90px; */
    /* padding: 7rem; */
  }
  max-width: 90vw;
`;

export const TwoFactorMethodDiv = styled.div`
  border: 1px solid ${props => props.theme.mercury};
  position: relative;
  padding: 2rem;
  cursor: pointer;
  h5 {
    margin-bottom: 5px;
  }
  ${DefaultIcon} {
    width: 10px;
    fill: ${props => props.theme.blue};
    position: absolute;
    top: 50%;
    right: 5%;
  }
  margin-top: 25px;
  &:hover {
    border-color: ${props => props.theme.blue};
  }
`;
