// @flow
import React, { Component } from 'react';
import { TwoFactorMethodDiv } from '../ModalConductorStyles';
import { DefaultIcon } from '../../../components/Icon';

type Props = {
  authType: string,
  title: string,
  text: string,
  handleClick: (authType: string) => void
};

export default class TwoFactorMethodType extends Component<Props> {
  handleClick = () => {
    this.props.handleClick(this.props.authType);
  };
  render() {
    return (
      <TwoFactorMethodDiv onClick={this.handleClick}>
        <h5>{this.props.title}</h5>
        <p>{this.props.text}</p>
        <DefaultIcon name="chevronRight" />
      </TwoFactorMethodDiv>
    );
  }
}
