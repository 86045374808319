import { SET_USERS, FAILED_USERS_LOAD } from './users';

export const HANDLE_SEARCH_CHANGE = 'users/HANDLE_SEARCH_CHANGE';
export const ADD_USER = 'ADD_USER';
export const TOGGLE_SORT = 'users/TOGGLE_SORT';
export const SET_VIEW_OPTION = 'users/SET_VIEW_OPTION';
export const EXPAND_USER_ROW = 'EXPAND_USER_ROW';
export const FOCUS_SEARCH_INPUT = 'users/FOCUS_SEARCH_INPUT';
export const BLUR_SEARCH_INPUT = 'users/BLUR_SEARCH_INPUT';
export const HANDLE_DROPDOWN_SELECT = 'users/HANDLE_DROPDOWN_SELECT';
export const SET_PER_PAGE = 'users/SET_PER_PAGE';
export const CHANGE_PAGE = 'users/CHANGE_PAGE';

export const changePage = page => ({
  type: CHANGE_PAGE,
  page
});

export const handleSearchChange = searchValue => ({
  type: HANDLE_SEARCH_CHANGE,
  searchValue
});

export const toggleSort = () => ({
  type: TOGGLE_SORT
});

export const setViewOption = viewOption => ({
  type: SET_VIEW_OPTION,
  viewOption
});

export const expandUserRow = id => ({
  type: EXPAND_USER_ROW,
  id
});

export const focusSearchInput = () => ({
  type: FOCUS_SEARCH_INPUT
});

export const blurSearchInput = () => ({
  type: BLUR_SEARCH_INPUT
});

export const handleDropdownSelect = groupFilter => ({
  type: HANDLE_DROPDOWN_SELECT,
  groupFilter
});

export const setPerPage = perPage => ({
  type: SET_PER_PAGE,
  perPage
});

const initialState = {
  searchFocused: false,
  searchValue: '',
  alphabeticalSortDirection: 'asc',
  viewOption: 'square',
  expandedUserRowId: null,
  dropdownOpen: false,
  dropdownValue: '',
  page: 1,
  perPage: 10,
  loading: true,
  failedLoad: false,
  previouslyVisitedPages: [1]
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        loading: false,
        failedLoad: false
      };
    case FAILED_USERS_LOAD:
      return {
        ...state,
        failedLoad: true
      };
    case HANDLE_SEARCH_CHANGE:
      return {
        ...state,
        searchValue: action.searchValue,
        page: 1,
        previouslyVisitedPages: [1]
      };
    case TOGGLE_SORT:
      return {
        ...state,
        alphabeticalSortDirection:
          state.alphabeticalSortDirection === 'asc' ? 'desc' : 'asc'
      };
    case SET_VIEW_OPTION:
      return {
        ...state,
        viewOption: action.viewOption
      };
    case EXPAND_USER_ROW:
      return {
        ...state,
        expandedUserRowId:
          state.expandedUserRowId === action.id ? null : action.id
      };
    case FOCUS_SEARCH_INPUT:
      return {
        ...state,
        searchFocused: true
      };
    case BLUR_SEARCH_INPUT:
      return {
        ...state,
        searchFocused: false
      };
    case HANDLE_DROPDOWN_SELECT:
      return {
        ...state,
        dropdownValue: action.groupFilter
      };
    case SET_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
        page: 1,
        previouslyVisitedPages: [1]
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        previouslyVisitedPages: state.previouslyVisitedPages.includes(
          action.page
        )
          ? state.previouslyVisitedPages
          : [...state.previouslyVisitedPages, action.page]
      };
    default:
      return state;
  }
};
