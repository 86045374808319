import styled, { css } from 'styled-components/macro';
import { ReactComponent as Chevron } from '../../assets/icons/chevron-down.svg';

export const LanguageList = styled.ul`
  display: ${props => (props.fixedHeader ? 'none' : 'block')};
  width: 14.4rem;
  background-color: ${props => props.theme.white};
  border: 0.1rem solid ${props => props.theme.mercury};
  padding: 0.2rem 1.4rem;
  position: absolute;
  right: ${props => (!props.open ? '0' : '-7.6rem')};
  top: 0;
  z-index: 1;
  ${props =>
    Boolean(!props.open) &&
    css`
      display: flex;
      justify-content: space-between;
      margin: 0;
      width: 7.6rem;
      position: relative;
      @media (min-width: 1024px) {
        border-color: ${props => props.theme.white};
        border-left: 0.1rem solid ${props => props.theme.mercury};
      }
    `}
  ${props =>
    Boolean(props.open) &&
    css`
      padding-top: 0.8rem;
    `}
  @media (min-width: 1024px) {
    padding-top: 0.6rem;
    height: ${props => !props.open && '100%'};
  }

  @media (max-width: 767px) {
    z-index: 1;
    border-top: none;
    position: absolute;
    top: 5.8rem;
    right: 0;

    ${props =>
      Boolean(props.open) &&
      css`
        margin: 0;
      `}

    ${props =>
      !props.open &&
      css`
        padding: 0 0.5rem;
        width: 60px;
        height: 38px;
      `}
  }
  &:hover {
    color: ${props =>
      !props.open
        ? props.theme.brand
        : props.selected
        ? props.theme.brand
        : ''};
    fill: ${props =>
      !props.open
        ? props.theme.brand
        : props.selected
        ? props.theme.brand
        : ''};
  }
  @media screen and (max-width: 767px) {
    ${props =>
      props.hideOnScroll &&
      css`
        display: none;
      `}
  }
`;

export const LanguageListItem = styled.li`
  ${props => props.theme.fontStackHeader}
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.655rem;
  letter-spacing: 0.04rem;
  padding: 1rem 0.7rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${props =>
    Boolean(props.open) &&
    css`
      color: ${props.selected ? props.theme.blue : ''};
    `}

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.blue};
  }
`;

export const Divider = styled.div`
  content: '';
  width: 11.3rem;
  border-top: 0.1rem solid ${props => props.theme.mercury};
  display: block;
`;

export const ChevronDown = styled(Chevron)`
  z-index: -1;
  width: 0.83rem;
  height: 0.5rem;
  /* position: absolute;
  right: 1.8rem;
  top: 2.4rem;
  margin-right: 0.3rem;
  margin-top: 0.2rem; */
  ${props =>
    props.selected &&
    css`
      fill: ${props.theme.blue};
    `}
  ${props =>
    Boolean(!props.open) &&
    css`
      fill: unset;
      right: 1.1rem;
      top: 1.28rem;

      /* ${LanguageList}:hover & {
        fill: ${props.theme.blue};
      } */
    `}
  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    top: 2.4rem;
  }
`;

export const PositionOnSignInPage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: ${props => (!props.open ? '3.8rem' : '7.6rem')};
  top: 0;
  height: ${props => (!props.open ? '' : '5rem')};

  @media (max-width: 767px) {
    /* right: ${props => (!props.open ? '3.8rem' : '0rem')}; */
    right: 0;
  }

  ${LanguageList} {
    border: 0.1rem solid ${props => props.theme.mercury};
    ${props =>
      Boolean(!props.open) &&
      css`
        width: 6.7rem;
        padding: 0 1.4rem;
      `}
  }
  ${LanguageListItem} {
    padding: ${props => (!props.open ? '0.6rem 0' : '')};
  }
  ${ChevronDown} {
    ${props =>
      Boolean(!props.open) &&
      css`
        right: 1.1rem;
        top: 1.1rem;
      `}
  }
`;
