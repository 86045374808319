//@flow
import React, { Component } from 'react';
import icons from './icons';
import xvgColor from '../../assets/icons/xvg-color.png';
import zenColor from '../../assets/icons/zen-color.png';
import trxColor from '../../assets/icons/trx-color.png';
import ncco from '../../assets/icons/ncco.png';
import styled, { css } from 'styled-components';

const imageIcons = {
  xvg: xvgColor,
  zen: zenColor,
  trx: trxColor,
  ncco
};

export type Props = {
  name: string,
  className?: string,
  style?: {},
  hoverIcon?: string,
  color?: string,
  colorName?: string
};

export type State = {
  hover: boolean
};

class Icon extends Component<Props, State> {
  static defaultProps = {
    style: {}
  };

  state = { hover: false };

  handleMouseEnter = () => {
    if (this.props.hoverIcon) {
      this.setState({ hover: true });
    }
  };

  handleMouseLeave = () => {
    if (this.props.hoverIcon) {
      this.setState({ hover: false });
    }
  };

  render() {
    const { name, style, hoverIcon, color } = this.props;
    const Component = icons[this.state.hover ? hoverIcon : name];
    const colorStyles = color ? { fill: color } : {};
    if (Object.keys(imageIcons).includes(name)) {
      return (
        <img
          style={style}
          src={imageIcons[name]}
          alt={name}
          className={this.props.className}
        />
      );
    }

    if (Component) {
      return (
        <Component
          {...this.props}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          style={{ ...style, ...colorStyles }}
        />
      );
    }
    return null;
  }
}

export default Icon;

export const DefaultIcon = styled(Icon)`
  height: 1.5rem;
  width: 1.5rem;
  ${props =>
    props.brand &&
    css`
      fill: ${props.theme.brand};
    `}
  ${props =>
    props.colorName &&
    css`
      fill: ${props.theme[props.colorName]};
    `}
 
    ${props =>
      props.color &&
      css`
        fill: ${props.color};
      `}
    ${props =>
      props.mobileHidden &&
      css`
        @media screen and (max-width: 767px) {
          display: none;
        }
      `}

    ${props =>
      props.mobileOnly &&
      css`
        @media screen and (min-width: 768px) {
          display: none;
        }
      `}
`;
