import { defaultSubmitHandler } from '../../utilities/requests';

export const handleSubmit = async values => {
  try {
    const referral_detail_code = document.cookie
      .split(';')
      .filter(item => item.includes('nc_referral_code'))[0];
    let code = [];
    if (referral_detail_code) {
      code = referral_detail_code.split('=');
    }
    if (code.length > 1) {
      values.referral_detail_code = code[1];
    }
  } catch (error) {
    // Ignore errors
  }
  const url = '/users';
  return defaultSubmitHandler(url)(values);
};
