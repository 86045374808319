// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  MobileTabSpacerDiv,
  TabHr,
  SpacerTab
} from '../../components/TabStyles';
import { history } from '../../utilities/history';
import { StyledPrimaryTabBar, ToggleWrapper } from './MarketingStyles';

type Props = {
  t: TFunction,
  activeMarketingTab: string,
  setActiveMarketingTab: ({
    value: string,
    text: string
  }) => void
};

class HeaderBlock extends Component<Props> {
  handleClickOption = (option: Option) => {
    this.props.setActiveMarketingTab(option);
    return history.push(option.value, { option });
  };
  render() {
    const { t } = this.props;
    const tabData = [
      {
        text: t('header.navigation.marketingCollateral'),
        value: '/marketing'
      },
      {
        text: t('header.navigation.guides'),
        value: '/marketing/guides'
      }
    ];

    return (
      <ToggleWrapper>
        <StyledPrimaryTabBar
          data={tabData}
          handleClickOption={this.handleClickOption}
          initialTab={this.props.activeMarketingTab}
        />

        <TabHr />
        <MobileTabSpacerDiv />
        <SpacerTab tablet />
      </ToggleWrapper>
    );
  }
}

export default withTranslation(['marketing'])(HeaderBlock);
