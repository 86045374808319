import styled from 'styled-components/macro';
import bgIpad from '../../assets/images/404-bg-ipad.jpg';
import bgDesktop from '../../assets/images/404-bg-desktop.jpg';

import bgMaintenance from '../../assets/images/maintenance-bg.jpg';
import { mobileHeaderHeight } from '../../modules/Navbar/NavbarStyles';

export const ErrorContainerDiv = styled.div`
  height: 100vh;
  /* height: calc(100vh - 160px); <--IF WE ADD A HEADER AND FOOTER INTO SIMPLE LAYOUT*/
  min-height: 600px;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${bgIpad});
  position: relative;
  @media screen and (max-width: 767px) {
    padding-top: 90px;
    height: calc(100vh - ${mobileHeaderHeight});
  }
  @media screen and (min-width: 768px) {
    min-height: 680px;
  }
  @media screen and (min-width: 1024px) {
    background-image: url(${bgDesktop});
  }
`;
export const LogoDiv = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const LogoImg = styled.img`
  display: block;
  width: 200px;
  margin: 0 auto;
`;

export const ErrorGirlDiv = styled.div`
  position: absolute;
  height: 235px;
  width: 100%;
  bottom: -2px;
  right: 0;
  @media screen and (min-width: 768px) {
    height: 280px;
  }
  @media screen and (min-width: 1024px) {
    height: 375px;
  }
`;

export const ErrorGirlImg = styled.img`
  display: block;
  height: 100%;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    margin-left: 65%;
  }
  @media screen and (min-width: 1024px) {
    margin-left: 66%;
  }
`;

export const ContentParentDiv = styled.div`
  text-align: center;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 120px;
  }
`;

export const TitleH1 = styled.h1`
  padding-top: 25px;
  font-size: 100px;
  @media screen and (min-width: 768px) {
    padding-top: 80px;
    font-size: 200px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 250px;
  }
`;

export const SubTitleH3 = styled.h3`
  font-size: 30px;
  width: 300px;
  margin: 10px auto;
  @media screen and (min-width: 768px) {
    font-size: 40px;
    width: 430px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 45px;
    width: 460px;
  }
`;

export const MaintenanceDiv = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${bgMaintenance});
  background-position: left;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MaintenanceImg = styled.img`
  margin-bottom: 45px;
  max-width: 85%;
  height: auto;
`;
