import psl from 'psl';
import i18n from '../i18n';
import { phone } from 'phone';

export const passwordValidation = value =>
  value && value.length >= 8
    ? undefined
    : i18n.t('global:validations.passwordShort');
export const pinNumberValidation = value =>
  value && value.length === 4 && /^\d+$/.test(value)
    ? undefined
    : i18n.t('global:validations.4DigitPin');
export const passwordWarn = value =>
  value && value.length >= 12
    ? undefined
    : i18n.t('global:validations.weakPassword');
export const passwordConfirm = (value, allValues) =>
  value && value === allValues.password
    ? undefined
    : i18n.t('global:validations.passwordMismatch');

export const registrationPasswordValidation = value =>
  value && value.length >= 8
    ? undefined
    : i18n.t('global:validations.passwordShort');
export const registrationPasswordWarn = value =>
  value && value.length >= 12
    ? undefined
    : i18n.t('global:validations.weakPassword');
export const registrationPasswordConfirm = (value, allValues) =>
  value && value === allValues.password
    ? undefined
    : i18n.t('global:validations.passwordMismatch');

export const maxLength = max => value =>
  value && value.length > max
    ? i18n.t('global:validations.maxLength', { characterLength: max })
    : undefined;
export const minLength = min => value =>
  value && value.length < min
    ? i18n.t('global:validations.minLength', { characterLength: min })
    : undefined;
export const minLength8 = minLength(8);
export const minLength6 = minLength(6);
export const minLength16 = minLength(16);
export const maxLength16 = maxLength(16);
export const maxLength255 = maxLength(255);

export const positiveNumber = value =>
  value && !isNaN(value) && value > 0
    ? undefined
    : i18n.t('global:validations.positiveNumber');

export const greaterThanZero = value =>
  value && !isNaN(value) && value > 0
    ? undefined
    : i18n.t('global:validations.greaterThanZero');

export const requiredField = value =>
  value ? undefined : i18n.t('global:validations.requiredField');
export const requiredFieldWithoutText = value => (value ? undefined : true);
export const requiredBooleanField = value =>
  value || value === false
    ? undefined
    : i18n.t('global:validations.requiredField');
export const emailValidation = value =>
  !value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value)
    ? i18n.t('global:validations.invalidEmail')
    : undefined;
export const dateOfBirthRequired = value =>
  value && value.birthday_day && value.birthday_month && value.birthday_year
    ? undefined
    : i18n.t('global:validations.dateOfBirth');
export const phoneNumber = value =>
  value && phone(value).isValid
    ? undefined
    : i18n.t('global:validations.phoneFormat');
export function domainValid(value) {
  const split = value.split('/');
  let domain;
  if (value.startsWith('https://') || value.startsWith('http://')) {
    domain = split[2];
  } else {
    return i18n.t('global:validations.invalidDomainProtocol');
  }
  return psl.isValid(domain)
    ? undefined
    : i18n.t('global:validations.invalidDomainName');
}

export function domainValidWithoutProtocol(value) {
  const split = value.split('www.');
  let domain;
  if (
    value.startsWith('www.') ||
    value.startsWith('http://www.') ||
    value.startsWith('https://www.')
  ) {
    domain = split[1];
  } else {
    return i18n.t('global:validations.invalidDomainWithoutProtocol');
  }

  return psl.isValid(domain)
    ? undefined
    : i18n.t('global:validations.invalidDomainName');
}

export const maxAmount = value =>
  value && Number(value) <= 9999999999
    ? undefined
    : i18n.t('global:validations.maxAmount');

export const maxAmount100 = value =>
  value && Number(value) <= 100
    ? undefined
    : i18n.t('global:validations.maxAmount100');

export const validateDestinationTag = value =>
  value && (value.length < 6 || value.length > 10 || !value.match(/^[0-9.]+$/))
    ? i18n.t('global:validations.destinationTag')
    : undefined;

export const acknowledgeConditionalApproval = value =>
  value
    ? undefined
    : 'Please confirm your acknowledgement to proceed to our site.';
