// TODO: create a reusable filters component that has a shared reducer
// types can take an argument to become unique
// export const CHANGE_PAGE = (type) => `${type}/CHANGE_PAGE`;
import { SET_RECURRING_INVOICES } from './view';
import { ROUTE_CHANGED } from '../../../../reducer';
export const CHANGE_PAGE = 'recurringInvoices/CHANGE_PAGE';
export const SET_FREQUENCY_FILTER = 'recurringInvoices/SET_FREQUENCY_FILTER';
export const SET_EMAIL_STATUS_FILTER =
  'recurringInvoices/SET_EMAIL_STATUS_FILTER';
export const SET_SEARCH_FILTER = 'recurringInvoices/SET_SEARCH_FILTER';
export const TOGGLE_CALENDAR_FILTER =
  'recurringInvoices/TOGGLE_CALENDAR_FILTER';
export const CLEAR_CALENDAR_FILTER = 'recurringInvoices/CLEAR_CALENDAR_FILTER';
export const SET_CALENDAR_START_FILTER =
  'recurringInvoices/SET_CALENDAR_START_FILTER';
export const SET_CALENDAR_END_FILTER =
  'recurringInvoices/SET_CALENDAR_END_FILTER';
export const SET_CALENDAR_SAGA = 'recurringInvoices/SET_CALENDAR_SAGA';
export const SET_PER_PAGE_FILTER = 'recurringInvoices/SET_PER_PAGE_FILTER';

export const changePage = page => ({
  type: CHANGE_PAGE,
  page
});

export const setFrequencyFilter = frequency => ({
  type: SET_FREQUENCY_FILTER,
  frequency
});

export const setSearchFilter = searchValue => ({
  type: SET_SEARCH_FILTER,
  searchValue
});

export const toggleCalendarFilter = () => ({
  type: TOGGLE_CALENDAR_FILTER
});

export const clearCalendarFilter = () => ({
  type: CLEAR_CALENDAR_FILTER
});

export const setCalendarStartFilter = calendarStart => ({
  type: SET_CALENDAR_START_FILTER,
  calendarStart
});

export const setCalendarEndFilter = calendarEnd => ({
  type: SET_CALENDAR_END_FILTER,
  calendarEnd
});

export const setCalendarSaga = () => ({
  type: SET_CALENDAR_SAGA
});

export const setPerPageFilter = perPage => ({
  type: SET_PER_PAGE_FILTER,
  perPage
});

const initialState = {
  frequency: null,
  search: '',
  calendarFilterState: false,
  startDate: null,
  endDate: null,
  perPage: 10,
  page: 1,
  visitedPages: [1],
  pageCount: 0
};

const resetPageState = {
  page: 1,
  visitedPages: [1]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECURRING_INVOICES:
      return {
        ...state,
        pageCount: Math.ceil(action.payload.count / state.perPage)
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        visitedPages: state.visitedPages.includes(action.page)
          ? state.visitedPages
          : [...state.visitedPages, action.page]
      };
    case SET_FREQUENCY_FILTER:
      return {
        ...state,
        frequency: action.frequency,
        ...resetPageState
      };

    case SET_SEARCH_FILTER:
      return {
        ...state,
        search: action.searchValue,
        ...resetPageState
      };
    case TOGGLE_CALENDAR_FILTER:
      return {
        ...state,
        calendarFilterState: !state.calendarFilterState,
        ...resetPageState
      };
    case SET_CALENDAR_START_FILTER:
      return {
        ...state,
        startDate: action.calendarStart
      };
    case SET_CALENDAR_END_FILTER:
      return {
        ...state,
        endDate: action.calendarEnd,
        ...resetPageState
      };
    case CLEAR_CALENDAR_FILTER:
      return {
        ...state,
        startDate: null,
        endDate: null,
        ...resetPageState
      };
    case SET_PER_PAGE_FILTER:
      return {
        ...state,
        perPage: action.perPage,
        ...resetPageState
      };
    case ROUTE_CHANGED:
      if (
        action.to.match(/\/invoices\/\d+/) ||
        action.from.match(/\/invoices\/\d+/)
      ) {
        return state;
      }
      return {
        ...state,
        ...resetPageState
      };
    default:
      return state;
  }
};
