import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: ${props =>
    css`linear-gradient(110.57deg, ${props.theme.brand} 13.58%, ${
      props.theme.mariner
    } 97.31%)`};
  border-radius: 2px;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const Tab = styled.div`
  ${props =>
    !props.active &&
    css`
      cursor: pointer;
    `}
  height: 45px;
  ${props =>
    props.active &&
    css`
      background-color: ${props => props.theme.white};
      ${props.first &&
        css`
          margin-left: 1px;
        `}
      ${props.last &&
        css`
          margin-right: 1px;
        `}
    `}
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    > h5 {
      opacity: 1;
    }
  }
  @media screen and (min-width: 1440px) {
    ${props =>
      props.length < 4 &&
      css`
        max-width: 253px;
      `}
  }
`;

export const H5 = styled.h5`
  ${props =>
    props.active
      ? css`
          color: ${props.theme.biscay};
        `
      : css`
          color: ${props.theme.white};
          opacity: 0.8;
        `}
`;

//  TODO:
// mobile denim - brand
