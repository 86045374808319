import { takeLatest, put } from 'redux-saga/effects';
import { getRequest } from '../../utilities/requests';
import { LOAD_MERCHANT_DOCUMENT_COLLECTION } from '../MerchantDocumentCollection/reducer';
import { setMerchantApplication, LOAD_MERCHANT_APPLICATION } from './reducer';

function* handleLoadMerchantApplication() {
  const url = '/merchant_applications';
  const response = yield getRequest(url);
  yield put(
    setMerchantApplication(
      response.data.merchant_application,
      response.data.processing_volumes
    )
  );
}

export function* loadMerchantApplicationSaga() {
  /**
   * @description
   * LOAD_MERCHANT_DOCUMENT_COLLECTION for the tooltip on upload-documents screen
   */
  yield takeLatest(
    [LOAD_MERCHANT_APPLICATION, LOAD_MERCHANT_DOCUMENT_COLLECTION],
    handleLoadMerchantApplication
  );
}
