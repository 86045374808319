//@flow
import React, { Component } from 'react';
import { NavbarClosedDiv, LogoImg, LogoLink } from './MobileHeaderStyles';
import LanguageDropDown from '../../../components/LanguageDropDown/LanguageDropDown';
// $FlowFixMe
import { ReactComponent as Menu } from '../../../assets/icons/menu.svg';

type Props = {
  toggleMobileNavbar: () => void,
  logo: string
};

class NavbarClosed extends Component<Props> {
  render() {
    return (
      <>
        <NavbarClosedDiv>
          <LogoLink to="/">
            <LogoImg src={this.props.logo} alt="logo" />
          </LogoLink>
          <Menu
            onClick={this.props.toggleMobileNavbar}
            style={{ width: 30, height: 30, marginRight: 20 }}
          />
        </NavbarClosedDiv>
        <LanguageDropDown />
      </>
    );
  }
}

export default NavbarClosed;
