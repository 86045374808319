import { takeEvery, put } from 'redux-saga/effects';
import i18n from '../../i18n';
import { DELETE_PLUGIN, setPluginDeleted } from './reducer';
import { showSuccessModal } from '../../modules/ModalConductor/reducer';
import { deleteRequest, handleSagaError } from '../../utilities/requests';

function* handleDeletePlugin(action) {
  try {
    const url = `/ecommerce_plugins/${action.id}`;
    yield deleteRequest(url);
    yield put(setPluginDeleted(action.id));
    yield put(
      showSuccessModal(
        i18n.t('ecommercePlugins:modal.deleteNotice.title'),
        i18n.t('ecommercePlugins:modal.deleteNotice.message')
      )
    );
  } catch (error) {
    handleSagaError(error);
  }
}

export function* deletePlugin() {
  yield takeEvery(DELETE_PLUGIN, handleDeletePlugin);
}
