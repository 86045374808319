// @flow
import React, { Component } from 'react';
import {
  SignUpH1,
  ThankYouCardDiv,
  ThankYouP,
  ThankYouGirlImg,
  ThankYouGirlDiv,
  ThankYouButton
} from './SignUpStyles';
import girlJumpShadow from '../../assets/images/girl-jump-shadow.png';
import { withTheme } from 'styled-components';

type Props = {
  companyName: string,
  theme: { isWhiteLabel: boolean }
};
class ThankYou extends Component<Props> {
  render() {
    return (
      <ThankYouCardDiv>
        <SignUpH1>Thank You!</SignUpH1>
        <ThankYouP>
          Thanks for registering an account with {this.props.companyName}. A
          confirmation email has been sent to you.
        </ThankYouP>
        <ThankYouP>
          Please follow the link provided in the email to activate your account.
        </ThankYouP>
        <ThankYouButton to="/">Return To Home</ThankYouButton>
        {!this.props.theme.isWhiteLabel && (
          <ThankYouGirlDiv>
            <ThankYouGirlImg src={girlJumpShadow} alt="Thank You Girl" />
          </ThankYouGirlDiv>
        )}
      </ThankYouCardDiv>
    );
  }
}

export default withTheme(ThankYou);
