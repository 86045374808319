import { combineReducers } from 'redux';
import devices from './devices';
import view from './view';

export const ADD_DEVICE = 'ADD_DEVICE';
export const LOAD_DEVICES = 'LOAD_DEVICES';
export const BLOCK_DEVICE = 'BLOCK_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const SET_DEVICE_DELETED = 'SET_DEVICE_DELETED';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UNBLOCK_DEVICE = 'UNBLOCK_DEVICE';

export const unblockDevice = id => ({
  type: UNBLOCK_DEVICE,
  id
});
export const updateDevice = device => ({
  type: UPDATE_DEVICE,
  device
});
export const setDeviceDeleted = id => ({
  type: SET_DEVICE_DELETED,
  id
});
export const deleteDevice = id => ({
  type: DELETE_DEVICE,
  id
});
export const blockDevice = id => ({
  type: BLOCK_DEVICE,
  id
});
export const loadDevices = () => ({
  type: LOAD_DEVICES
});
export const addDevice = device => ({
  type: ADD_DEVICE,
  device
});

const reducer = combineReducers({
  devices,
  view
});

export default reducer;
