import { throttle, select, put } from 'redux-saga/effects';
import {
  LOAD_ASSET_MANAGEMENT_TRANSACTIONS,
  setAssetManagementTransactions
} from '../reducer/transactions';
import { failedAssetManagementTransactionLoad } from '../reducer/view';
import { getRequest } from '../../../utilities/requests';
import {
  SET_TRANSACTION_TYPE,
  SET_PAGE,
  SET_SEARCH_VALUE,
  SET_PER_PAGE,
  SET_CURRENCY_FILTER,
  SET_STATUS_FILTER,
  SET_EXTERNAL_USER_ID_FILTER
} from '../reducer/transactionsFilters';

function* handleLoadAssetManagementTransactions() {
  try {
    const url = '/asset_management/transactions';
    const filters = yield select(
      state => state.assetManagement.transactionsFilters
    );
    const response = yield getRequest(url, filters);
    yield put(setAssetManagementTransactions(response.data));
  } catch (error) {
    yield put(failedAssetManagementTransactionLoad());
  }
}

export function* loadAssetManagementTransactionsSaga() {
  yield throttle(
    500,
    [
      LOAD_ASSET_MANAGEMENT_TRANSACTIONS,
      SET_TRANSACTION_TYPE,
      SET_PAGE,
      SET_SEARCH_VALUE,
      SET_PER_PAGE,
      SET_CURRENCY_FILTER,
      SET_STATUS_FILTER,
      SET_EXTERNAL_USER_ID_FILTER
    ],
    handleLoadAssetManagementTransactions
  );
}
