// import { ReactComponent as Xem } from '../../assets/icons/xem.svg';
// import { ReactComponent as Ncco } from '../../assets/icons/ncco.svg';
// import { ReactComponent as Trx } from '../../assets/icons/trx.svg';
// import { ReactComponent as Xvg } from '../../assets/icons/Xvg.svg';
import { ReactComponent as account } from '../../assets/icons/account.svg';
import { ReactComponent as arrowLeftO } from '../../assets/icons/arrow-left-o.svg';
import { ReactComponent as arrowLeftSlim } from '../../assets/icons/arrow-left-slim.svg';
import { ReactComponent as arrowDownSlim } from '../../assets/icons/arrow-down-slim.svg';
import { ReactComponent as arrowRightO } from '../../assets/icons/arrow-right-o.svg';
import { ReactComponent as arrowRightSlim } from '../../assets/icons/arrow-right-slim.svg';
import { ReactComponent as arrowRightLong } from '../../assets/icons/arrow-right-long.svg';
import { ReactComponent as bank } from '../../assets/icons/bank.svg';
import { ReactComponent as bch } from '../../assets/icons/bch.svg';
import { ReactComponent as btc } from '../../assets/icons/btc.svg';
import { ReactComponent as usdt } from '../../assets/icons/usdt.svg';
import { ReactComponent as usdc } from '../../assets/icons/usdc.svg';
import { ReactComponent as cad } from '../../assets/icons/cad.svg';
import { ReactComponent as calendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as certificate } from '../../assets/icons/certificate.svg';
import { ReactComponent as crown } from '../../assets/icons/crown.svg';
import { ReactComponent as checkmark } from '../../assets/icons/checkmark.svg';
import { ReactComponent as chevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as chevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as chevronRight } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as chevronUp } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as clipboard } from '../../assets/icons/clipboard.svg';
import { ReactComponent as cloudUpload } from '../../assets/icons/cloud-upload.svg';
import { ReactComponent as cloudUploaded } from '../../assets/icons/cloud-uploaded.svg';
import { ReactComponent as company } from '../../assets/icons/company.svg';
import { ReactComponent as trash } from '../../assets/icons/delete.svg';
import { ReactComponent as discount } from '../../assets/icons/discount.svg';
import { ReactComponent as edit } from '../../assets/icons/edit.svg';
import { ReactComponent as envelope } from '../../assets/icons/envelope.svg';
import { ReactComponent as envelopeOpen } from '../../assets/icons/envelope-open.svg';
import { ReactComponent as eth } from '../../assets/icons/eth.svg';
import { ReactComponent as euro } from '../../assets/icons/euro.svg';
import { ReactComponent as extLink } from '../../assets/icons/ext-link.svg';
import { ReactComponent as invoice } from '../../assets/icons/mainMenu/invoice.svg';
import { ReactComponent as info } from '../../assets/icons/info.svg';
import { ReactComponent as infoThick } from '../../assets/icons/info-thick.svg';
import { ReactComponent as listView } from '../../assets/icons/list-view.svg';
import { ReactComponent as globe } from '../../assets/icons/globe.svg';
import { ReactComponent as global } from '../../assets/icons/global.svg';
import { ReactComponent as lock } from '../../assets/icons/lock.svg';
import { ReactComponent as logout } from '../../assets/icons/logout.svg';
import { ReactComponent as ltc } from '../../assets/icons/ltc.svg';
import { ReactComponent as medal } from '../../assets/icons/medal.svg';
import { ReactComponent as menu } from '../../assets/icons/menu.svg';
import { ReactComponent as marketing } from '../../assets/icons/marketing.svg';
import { ReactComponent as notAllowed } from '../../assets/icons/not-allowed.svg';
import { ReactComponent as phone } from '../../assets/icons/phone.svg';
import { ReactComponent as plusCircle } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as minusCircle } from '../../assets/icons/minus-circle.svg';
import { ReactComponent as minusO } from '../../assets/icons/minus-o.svg';
import { ReactComponent as plus } from '../../assets/icons/plus.svg';
import { ReactComponent as plusO } from '../../assets/icons/plus-o.svg';
import { ReactComponent as personalInfo } from '../../assets/icons/personal-info-icon.svg';
import { ReactComponent as questionmark } from '../../assets/icons/questionmark.svg';
import { ReactComponent as reactivate } from '../../assets/icons/re-activate.svg';
import { ReactComponent as search } from '../../assets/icons/search.svg';
import { ReactComponent as settings } from '../../assets/icons/settings.svg';
import { ReactComponent as squareView } from '../../assets/icons/square-view.svg';
import { ReactComponent as timer } from '../../assets/icons/timer.svg';
import { ReactComponent as techScreen } from '../../assets/icons/tech-screen.svg';
import { ReactComponent as trophy } from '../../assets/icons/trophy.svg';
import { ReactComponent as usd } from '../../assets/icons/usd.svg';
import { ReactComponent as warning } from '../../assets/icons/warning.svg';
import { ReactComponent as x } from '../../assets/icons/x.svg';
import { ReactComponent as xem } from '../../assets/icons/xem.svg';
import { ReactComponent as xrp } from '../../assets/icons/xrp.svg';
import { ReactComponent as xSolid } from '../../assets/icons/xSolid.svg';
import { ReactComponent as download } from '../../assets/icons/download.svg';
import { ReactComponent as cart } from '../../assets/icons/mainMenu/cart.svg';
import { ReactComponent as browser } from '../../assets/icons/mainMenu/hosted-payments.svg';
import { ReactComponent as devices } from '../../assets/icons/mainMenu/devices.svg';
import { ReactComponent as home } from '../../assets/icons/mainMenu/home.svg';
import { ReactComponent as pieChart } from '../../assets/icons/mainMenu/piechart.svg';
import { ReactComponent as payouts } from '../../assets/icons/mainMenu/payouts.svg';
import { ReactComponent as transactions } from '../../assets/icons/mainMenu/transactions.svg';
import { ReactComponent as refund } from '../../assets/icons/refund.svg';
import { ReactComponent as recurring } from '../../assets/icons/recurring.svg';
import { ReactComponent as created } from '../../assets/icons/created.svg';
import { ReactComponent as time } from '../../assets/icons/time.svg';
export default {
  // Xem,
  // Ncco,
  // Trx,
  // Xvg,
  arrowLeftSlim,
  arrowDownSlim,
  arrowRightSlim,
  arrowRightLong,
  bank,
  bch,
  btc,
  usdt,
  usdc,
  cad,
  calendar,
  certificate,
  checkmark,
  chevronDown,
  clipboard,
  cloudUpload,
  cloudUploaded,
  company,
  crown,
  delete: trash,
  discount,
  trash,
  edit,
  envelope,
  envelopeOpen,
  eth,
  euro,
  extLink,
  info,
  infoThick,
  invoice,
  listView,
  lock,
  logout,
  ltc,
  globe,
  global,
  medal,
  menu,
  marketing,
  notAllowed,
  phone,
  plusCircle,
  personalInfo,
  minusCircle,
  minusO,
  plus,
  plusO,
  questionmark,
  search,
  squareView,
  techScreen,
  usd,
  warning,
  x,
  xem,
  xSolid,
  account,
  arrowLeftO,
  arrowRightO,
  chevronLeft,
  chevronRight,
  refund,
  settings,
  chevronUp,
  trophy,
  reactivate,
  download,
  cart,
  browser,
  devices,
  xrp,
  home,
  pieChart,
  payouts,
  timer,
  transactions,
  recurring,
  created,
  time
};
