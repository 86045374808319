//@flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  FooterDiv,
  CopyrightP,
  FooterLink,
  FooterA,
  LinkDiv
} from './LayoutStyles';

type Props = {
  showFees: boolean,
  t: TFunction,
  language: string
};

class Footer extends Component<Props> {
  render() {
    const year = new Date().getFullYear();
    const { t } = this.props;
    return (
      <FooterDiv>
        <LinkDiv>
          {this.props.showFees && (
            <FooterLink to="/fees">{t('links.fees')}</FooterLink>
          )}
          <FooterA
            href="https://merchant-support.net-cents.com/knowledge/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('links.knowledgeBase')}
          </FooterA>
          <FooterLink to="/support">{t('links.support')}</FooterLink>
        </LinkDiv>
        <CopyrightP lang={this.props.language}>
          {t('footer.copyright.default')} {year}
        </CopyrightP>
      </FooterDiv>
    );
  }
}
export default withTranslation(['global'])(Footer);
