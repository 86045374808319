// @flow
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RequestReset from './RequestReset';
import UpdatePassword from './UpdatePassword';
import { defaultSubmitHandler } from '../../utilities/requests';
import { SignInBackground, SignInDiv } from '../SignIn/SignInStyles';

type Props = {};

export default class ResetPassword extends Component<Props> {
  render() {
    return (
      <SignInBackground>
        <SignInDiv>
          <Switch>
            <Route
              path="/password_resets/:token/edit"
              render={routeProps => {
                if (!routeProps.match.params.token) {
                  return <Redirect to="/page_not_found" />;
                }
                return (
                  <UpdatePassword
                    onSubmit={defaultSubmitHandler(
                      `/password_resets/${routeProps.match.params.token}`,
                      'put'
                    )}
                  />
                );
              }}
            />
            <Route path="/password_resets" component={RequestReset} />
          </Switch>
        </SignInDiv>
      </SignInBackground>
    );
  }
}
