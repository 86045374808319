import styled, { css } from 'styled-components/macro';
import Icon from '../../../components/Icon';
export const LogoImg = styled.img`
  width: 130px;
`;
export const ClosedLogoImg = styled.img`
  max-width: 33px;
  max-height: 33px;
`;
export const LogoWrapper = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    height: 59px;
    margin-bottom: 3rem;
  }
`;

export const SidebarContainerDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  position: fixed;
  z-index: 10;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: ${props => props.theme.athensGray};

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    ${props => props.theme.contentDropShadow};
  }
`;

export const SidebarWrapperDiv = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* firefox */
  padding-bottom: 80px;
  height: calc(100vh - 150px);
  @media screen and (min-width: 1024px) {
    height: calc(100vh - 140px);
  }
  width: ${props => (props.opened ? '230px' : '68px')};
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ArrowLeftDiv = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  ${props =>
    props.sidebarToggle ||
    css`
      padding-right: 0;
      justify-content: center;
    `}
`;

export const ArrowIcon = styled(Icon)`
  width: 24px;
  fill: ${props => props.theme.brand};
`;
