// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { type MarketingFile } from '.';
import {
  DownloadImgLinkA,
  DownloadCryptoBadgeButton,
  MarketingCollateralDiv
} from './MarketingStyles';
import cryptoBadgeImg from '../../assets/images/pay-w-btc-eth-ltc-bch-xrp-more.png';
import CollateralItem from './CollateralItem';

type Props = {
  downloadFile: (fileName: string, url: string) => void,
  file: MarketingFile,
  marketingCollateral: MarketingFile[],
  t: TFunction
};

class MarketingCollateral extends Component<Props> {
  downloadFile = () => {
    this.props.downloadFile(this.props.file.name, this.props.file.url);
  };

  render() {
    const { file, t } = this.props;
    return (
      <MarketingCollateralDiv>
        <h4>{file.name}</h4>
        <p>{file.description}</p>
        <DownloadImgLinkA
          onClick={this.downloadFile}
          target="_blank"
          download={`Netcents ${file.name}`}
        >
          <img src={cryptoBadgeImg} alt="" />
        </DownloadImgLinkA>
        <DownloadCryptoBadgeButton
          onClick={this.downloadFile}
          target="_blank"
          download={`Netcents ${file.name}`}
        >
          {t('marketing:body.button')}
        </DownloadCryptoBadgeButton>
        <CollateralItem
          label={t('marketing:body.collateralItem.title')}
          description={t('marketing:body.collateralItem.description')}
          href={`mailto:merchantsupport@net-cents.com?subject=${t(
            'marketing:body.collateralItem.mailerSubject'
          )}`}
          linkText="merchantsupport@net-cents.com"
        />
        {this.props.marketingCollateral.map(file => (
          <CollateralItem
            key={file.name}
            label={file.name}
            description={file.description}
            onClick={() => this.props.downloadFile(file.name, file.url)}
            linkText={`${t('global:actions.download')} ${file.name}`}
          />
        ))}
      </MarketingCollateralDiv>
    );
  }
}
export default withTranslation(['global', 'marketing'])(MarketingCollateral);
