// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, TFunction } from 'react-i18next';
import {
  ErrorContainerDiv,
  ErrorGirlDiv,
  ErrorGirlImg,
  ContentParentDiv,
  TitleH1,
  SubTitleH3
} from './ErrorStyles';
import { ErrorButton } from '../ButtonStyles';
import { withTheme } from 'styled-components';

type Props = {
  errorGirl: string,
  title: string,
  subTitle: string,
  theme: { isWhiteLabel: boolean },
  t: TFunction
};

class ErrorPage extends React.Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <ErrorContainerDiv>
        {!this.props.theme.isWhiteLabel && (
          <ErrorGirlDiv>
            <ErrorGirlImg src={this.props.errorGirl} alt="" />
          </ErrorGirlDiv>
        )}
        <ContentParentDiv>
          <TitleH1>{this.props.title}</TitleH1>
          <SubTitleH3>{this.props.subTitle}</SubTitleH3>
          <ErrorButton href="/">{t('errorPages.button')}</ErrorButton>
        </ContentParentDiv>
      </ErrorContainerDiv>
    );
  }
}

const mapStateToProps = state => ({
  platformLogo: state.global.platformLogo
});

export default withTheme(
  connect(mapStateToProps)(withTranslation(['unauthenticated'])(ErrorPage))
);
