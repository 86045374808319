// @flow
import { put } from 'redux-saga/effects';
import i18n from '../i18n';
import axios from 'axios';
import { type $AxiosError, type $AxiosXHR } from 'axios';
import { updateToken, getToken } from './tokenStorage';
import { SubmissionError } from 'redux-form';
import { showErrorModal } from '../modules/ModalConductor/reducer';
import { logout } from '../App/reducer';
import { setSignInMessage } from '../App/SignIn/reducer';
import { store } from '..';
// import { showModal } from '../Wallet/modules/ModalWrapper/reducer';
// import { SESSION_EXPIRED } from '../Wallet/modules/ModalWrapper/modalTypes';

type AnyResponse = { [key: string]: any };

export const postRequest = async (url: string, payload: {}) =>
  await request(url, payload, 'post');

export const getRequest = async (
  url: string,
  params: {} = {},
  options: {} = {}
) => await request(url, params, 'get', options);

export const deleteRequest = async (url: string) =>
  await request(url, {}, 'delete');

export const patchRequest = async (url: string, payload: {}) =>
  await request(url, payload, 'patch');

export const putRequest = async (url: string, payload: {}) =>
  await request(url, payload, 'put');

export const request = async (
  url: string,
  payload: {},
  method: 'post' | 'patch' | 'put' | 'get' | 'delete',
  options: {} = {}
) => {
  try {
    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };
    let response;
    if (method === 'get' || method === 'delete') {
      response = await paramRequest(url, payload, method, headers, options);
    } else {
      response = await bodyRequest(url, payload, method, headers, options);
    }
    updateToken(response);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.response.headers['x-token']
    ) {
      store.dispatch(logout());
      store.dispatch(setSignInMessage(i18n.t('global:notice.sessionExpired')));
    }
    throw error;
  }
};

const bodyRequest = async (
  url: string,
  data: {},
  method: 'post' | 'patch' | 'put',
  headers: { Authorization: string },
  options: {}
) =>
  // $FlowFixMe
  (await axios({
    method,
    url,
    data,
    headers,
    ...options
  }): $AxiosXHR<AnyResponse>);

const paramRequest = async (
  url: string,
  params: {},
  method: 'get' | 'delete',
  headers: { Authorization: string },
  options: {}
) =>
  // $FlowFixMe
  (await axios({
    method,
    url,
    params,
    headers,
    ...options
  }): $AxiosXHR<AnyResponse>);

type ErrorResponse = {
  data: {
    source?: { [key: string]: string },
    details: string,
    error?: string
  }
};
export const handleThrowSubmissionError = (response: ?ErrorResponse) => {
  let errors = { _error: i18n.t('global:errorMessage.unableToSubmit') };
  if (response) {
    if (response.data.source) {
      errors = { ...response.data.source, ...errors };
    }
    const responseMessage = response.data.error || response.data.details;
    if (responseMessage) {
      errors._error = responseMessage;
    }
  }
  throw new SubmissionError(errors);
};

export const handleThrowVerificationSubmissionError = (
  response: ?ErrorResponse
) => {
  const errors = { _error: i18n.t('global:errorMessage.unableToSubmit') };
  if (response) {
    if (response.data.source) {
      Object.entries(response.data.source).forEach(([key, value]) => {
        const fieldName = key.replace(
          /(company_detail|address|company_contact)/g,
          '$&_attributes'
        );
        let schema = errors;
        const pList = fieldName.split('.');
        const len = pList.length;
        for (let i = 0; i < len - 1; i++) {
          const elem = pList[i];
          if (!schema[elem]) {
            schema[elem] = {};
          }
          schema = schema[elem];
        }

        schema[pList[len - 1]] = value;
      });
    }

    errors._error = response.data.error || response.data.details;
  }
  throw new SubmissionError(errors);
};

export const rethrowSubmissionError = (error: Error | SubmissionError) => {
  if (error instanceof SubmissionError) {
    throw error;
  }
};

export const defaultSubmitHandler = (
  url: string,
  method: 'post' | 'patch' | 'put' = 'post'
) => async (values: {}) => {
  try {
    const response = await request(url, values, method);
    return response.data;
  } catch (error) {
    handleThrowSubmissionError(error.response);
  }
};

export const deleteSubmitHandler = (url: string) => async () => {
  try {
    const response = await deleteRequest(url);
    return response.data;
  } catch (error) {
    handleThrowSubmissionError(error.response);
  }
};

export function* handleSagaError(
  error: $AxiosError<{ message?: string, details?: string, error?: string }>,
  defaultMessage: string = i18n.t('global:errorMessage.systemError')
): Generator<*, *, *> {
  let message = defaultMessage;
  if (
    error.response &&
    (error.response.data.error ||
      error.response.data.message ||
      error.response.data.details)
  ) {
    message =
      error.response.data.error ||
      error.response.data.message ||
      error.response.data.details;
  }
  yield put(showErrorModal(message));
}
