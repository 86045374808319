export const SET_MARKETING_FILES = 'SET_MARKETING_FILES';
export const LOAD_MARKETING_FILES = 'LOAD_MARKETING_FILES';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';

export const downloadFile = (fileName, url) => ({
  type: DOWNLOAD_FILE,
  fileName,
  url
});

export const loadMarketingFiles = () => ({
  type: LOAD_MARKETING_FILES
});

export const setMarketingFiles = payload => ({
  type: SET_MARKETING_FILES,
  payload
});

const initialState = {
  loading: true,
  marketingFiles: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MARKETING_FILES:
      return {
        ...state,
        marketingFiles: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
