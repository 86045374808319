// @flow
import React, { Component, Fragment } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  NavBarOpenedBackgroundOverlay,
  NavBarOpenedContainer,
  NavBarOpenedItemContainer,
  Divider,
  NavBarOpenedTopLinks,
  CloseIcon,
  NavBarOpenedItemText,
  LogoutButton
} from './MobileHeaderStyles';
import { navIcon } from '../../../utilities/icons';
import { routes, hasReadPermission } from '../../../utilities/sidebarItems';
import { type Permissions, type User } from '../../../utilities/types';

type Props = {
  location: Location,
  toggleMobileNavbar: () => void,
  permissions: Permissions,
  user: User,
  logout: () => void,
  t: TFunction
};

type State = {
  previousIndex: ?number
};

export class NavBarOpened extends Component<Props, State> {
  state = {
    previousIndex: null
  };

  handlePreviousTab = (i: number) => {
    if (this.state.previousIndex !== i - 1) {
      this.setState({ previousIndex: i - 1 });
    }
  };

  handleLogout = () => {
    this.props.toggleMobileNavbar();
    this.props.logout();
  };

  renderNavListTop = () => {
    const { t } = this.props;
    const formattedRoute = route => {
      if (route.key === 'Dashboard') {
        return '';
      }

      return route.key.replace(' ', '-').toLowerCase();
    };

    return (
      <NavBarOpenedTopLinks>
        {routes(t).map(route => {
          const { pathname } = this.props.location;
          const routeName = `/${formattedRoute(route)}`;
          const active =
            routeName === '/'
              ? pathname === routeName
              : pathname.startsWith(routeName);

          return (
            <Fragment key={route.key}>
              {hasReadPermission(
                route.key,
                this.props.permissions,
                this.props.user
              ) && (
                <NavBarOpenedItemContainer isActive={active}>
                  {navIcon(route.key.toLowerCase())}
                  <NavBarOpenedItemText
                    to={`/${formattedRoute(route)}`}
                    isActive={active}
                    hasIcon
                  >
                    {route.value.toLowerCase()}
                  </NavBarOpenedItemText>
                </NavBarOpenedItemContainer>
              )}
            </Fragment>
          );
        })}
      </NavBarOpenedTopLinks>
    );
  };

  renderNavListBottom = () => {
    const { t } = this.props;
    const { pathname } = this.props.location;

    // navListBottom labels are translatable
    const navListBottom = [
      { tLabel: t('global:routeNames.help'), to: '/support' },
      { tLabel: t('global:routeNames.account'), to: '/account' },
      {
        tLabel: t('global:routeNames.settings'),
        to: '/settings'
      },
      {
        tLabel: t('global:routeNames.marketing'),
        to: '/marketing'
      }
    ];

    // $FlowFixMe
    return navListBottom.map(navItem => {
      const active = pathname.includes(navItem.to);

      return (
        <NavBarOpenedItemContainer key={navItem.tLabel} isActive={active}>
          <NavBarOpenedItemText to={navItem.to} isActive={active}>
            {navItem.tLabel.toLowerCase()}
          </NavBarOpenedItemText>
        </NavBarOpenedItemContainer>
      );
    });
  };

  render() {
    return (
      <NavBarOpenedBackgroundOverlay>
        <NavBarOpenedContainer>
          <CloseIcon name="x" onClick={this.props.toggleMobileNavbar} />
          {this.renderNavListTop()}
          <Divider />
          {this.renderNavListBottom()}
          <LogoutButton onClick={this.handleLogout}>Logout</LogoutButton>
        </NavBarOpenedContainer>
      </NavBarOpenedBackgroundOverlay>
    );
  }
}

export default withRouter(withTranslation(['global'])(NavBarOpened));
