import i18n from '../../../../i18n';
import { UPDATE_INVOICE } from '../Index/reducer/invoices';
import { SHOW_MODAL } from '../../../../modules/ModalConductor/reducer';
import { CONFIRMATION_MODAL } from '../../../../modules/ModalConductor/modalTypes';
import { store } from '../../../../';
import { markCompleteRefund } from '../../../Transactions/reducer';

export const LOAD_INVOICE = 'LOAD_INVOICE';
export const SET_INVOICE = 'SET_INVOICE';
export const RESEND_INVOICE = 'RESEND_INVOICE';
export const CANCEL_INVOICE = 'CANCEL_INVOICE';
export const MARK_COMPLETE_INVOICE = 'MARK_COMPLETE_INVOICE';
export const REACTIVATE_INVOICE = 'REACTIVATE_INVOICE';
export const TOGGLE_INVOICE_READ_ONLY_STATE = 'TOGGLE_INVOICE_READ_ONLY_STATE';

export const showCancelInvoiceModal = id => ({
  type: SHOW_MODAL,
  modal: CONFIRMATION_MODAL,
  payload: {
    onConfirm: () => store.dispatch(cancelInvoice(id)),
    title: i18n.t('invoices:modal.cancelInvoiceConfirmation.title'),
    message: i18n.t('invoices:modal.cancelInvoiceConfirmation.message')
  }
});

export const showResendInvoiceModal = invoice => ({
  type: SHOW_MODAL,
  modal: CONFIRMATION_MODAL,
  payload: {
    onConfirm: () => store.dispatch(resendInvoice(invoice.id)),
    title: i18n.t('invoices:modal.resendInvoiceConfirmation.title'),
    message: i18n.t('invoices:modal.resendInvoiceConfirmation.message', {
      email: invoice.customer.email
    })
  }
});

export const showReactivateInvoiceModal = id => ({
  type: SHOW_MODAL,
  modal: CONFIRMATION_MODAL,
  payload: {
    onConfirm: () => store.dispatch(reactivateInvoice(id)),
    title: i18n.t('invoices:modal.reactivateInvoiceConfirmation.title'),
    message: i18n.t('invoices:modal.reactivateInvoiceConfirmation.message')
  }
});

export const showMarkCompleteModal = invoice => ({
  type: SHOW_MODAL,
  modal: CONFIRMATION_MODAL,
  payload: {
    onConfirm: () => store.dispatch(markCompleteInvoice(invoice)),
    title: i18n.t('invoices:modal.markCompleteInvoiceConfirmation.title'),
    message: i18n.t('invoices:modal.markCompleteInvoiceConfirmation.message')
  }
});

export const showMarkCompleteBeforeRefundModal = (id, status) => ({
  type: SHOW_MODAL,
  modal: CONFIRMATION_MODAL,
  payload: {
    onConfirm: () => store.dispatch(markCompleteRefund(id)),
    title: i18n.t(
      'invoices:modal.markCompleteBeforeRefundInvoiceConfirmation.title'
    ),
    message:
      status === 'underpaid'
        ? i18n.t(
            'invoices:modal.markCompleteBeforeRefundInvoiceConfirmation.message.underpaid'
          )
        : i18n.t(
            'invoices:modal.markCompleteBeforeRefundInvoiceConfirmation.message.overpaid'
          )
  }
});

export const markCompleteInvoice = invoice => ({
  type: MARK_COMPLETE_INVOICE,
  invoice
});

export const loadInvoice = id => ({
  type: LOAD_INVOICE,
  id
});

export const setInvoice = invoice => ({
  type: SET_INVOICE,
  invoice
});

export const resendInvoice = id => ({
  type: RESEND_INVOICE,
  id
});

export const cancelInvoice = id => ({
  type: CANCEL_INVOICE,
  id
});

export const reactivateInvoice = id => ({
  type: REACTIVATE_INVOICE,
  id
});

export const toggleInvoiceReadOnlyState = () => ({
  type: TOGGLE_INVOICE_READ_ONLY_STATE
});

const initialState = {
  invoice: null,
  readOnly: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE:
      return {
        ...state,
        invoice: action.invoice
      };
    case TOGGLE_INVOICE_READ_ONLY_STATE:
      return {
        ...state,
        readOnly: !state.readOnly
      };
    case UPDATE_INVOICE:
      if (state.invoice && state.invoice.id === action.invoice.id) {
        return {
          ...state,
          invoice: action.invoice
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
