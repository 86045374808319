import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import SubmitButton from './SubmitButton';

export const buttonStyles = css`
  user-select: none;
  width: 100%;
  transition: all 0.2s ease;
  text-align: center;
  display: block;
  color: white;
  padding: 20px;
  border: none;
  font-size: 13px;
  cursor: pointer;
  ${props => props.theme.fontStackHeader};
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background-color: ${props => props.theme.brand};
  &:hover {
    background-color: ${props => props.theme.indigo};
  }
  &:disabled {
    background-color: ${props => props.theme.inactiveBlue};
    ${props =>
      props.theme.isWhiteLabel &&
      css`
        opacity: 0.8;
      `}
    cursor: not-allowed;
  }
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
`;

export const outlineButtonStyles = css`
  user-select: none;
  width: 100%;
  transition: all 0.2s ease;
  /* text-align: center;
  display: block; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => (props.small ? '7px 20px' : '20px')};
  font-size: 13px;
  cursor: pointer;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  ${props => props.theme.fontStackHeader};
  color: ${props => props.theme.brand};
  border: none;
  font-weight: 400;
  font-style: normal;
  box-shadow: 0px 0px 0px 1px ${props => props.theme.brand};
  transition: box-shadow 0.2s linear;
  &:lang(de) {
    padding: 10px;
    min-width: max-content;
  }
  &:hover {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.indigo};
  }
  &:disabled {
    color: ${props => props.theme.dustierGray};
    cursor: not-allowed;
    box-shadow: 0px 0px 0px 1px ${props => props.theme.dustierGray};
  }
  ${props =>
    props.disabled &&
    css`
      && {
        color: ${props => props.theme.dustierGray};
        cursor: not-allowed;
        box-shadow: 0px 0px 0px 1px ${props => props.theme.dustierGray};
      }
    `}
  &:focus {
    outline: none;
  }
`;

export const ErrorButtonStyles = css`
  user-select: none;
  display: inline-block;
  font-size: $button-sm-font-size;
  padding: 26px 10px;
  min-width: 225px;
  color: white;
  cursor: pointer;
  ${props => props.theme.fontStackHeader};
  font-weight: 600;
  font-style: normal;
  transition: all 0.25s ease;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  background-color: ${props => props.theme.brand};
  &:hover {
    background-color: ${props => props.theme.indigo};
  }
`;

export const ErrorButton = styled.a`
  ${ErrorButtonStyles}
  margin-top: 20px;
  padding: 20px 60px;
  position: relative;
  z-index: 1;
  @media screen and (min-width: 768px) {
    padding: 30px 80px;
  }
`;

export const OutlineButton = styled.a`
  ${outlineButtonStyles};
`;
export const OutlineButtonInput = styled.input`
  ${outlineButtonStyles};
  background-color: transparent;
  @media screen and (max-width: 375px) {
    &&& {
      padding: 10px;
    }
  }
`;
export const OutlineButtonLink = styled(Link)`
  ${outlineButtonStyles};
  ${props =>
    props.getVerified &&
    css`
      && {
        padding: 1.8rem 0;
        width: 19rem;
      }
    `}
`;

export const OutlineButtonDiv = styled.div`
  ${outlineButtonStyles};
  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.white};
      background-color: ${props => props.theme.brand};
    `}
  height: 45px;
  width: 48%;
`;

export const PrimaryButton = styled.a`
  ${buttonStyles};
`;
export const PrimaryButtonLink = styled(Link)`
  ${buttonStyles};
`;
export const ButtonInput = styled.input`
  ${buttonStyles};
  font-weight: 600;
`;

export const OutlineSubmitButton = styled(SubmitButton)`
  ${outlineButtonStyles};
  background-color: transparent;
  :hover {
    background-color: unset;
  }
`;
