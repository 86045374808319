export const LOAD_RECURRING_INVOICE = 'LOAD_RECURRING_INVOICE';
export const SET_RECURRING_INVOICE = 'SET_RECURRING_INVOICE';

export const loadRecurringInvoice = id => ({
  type: LOAD_RECURRING_INVOICE,
  id
});

export const setRecurringInvoice = recurringInvoice => ({
  type: SET_RECURRING_INVOICE,
  recurringInvoice
});

const initialState = {
  //   invoice_recipients: [],
  //   invoice_recipient: null,
  //   loadingRecipient: true,
  //   recurring_view: { searchValue: '', expandedRowId: null }
  //   currentPage: 1,
  //   amountFilter: 10,
  //   previouslyVisited: [1],
  //   searchFilterState: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECURRING_INVOICE:
      return {
        ...state,
        recurring_invoice: action.recurringInvoice
      };

    default:
      return state;
  }
};
