// @flow
import React, { Component } from 'react';
import Lottie from './Lottie';
import styled, { css } from 'styled-components';

const SectionLoaderLottie = styled(Lottie)`
  height: 55px;
`;

const SectionLoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.small ? '100px' : '300px')};
  ${props =>
    props.customHeight &&
    css`
      height: ${props.customHeight};
    `}
  width: 100%;
`;

type Props = { small?: boolean, customHeight?: string };

export default class SectionLoader extends Component<Props> {
  render() {
    return (
      <SectionLoaderDiv
        small={this.props.small}
        customHeight={this.props.customHeight}
      >
        <SectionLoaderLottie name="smaller-loader" loop />
      </SectionLoaderDiv>
    );
  }
}
