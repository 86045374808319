import { SUCCESS_MODAL } from './modalTypes';

export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const closeModal = () => ({
  type: CLOSE_MODAL
});
export const showModal = (modal = null, payload = {}) => ({
  type: SHOW_MODAL,
  modal,
  payload
});

export const showSuccessModal = (title, message, lottie) => ({
  type: SHOW_MODAL,
  modal: SUCCESS_MODAL,
  payload: {
    title,
    message,
    lottie
  }
});

export const showErrorModal = message => ({
  type: SHOW_MODAL,
  modal: SUCCESS_MODAL,
  payload: { message }
});

const initialState = {
  modal: null,
  payload: {}
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modal: action.modal,
        payload: action.payload
      };
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};
