import { combineReducers } from 'redux';

import acceptedCurrencies from './acceptedCurrencies';
import transactions from './transactions';
import transactionsFilters from './transactionsFilters';
import addresses from './addresses';
import balances from './balances';
import view from './view';
import usage from './usage';

const reducer = combineReducers({
  acceptedCurrencies,
  transactions,
  transactionsFilters,
  balances,
  addresses,
  usage,
  view
});

export default reducer;
