import { createSelector } from 'reselect';

const user = state => state.global.user || {};
const getVerified = state => user(state).verified;
const getApplicationSubmitted = state => user(state).application_submitted;
const getApplicationApproved = state => user(state).application_approved;
const getApplicationDenied = state => user(state).application_denied;
const getDocumentVerificationPending = state =>
  user(state).document_verification_pending;
const getDocumentVerificationRejected = state =>
  user(state).document_verification_rejected;
const getDocumentsVerified = state => user(state).documents_verified;
const getAcknowledgedConditionalApproval = state =>
  user(state).acknowledged_conditional_approval;

const handleMerchantApplicationStates = (
  verified,
  applicationSubmitted,
  applicationApproved,
  applicationDenied,
  documentVerificationPending,
  documentVerificationRejected,
  documentsVerified,
  acknowledgedConditionalApproval
) => {
  let verificationState = '';

  if (applicationDenied) {
    verificationState = 'company_details_denied';
  } else if (documentVerificationRejected) {
    verificationState = 'kyc_docs_rejected';
  } else if (
    (documentVerificationPending && !acknowledgedConditionalApproval) ||
    (verified && documentVerificationPending)
  ) {
    verificationState = 'kyc_docs_submitted';
  } else if (!documentsVerified && applicationApproved) {
    verificationState = 'company_details_approved';
  } else if (applicationSubmitted && !verified) {
    verificationState = 'company_details_submitted';
  } else if (verified && documentsVerified && !documentVerificationPending) {
    verificationState = 'kyc_docs_approved';
  }
  return verificationState;
};

export const getMerchantApplicationState = createSelector(
  getVerified,
  getApplicationSubmitted,
  getApplicationApproved,
  getApplicationDenied,
  getDocumentVerificationPending,
  getDocumentVerificationRejected,
  getDocumentsVerified,
  getAcknowledgedConditionalApproval,
  handleMerchantApplicationStates
);
