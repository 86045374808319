// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import SubmitButton from '../../components/SubmitButton';
import Input from '../../components/Input';
import { setSignInMessage } from '../SignIn/reducer';
import { history } from '../../utilities/history';
import { defaultSubmitHandler } from '../../utilities/requests';
import { requiredField } from '../../utilities/validations';
import { SignInBackground, SignInDiv } from '../SignIn/SignInStyles';
import { GreyTextReactLink } from '../../components/LinkStyles';

type Props = {
  handleSubmit: (values: any) => void,
  error: ?string,
  submitting: boolean,
  t: TFunction
};

class ResendConfirmation extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <SignInBackground>
        <SignInDiv>
          <form onSubmit={this.props.handleSubmit}>
            <h1>{t('unauthenticated:confirmation.form.title')}</h1>
            <Field
              type="email"
              label={t('unauthenticated:confirmation.form.fields.email')}
              name="email"
              autocomplete="email"
              validate={requiredField}
              component={Input}
            />

            <SubmitButton
              value={t('global:actions.submit')}
              error={this.props.error}
              disabled={this.props.submitting}
              marginTop="3rem"
            />
            <GreyTextReactLink to="/signin">
              {t('global:actions.cancel')}
            </GreyTextReactLink>
          </form>
        </SignInDiv>
      </SignInBackground>
    );
  }
}

const onSubmitSuccess = (result, dispatch, props) => {
  history.push('/signin');
  dispatch(
    setSignInMessage(props.t('unauthenticated:modal.emailConfConfirmation'))
  );
};

export default withTranslation(['unauthenticated', 'global'])(
  reduxForm({
    form: 'resendConfirmation',
    onSubmit: defaultSubmitHandler('/users/resend_confirmation'),
    onSubmitSuccess
  })(ResendConfirmation)
);
