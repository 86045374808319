import styled from 'styled-components/macro';

export const SidebarOpenedContainerDiv = styled.div``;

export const LogoImg = styled.img`
  width: 146px;
  margin-top: 37px;
`;
//
export const ProfileWrapperDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
`;
