import styled, { css } from 'styled-components/macro';

const validationCircle = css`
  content: '';
  position: absolute;
  top: 25px;
  right: 6px;
  border-radius: 3px;
  width: 6px;
  height: 6px;
  display: inline-block;
`;

export const InputDiv = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0;
  ${props =>
    props.disabled &&
    css`
      border-bottom-color: ${props => props.theme.athensGray};
    `}
  ::after {
    ${props =>
      !props.readOnly &&
      props.meta.touched &&
      (props.meta.error
        ? css`
          ${validationCircle}
          background-color: ${props => props.theme.red};
        `
        : css`
          ${validationCircle}
          background-color: ${props => props.theme.green};
        `)}
      }
  ${props =>
    !props.readOnly &&
    props.meta.touched &&
    props.meta.error &&
    !props.disabledErrorMargin &&
    css`
      margin: 20px 0px 40px;
    `}
  ${props =>
    props.showNumberWheel ||
    css`
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `}
  ${props =>
    props.showNumberWheel === 4 &&
    !props.disabled &&
    css`
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: default;
        opacity: 1;
        transform: translateX(-45px);
      }
      &::placeholder {
        opacity: 0.5;
      }
    `}
  ${props =>
    props.showNumberWheel === 3 &&
    !props.disabled &&
    css`
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: default;
        opacity: 1;
        transform: translateX(-32px);
      }
      &::placeholder {
        opacity: 0.5;
      }
    `}
`;

export const DisplayUnitsP = styled.p`
  position: absolute;
  right: 0;
  bottom: 4px;
  margin-right: 20px;
  ${props => props.theme.fontStackHeader};
  font-weight: 400;
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.mercury};
      &::placeholder {
        opacity: 0.5;
      }
    `};
`;

export const InputValidationSmall = styled.small`
  position: absolute;
  top: calc(100% + 0.3rem);
  right: 0;
  color: ${props => props.theme.red} !important;
  line-height: 1.7rem;
  ${props => props.theme.fontStackBody}
  ${props =>
    !(props.meta && props.meta.touched && props.meta.error) &&
    css`
      display: none;
    `};
  ${props =>
    props.rejected &&
    css`
      display: block;
    `}}
`;

export const SubmitValidationSmall = styled.small`
  text-align: right;
  display: block;
  margin-top: 1rem;
  color: ${props => props.theme.red};
  line-height: 1.7rem;
  ${props => props.theme.fontStackBody}
`;

const InputLabelCss = css`
  display: block;
  ${props => props.theme.fontStackHeader};
  font-style: normal;
  font-weight: 600;
  text-transform: none;
  transform: translate(0, 0);
  transition: all 0.2s ease;
  user-select: none;
  z-index: 0;
  font-size: 1.3rem;
  height: 1.6rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props =>
    props.disabled &&
    !props.readOnly &&
    css`
      transition: none;
      color: ${props => props.theme.mercury};
    `}
  /* ${props =>
    ((!props.meta.active && !props.input.value) ||
      (!props.input.value && props.readOnly)) &&
    props.meta.touched &&
    props.meta.error &&
    css`
      color: ${props => props.theme.red};
    `} */
`;

export const InputLabel = styled.label`
  position: relative;
  color: black;
  width: 80%;
  ${InputLabelCss};
  ${props =>
    props.opened ||
    (!props.meta.active &&
      !props.value &&
      css`
        transform: translate(0, 150%);
        ${props =>
          props.inactiveFontSize && `font-size: ${props.inactiveFontSize};`}
        color: ${props => props.theme.dustierGray};
        font-weight: 300;
        text-transform: none;
      `)}
`;

export const InputInlineLabel = styled.label`
  position: absolute;
  margin-bottom: 5px;
  color: ${props => props.theme.dustyGray};
  bottom: 0;
  ${InputLabelCss};
  font-weight: 300;
  ${props =>
    !props.meta.active &&
    !props.input.value &&
    css`
      color: ${props => props.theme.dustierGray};
    `}
  ${props =>
    props.hex &&
    css`
      padding-bottom: 33px;
      font-size: 2.8rem;
      /* bottom: inherit; */
      overflow: visible;
    `}
`;

export const StyledInputCss = css`
  font-size: ${props => props.fontSize || '1.4rem'};
  ${props => props.theme.fontStackHeader};
  font-style: normal;
  font-weight: 400;
  position: relative;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${props => props.theme.mercury};
  background-color: transparent !important;
  &:focus {
    outline: none;
    padding-bottom: 4px;
    border-bottom: 2px solid ${props => props.theme.brand};
  }
  &::placeholder {
    color: ${props => props.theme.dustierGray};
  }
  &[type='number'] {
    -moz-appearance: textfield !important;
  }
  &[readonly] {
    border-color: transparent;
    color: black;
  }
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.dustierGray};
      &::placeholder {
        opacity: 0.5;
      }
    `}
  ${props =>
    props.meta.touched &&
    props.meta.error &&
    css`
      &,
      &:focus {
        border-bottom-color: ${props => props.theme.red};
      }
    `};
`;

export const StyledInlineInput = styled.input`
  padding-left: 28px;
  width: calc(100% + 35px);
  /* width: 100%; */
  ${StyledInputCss};
  ${props =>
    props.hex &&
    css`
      width: 100%;
      color: ${props => props.theme.dustyGray};
      padding-left: 60px;
      font-size: 2.8rem;
      &:focus {
        color: black;
      }
      @media screen and (min-width: 1024px) {
        width: 300px;
      }
    `};
`;

export const StyledInput = styled.input`
  width: 100%;
  ${StyledInputCss};
  ${props =>
    props.readOnly &&
    css`
      pointer-events: none;
    `}
`;

export const IconWrapperDiv = styled.div`
  position: absolute;
  bottom: 5px;
  right: 20px;
`;
