//@flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

type Props = {|
  component: Function,
  isAuthenticated: boolean,
  path: string,
  exact?: boolean
|};

const NoAuthRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={routeProps => {
      let redirectRoute = '/';
      const routeState = routeProps.location.state;
      if (routeState && routeState.redirectCount < 2)
        redirectRoute = routeState.from;
      return isAuthenticated ? (
        <Redirect
          to={{
            pathname: redirectRoute,
            state: { redirectCount: 1 }
          }}
        />
      ) : (
        <Component {...routeProps} />
      );
    }}
  />
);
export default NoAuthRoute;
