// @flow
import React, { Component } from 'react';
import Lottie from './Lottie';
import styled from 'styled-components';

const PageLoaderLottie = styled(Lottie)`
  position: fixed;
  top: 50%;
  left: 50%;
  height: 120px;
  transform: translate(-50%, -50%);
`;
type Props = {};

export default class PageLoader extends Component<Props> {
  render() {
    return <PageLoaderLottie name="page-loader" boomerang />;
  }
}
