import styled, { css } from 'styled-components/macro';
import Input from '../../../components/Input';
import { StyledInput } from '../../../components/InputStyles';

export const TwoFactorModalContentDiv = styled.div`
  h4 {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 767px) {
    padding: 8rem 4rem 4.6rem;
  }
  @media screen and (min-width: 768px) {
    padding: 8rem 7rem 4.6rem;
    h4 {
      font-size: 16px;
    }
  }

  > p {
    text-align: center;
  }
`;

export const EnableEmailTwoFactorForm = styled.form``;

export const GoogleAuthQrDiv = styled.div`
  text-align: center;
  padding: 0 3rem;
  @media screen and (min-width: 768px) {
    padding: 0 5rem;
  }
  p {
    margin-bottom: 9px;
    line-height: 1.7rem;
  }
`;

export const EmphasizeText = styled.span`
  ${props =>
    props.color &&
    css`
      color: ${props => props.theme[props.color]};
    `}
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `}
    ${props =>
      props.brand &&
      css`
        color: props.theme.brand;
      `}
`;

export const QrDiv = styled.div`
  margin: 2rem 0rem;
  padding: 0.8rem;
`;

export const BackupCodeDiv = styled.div`
  h5 {
    display: inline;
  }
  margin-top: 3rem;
`;

export const BackupCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    margin-bottom: 5px;
  }
`;

export const BackupCodeH5 = styled.h5`
  font-weight: 300;
  letter-spacing: 0.04rem;
`;

export const BackupInstructionsP = styled.p`
  && {
    line-height: 1.7rem;
  }
  margin-top: 0.3rem;
  text-align: center;
  font-style: italic;
  color: ${props => props.theme.dustyGray};
`;

export const StyledInputLarge = styled(Input)`
  font-weight: 300;
  &::placeholder {
    color: ${props => props.theme.mercury};
    font-size: 3.5rem;
    text-align: center;
    ${props => props.theme.fontStackHeader};
  }
  ${StyledInput} {
    text-align: center;
    height: 5rem;
    font-size: 3.5rem;
  }
`;
