import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import global, { LOGOUT } from './App/reducer';
import dashboard from './App/Dashboard/reducer';
import fees from './App/Fees/reducer';
import transactions from './App/Transactions/reducer';
import posTerminals from './App/PosTerminals/reducer';
import payouts from './App/Payouts/reducer';
import hostedPayments from './App/HostedPayments/reducer';
import settlementOptions from './App/SettlementOptions/reducer';
import subUsers from './App/UsersGroupsSettings/reducer';
// import Branding from './App/Branding/reducer';
import verification from './App/Verification/reducer';
import merchantDocumentCollection from './App/MerchantDocumentCollection/reducer';
import modalConductor from './modules/ModalConductor/reducer';
import ecommercePlugins from './App/EcommercePlugins/reducer';
import assetManagement from './App/AssetManagement/reducer';
import currencySettings from './App/CurrencySettings/reducer';
import signIn from './App/SignIn/reducer';
import { verificationFormReducer } from './App/Verification/reducer';
import apiKeys from './App/ApiKeys/reducer';
import branding from './App/Branding/CompanyDisplayName/reducer';
import billing from './App/Billing/reducer';
import company from './App/Company/reducer';
import refunds from './App/Refunds/reducer';
import acceptRefund from './App/AcceptRefund/reducer';
import marketing from './App/Marketing/reducer';
import invoices from './App/Invoices/reducer';
import support from './App/MerchantSupport/reducer';

const reducer = combineReducers({
  form: formReducer.plugin({
    verification: verificationFormReducer
    // tolerance: toleranceReducer
  }),
  global,
  currencySettings,
  company,
  dashboard,
  fees,
  transactions,
  posTerminals,
  payouts,
  hostedPayments,
  modalConductor,
  ecommercePlugins,
  subUsers,
  settlementOptions,
  verification,
  merchantDocumentCollection,
  apiKeys,
  signIn,
  billing,
  assetManagement,
  refunds,
  acceptRefund,
  marketing,
  invoices,
  support,
  branding
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    const { global } = state;
    state = { global };
  }

  return reducer(state, action);
};

export default rootReducer;
