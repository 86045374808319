// @flow
import React, { Component } from 'react';
import { TooltipDiv } from './SidebarItemStyles';

type Props = {
  tooltip: {
    title: string,
    top: number,
    left: number
  }
};

export default class Tooltip extends Component<Props> {
  render() {
    return (
      <TooltipDiv tooltip={this.props.tooltip}>
        <h6>{this.props.tooltip.title}</h6>
      </TooltipDiv>
    );
  }
}
