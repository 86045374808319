import { takeLatest, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import i18n from '../../i18n';
import {
  DOWNLOAD_FILE,
  setMarketingFiles,
  LOAD_MARKETING_FILES
} from './reducer';
import { getRequest, handleSagaError } from '../../utilities/requests';
import { history } from '../../utilities/history';

function* downloadfileEffect(action) {
  try {
    const url = action.url;
    const response = yield getRequest(url, {}, { responseType: 'blob' });
    saveAs(response.data, action.fileName);
  } catch (error) {
    handleSagaError(error, i18n.t('marketing:errorMessage'));
  }
}

export function* marketingSaga() {
  yield takeLatest(DOWNLOAD_FILE, downloadfileEffect);
}

function* handleLoadMarketingFiles() {
  try {
    const url = '/marketing_files';
    const response = yield getRequest(url);
    yield put(setMarketingFiles(response.data.marketing_files));
  } catch (error) {
    history.push('/error');
  }
}

export function* loadMarketingFilesSaga() {
  yield takeLatest(LOAD_MARKETING_FILES, handleLoadMarketingFiles);
}
