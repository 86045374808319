import styled, { css } from 'styled-components/macro';
import NavBarOpenedBackground from '../../../assets/images/mobile-menu-bg.png';
import { ReactComponent as Chevron } from '../../../assets/icons/chevron-down.svg';
import { Link } from 'react-router-dom';
// $FlowFixMe
import { ReactComponent as x } from '../../../assets/icons/x.svg';
// $FlowFixMe
import { ReactComponent as QuestionMark } from '../../../assets/icons/questionmark.svg';
import { ActionLink } from '../../../components/LinkStyles';
import { mobileHeaderHeight } from '../../Navbar/NavbarStyles';
import Icon from '../../../components/Icon';
import { PrimaryButton } from '../../../components/ButtonStyles';

export const LogoImg = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 20px;
`;

export const ButtonLeftDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const navbar = css`
  position: relative;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${mobileHeaderHeight};
`;

export const NavbarOpenedWrapperDiv = styled.div`
  background-image: url(${NavBarOpenedBackground});
  width: 100%;
  background-color: #cccccc;
  min-height: 425px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  z-index: 10;
  ${props => props.theme.bottomDropShadow};
`;

export const NavHeaderH6 = styled.h6`
  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.brand};
      text-decoration-color: ${props => props.theme.brand};
      text-decoration: underline;
    `}
`;

export const NavItemsWrapperDiv = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const ProfileWrapperDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavbarClosedDiv = styled.div`
  ${navbar};
  background-color: white;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 0px 3px 1px;
`;

export const NavbarOpenedDiv = styled.div`
  ${navbar};
`;

export const ChevronWrapperDiv = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
  cursor pointer;
`;
export const ChevronSvg = styled(Chevron)`
  position: inherit;
  width: 10px;
  height: 10px;
`;
export const XSvg = styled(x)`
  width: 15px;
  height: 15px;
  margin-right: 20px;
`;
export const QuestionMarkSvg = styled(QuestionMark)`
  margin-left: 10px;
  width: 15px;
  height: 15px;
`;

const navHeaderStyles = css`
  padding-left: 10px;
  padding-right: 10px;
  h6 {
    ${props => props.theme.fontStackHeader};
    font-weight: 400;
  }
`;
export const NavHeaderAction = styled.a`
  ${navHeaderStyles}
`;

export const NavHeaderLink = styled(ActionLink)`
  ${navHeaderStyles}
`;

export const LogoLink = styled(Link)`
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

// Navbar Opened styles
export const NavBarOpenedBackgroundOverlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99;
`;

export const NavBarOpenedContainer = styled.div`
  background-color: #fff;
  position: absolute;
  right: 0;
  width: 294px;
  height: 100vh;
  overflow-y: scroll;
`;

export const NavBarOpenedItemContainer = styled.li`
  display: flex;
  align-items: center;
  padding: 17px 0;
  padding-left: 34px;
  ${props =>
    props.isActive &&
    css`
      border-left: 4px solid ${props => props.theme.brand};
      svg {
        fill: ${props => props.theme.brand};

        ${props =>
          props.isActive &&
          css`
            // Styles for navListBottom, , offsets active left border
            transform: translateX(-4px);
          `};
      }

      ${NavBarOpenedItemText} {
        color: ${props => props.theme.brand};
      }
    `}
`;

export const NavBarOpenedItemText = styled(Link)`
  ${props => props.theme.fontStackHeader}
  text-transform: capitalize;
  font-weight: 400;
  color: #000;

  ${props =>
    props.hasIcon &&
    css`
      margin-left: ${props.isActive ? '17px' : '21px'};
    `};

  ${props =>
    props.isActive &&
    !props.hasIcon &&
    css`
      // Styles for navListBottom, offsets active left border
      transform: translateX(-4px);
    `};
`;

export const Divider = styled.div`
  content: '';
  width: 80%;
  border-bottom: 1px solid ${props => props.theme.mercury};
  margin: 17px auto 6px;
`;

export const NavBarOpenedTopLinks = styled.div`
  padding-top: 45px;
`;

export const CloseIcon = styled(Icon)`
  fill: black;
  position: absolute;
  top: 37px;
  right: 30px;
  width: 14px;
  z-index: 9999999;
  cursor: pointer;
`;

export const LogoutButton = styled(PrimaryButton)`
  width: 224px;
  margin: 16px auto 25px;
  font-weight: 600;
`;
