// @flow
import React, { Component } from 'react';
import { NavbarClosedDiv, LogoImg } from './MobileHeaderStyles';
import { LogoLink } from './MobileHeaderStyles';

type Props = {
  logo: string,
  external: boolean
};

export default class SimpleMobileHeader extends Component<Props> {
  render() {
    return (
      <NavbarClosedDiv>
        <LogoLink to="/" disabled={this.props.external}>
          <LogoImg src={this.props.logo} alt="logo" />
        </LogoLink>
      </NavbarClosedDiv>
    );
  }
}
