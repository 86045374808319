import React from 'react';
import maintenanceImage from '../../assets/images/maintenance-msg.png';
import { MaintenanceDiv, MaintenanceImg } from './ErrorStyles';

export default class Maintenance extends React.Component {
  render() {
    return (
      <MaintenanceDiv className="maintenance-container">
        <MaintenanceImg src={maintenanceImage} />
      </MaintenanceDiv>
    );
  }
}
