import i18n from '../../i18n';
import { takeEvery, put } from 'redux-saga/effects';
import {
  ACTIVATE_HP_PLUGIN,
  DEACTIVATE_HP_PLUGIN,
  updateHpPlugin
} from './reducer';
import { patchRequest, handleSagaError } from '../../utilities/requests';
import { showSuccessModal } from '../../modules/ModalConductor/reducer';

function* handleUpdateHpPlugin(id, payload, successModal) {
  try {
    const url = `/hosted_payments_plugin/${id}`;
    const response = yield patchRequest(url, payload);
    yield put(updateHpPlugin(response.data.hosted_payments_plugin));
    yield put(showSuccessModal(successModal.title, successModal.message));
  } catch (error) {
    yield handleSagaError(error);
  }
}

function* handleActivateHpPlugin(action) {
  const modalPayload = {
    title: i18n.t('hostedPayments:modal.pluginNotice.activated.title'),
    message: i18n.t('hostedPayments:modal.pluginNotice.activated.message')
  };
  yield handleUpdateHpPlugin(action.id, { active: true }, modalPayload);
}

function* handleDeactivateHpPlugin(action) {
  const modalPayload = {
    title: i18n.t('hostedPayments:modal.pluginNotice.deactivated.title'),
    message: i18n.t('hostedPayments:modal.pluginNotice.deactivated.message')
  };
  yield handleUpdateHpPlugin(action.id, { active: false }, modalPayload);
}

export function* activateHpPlugin() {
  yield takeEvery(ACTIVATE_HP_PLUGIN, handleActivateHpPlugin);
}

export function* deactivateHpPlugin() {
  yield takeEvery(DEACTIVATE_HP_PLUGIN, handleDeactivateHpPlugin);
}
