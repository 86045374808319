import React from 'react';
import Responsive from 'react-responsive';

export const LgDesktop = props => <Responsive {...props} minWidth={1440} />;
export const NotLgDesktop = props => <Responsive {...props} maxWidth={1439} />;
export const SmDesktop = props => (
  <Responsive {...props} minWidth={1024} maxWidth={1439} />
);
export const Desktop = props => <Responsive {...props} minWidth={1024} />;
export const Tablet = props => (
  <Responsive {...props} minWidth={768} maxWidth={1023} />
);
export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const Device = props => <Responsive {...props} maxWidth={1023} />;
export const Default = props => <Responsive {...props} minWidth={768} />;
