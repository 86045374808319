import styled, { css } from 'styled-components/macro';
import { paddingDefault, paddingDesktop } from '../styles/utils';
export const ChildrenWrapper = styled.div`
  position: relative;
  background-color: white;
  padding-bottom: 10rem;

  @media screen (max-width: 767px) {
    margin-top: 0px;
  }

  @media screen and (min-width: 768px) {
    margin-top: 50px;
    margin-left: 68px;
  }

  @media screen and (min-width: 1024px) {
    margin-left: ${props => (props.sidebarToggle ? '230px' : '68px')};
    margin-top: 50px;
    border: ${props => props.theme.defaultBorder};
  }
`;

export const ToggleWrapperStyles = css`
  margin-bottom: 1.5rem;
  padding-top: 3rem;
  @media screen and (max-width: 767px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 3rem ${paddingDefault} 0rem;
  }
  @media screen and (min-width: 1024px) {
    padding: 6rem ${paddingDefault} 0rem;
  }
  @media screen and (min-width: 1440px) {
    padding: 6rem ${paddingDesktop} 0rem;
  }
`;
