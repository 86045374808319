import styled, { css } from 'styled-components/macro';

export const StyledSpan = styled.span`
  ${props =>
    props.italic &&
    css`
      font-style: italic;
    `}
  ${props =>
    props.upperCase &&
    css`
      text-transform: uppercase;
    `}
    ${props =>
      props.color &&
      css`
        color: ${props.theme[props.color]};
      `}
    ${props =>
      props.bold &&
      css`
        font-weight: 600;
      `}
    ${props =>
      props.hoverable &&
      css`
        cursor: pointer;
      `}
    ${props =>
      props.headerText &&
      css`
        ${props => props.theme.fontStackHeader}
      `}
    ${props =>
      props.capitalize &&
      css`
        text-transform: capitalize;
      `}
      ${props =>
        props.brand &&
        css`
          color: ${props.theme.brand};
        `}
`;

export const H10 = styled.h5`
  font-size: 12px;
  @media screen and (min-width: 1024px) {
    font-size: 13px;
  }
`;

export const H6 = styled.h5`
  font-size: 1.6rem;
  font-weight: 400;
  @media screen and (min-width: 1024px) {
    font-size: 1.8rem;
  }
  letter-spacing: 0.03em;
  line-height: 127%;
`;
