// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SalesMaterialItemDiv,
  DownloadLinkA,
  BorderDiv,
  DownloadLinkDesktopA,
  SalesMaterialItemHeader,
  SalesMaterialDescription,
  FileNameLabel
} from './MarketingStyles';
import { type MarketingFile } from '.';
import Icon from '../../components/Icon';
import { Desktop, Device } from '../../components/Responsive';

export const MarketingBody = ({
  marketingFiles
}: {
  marketingFiles: MarketingFile[]
}) => {
  const { t } = useTranslation(['global']);

  return (
    <>
      {marketingFiles.map(file => (
        <SalesMaterialItemDiv key={file.name}>
          <SalesMaterialItemHeader>
            <Icon name="chevronRight" />
            <Device>
              <DownloadLinkA
                href={file.url}
                target="_blank"
                download={`NetCents ${file.name}`}
              >
                <FileNameLabel>{file.name}</FileNameLabel>
              </DownloadLinkA>
            </Device>
            <Desktop>
              <DownloadLinkDesktopA
                href={file.url}
                target="_blank"
                download={`NetCents ${file.name}`}
              >
                <FileNameLabel>{file.name}</FileNameLabel>
                <BorderDiv />
                <span>
                  {t('actions.download')} {file.file_type}
                </span>
              </DownloadLinkDesktopA>
            </Desktop>
          </SalesMaterialItemHeader>
          {file.description && (
            <SalesMaterialDescription>
              <p>{file.description}</p>
            </SalesMaterialDescription>
          )}
        </SalesMaterialItemDiv>
      ))}
    </>
  );
};
