export const LOAD_ASSET_BALANCES = 'LOAD_ASSET_BALANCES';
export const SET_ASSET_BALANCES = 'SET_ASSET_BALANCES';

export const setAssetBalances = assets => ({
  type: SET_ASSET_BALANCES,
  assets
});
export const loadAssetBalances = () => ({
  type: LOAD_ASSET_BALANCES
});

export default (state = {}, action) => {
  switch (action.type) {
    case SET_ASSET_BALANCES:
      return action.assets;

    default:
      return state;
  }
};
