import axios from 'axios';
import i18n from '../../../i18n';
import { SubmissionError } from 'redux-form';
import jwtDecode from 'jwt-decode';
// import { setToken } from '../../../utilities/tokenStorage';
// import { setInitialState, setUser } from '../../../App/reducer';
import { handleSignInSuccess } from '../../../App/SignIn/handleSubmit';
import { history } from '../../../utilities/history';

const handleSubmit = async (values, dispatch, props) => {
  const url = '/sessions/verify_two_factor_code';
  try {
    const response = await axios.post(url, values, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    });
    handleResponse(response, dispatch);
    return response;
  } catch (error) {
    let message = i18n.t('global:errorMessage.default');
    if (error.response) {
      message =
        error.response.data.error || error.response.data.details || message;
    }
    throw new SubmissionError({ _error: message });
  }
};

function handleResponse(response, dispatch) {
  try {
    const token = response.data.jwt;
    const userDetails = jwtDecode(token);
    if (userDetails.fully_signed_in) {
      handleSignInSuccess(response, dispatch, token, userDetails);
    } else {
      history.push('/signin');
    }
  } catch (error) {
    //
  }
}

export default handleSubmit;
