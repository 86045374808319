export const LOAD_FEES = 'LOAD_FEES';
export const SET_FEES = 'SET_FEES';
export const FAILED_FEES_LOAD = 'FAILED_FEES_LOAD';

export const setFees = fees => ({
  type: SET_FEES,
  fees
});
export const loadFees = () => ({
  type: LOAD_FEES
});
export const failedFeesLoad = () => ({
  type: FAILED_FEES_LOAD
});
const initialState = { loading: true, fees: {} };
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FEES:
      return {
        ...state,
        fees: action.fees,
        loading: false
      };
    default:
      return state;
  }
};
