import { takeEvery, put } from 'redux-saga/effects';
import i18n from '../../../../i18n';
import {
  LOAD_INVOICE,
  setInvoice,
  RESEND_INVOICE,
  CANCEL_INVOICE,
  REACTIVATE_INVOICE,
  MARK_COMPLETE_INVOICE
} from './reducer';
import {
  handleSagaError,
  getRequest,
  postRequest,
  deleteRequest
} from '../../../../utilities/requests';
import {
  showSuccessModal,
  showModal
} from '../../../../modules/ModalConductor/reducer';
import { updateInvoice } from '../Index/reducer/invoices';
import { markTransactionComplete } from '../../../Transactions/sagas';

function* handleLoadInvoice(action) {
  try {
    const url = `/invoices/${action.id}`;
    const response = yield getRequest(url);
    yield put(setInvoice(response.data));
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* loadInvoice() {
  yield takeEvery(LOAD_INVOICE, handleLoadInvoice);
}

function* handleResendInvoice(action) {
  try {
    const url = `/invoices/${action.id}/resend`;
    yield postRequest(url);
    yield put(
      showSuccessModal(
        i18n.t('invoices:modal.resendInvoiceNotice.title'),
        i18n.t('invoices:modal.resendInvoiceNotice.message')
      )
    );
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* resendInvoiceSaga() {
  yield takeEvery(RESEND_INVOICE, handleResendInvoice);
}

function* handleCancelInvoice(action) {
  try {
    const url = `/invoices/${action.id}`;
    const response = yield deleteRequest(url);
    yield put(updateInvoice(response.data));
    yield put(
      showSuccessModal(
        i18n.t('invoices:modal.cancelInvoiceNotice.title'),
        i18n.t('invoices:modal.cancelInvoiceNotice.message')
      )
    );
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* cancelInvoiceSaga() {
  yield takeEvery(CANCEL_INVOICE, handleCancelInvoice);
}

function* handleReactivateInvoice(action) {
  try {
    const url = `/invoices/${action.id}/reactivate`;
    const response = yield postRequest(url);
    yield put(updateInvoice(response.data));
    yield put(
      showSuccessModal(
        i18n.t('invoices:modal.reactivateInvoiceNotice.title'),
        i18n.t('invoices:modal.reactivateInvoiceNotice.message')
      )
    );
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* reactivateInvoiceSaga() {
  yield takeEvery(REACTIVATE_INVOICE, handleReactivateInvoice);
}

function* handleMarkCompleteInvoice(action) {
  try {
    yield markTransactionComplete(action.invoice.transaction.id);
    yield put(showModal());
    const url = `/invoices/${action.invoice.id}`;
    const response = yield getRequest(url);
    yield put(updateInvoice(response.data));
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* markCompleteInvoiceSaga() {
  yield takeEvery(MARK_COMPLETE_INVOICE, handleMarkCompleteInvoice);
}
