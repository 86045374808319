// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, type Match } from 'react-router-dom';
import AccountSetupForm from './AccountSetupForm';
import { SignInBackground, SignInDiv } from '../SignIn/SignInStyles';
import { defaultSubmitHandler } from '../../utilities/requests';

type MappedProps = {
  termsOfServiceUrl: string
};
type MappedActions = {};
type Props = {
  match: Match
};
type ComponentProps = Props & MappedProps & MappedActions;

class index extends Component<ComponentProps> {
  render() {
    const token = this.props.match.params.token;
    if (!token) {
      return <Redirect to="/signin" />;
    }
    return (
      <SignInBackground>
        <SignInDiv>
          <AccountSetupForm
            termsOfServiceUrl={this.props.termsOfServiceUrl}
            onSubmit={defaultSubmitHandler(`/users/${token}/activate`)}
          />
        </SignInDiv>
      </SignInBackground>
    );
  }
}

const mapStateToProps = (state): MappedProps => ({
  termsOfServiceUrl: state.global.tenant.termsOfServiceUrl
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
