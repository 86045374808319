import { takeLatest, put } from 'redux-saga/effects';
import {
  LOAD_AVAILABLE_ASSETS,
  setAvailableAssets
} from '../reducer/acceptedCurrencies';
import { getRequest } from '../../../utilities/requests';
import { history } from '../../../utilities/history';

function* handleLoadAvailableAssets() {
  try {
    const url = '/asset_management/edit_settings';
    const response = yield getRequest(url);
    yield put(setAvailableAssets(response.data.available_assets));
  } catch (error) {
    history.push('/error');
    // console.error(error);
  }
}

export function* loadAvailableAssetsSaga() {
  yield takeLatest(LOAD_AVAILABLE_ASSETS, handleLoadAvailableAssets);
}
