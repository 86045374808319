import styled, { css } from 'styled-components/macro';
import Icon from './Icon';

export const CheckboxContainer = styled.div`
  margin: 20px 0;
  display: flex;
  input {
    visibility: hidden;
    position: absolute;
  }
`;

export const CheckboxDiv = styled.div`
  height: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  cursor: arrow;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
      /* Case1: ReadOnly/Disabled && Checked */
    ${props =>
      (props.readOnly || props.disabled) &&
      props.checked &&
      css`
        border: 1px solid ${props => props.theme.dustierGray};
        background-color: ${props => props.theme.dustierGray};
      `}
    /* Case2: ReadOnly/Disabled && Unchecked */
    ${props =>
      (props.readOnly || props.disabled) &&
      !props.checked &&
      css`
        border: 1px solid ${props => props.theme.mercury};
        background-color: ${props => props.theme.white};
      `}
    /* Case3: Active && Checked */
    ${props =>
      !props.readOnly &&
      !props.disabled &&
      props.checked &&
      css`
        border: 1px solid ${props => props.theme.blue};
        background-color: ${props => props.theme.blue};
      `}
    /* Case4: Active && Unchecked */
    ${props =>
      !props.readOnly &&
      !props.disabled &&
      !props.checked &&
      css`
        border: 1px solid ${props => props.theme.blue};
        background-color: ${props => props.theme.white};
      `}
      &:hover {
      ${props =>
        !props.readOnly &&
        !props.disabled &&
        css`
          box-shadow: 0px 0px 0px 2px ${props => props.theme.blue};
        `}
  }
`;

export const CheckboxLabel = styled.label`
  margin-left: 1.2rem;
  margin-top: -0.25rem;
  line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 400;
  ${props => props.theme.fontStackHeader};
`;

export const CheckmarkIcon = styled(Icon)`
  height: 0.7rem;
  fill: ${props => props.theme.white};
`;

export const ValidationSmall = styled.small`
  display: block;
  text-align: right;
  color: ${props => props.theme.red};
  margin: -15px 0 20px;
  /* margin-left: 10px; */
`;
