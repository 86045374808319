export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const ERROR_MODAL = 'ERROR_MODAL';
export const SUCCESS_MODAL = 'SUCCESS_MODAL';
export const SELECT_TWO_FACTOR_METHOD_MODAL = 'SELECT_TWO_FACTOR_METHOD_MODAL';
export const ENABLE_EMAIL_TWO_FACTOR_AUTH_MODAL =
  'ENABLE_EMAIL_TWO_FACTOR_AUTH_MODAL';
export const DISABLE_TWO_FACTOR_AUTH_MODAL = 'DISABLE_TWO_FACTOR_AUTH_MODAL';
export const ENABLE_GOOGLE_AUTH_MODAL = 'ENABLE_GOOGLE_AUTH_MODAL';
export const VERIFY_TWO_FACTOR_AUTH_MODAL = 'VERIFY_TWO_FACTOR_AUTH_MODAL';
export const CONDITIONALLY_APPROVED_MODAL = 'CONDITIONALLY_APPROVED_MODAL';
