export const clearLiveChat = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.remove();
  } else {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.remove();
      }
    ];
  }
};
