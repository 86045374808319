//styles broken atm

//@flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  PasswordValidationWrapper,
  PasswordBackground,
  PasswordText
} from './PasswordValidationStyles';

type Props = {
  touched: boolean,
  warning: ?string,
  error: ?string,
  t: TFunction
};

class PasswordValidation extends Component<Props> {
  render() {
    const { t } = this.props;
    let text = t('validations.passwordEmpty');
    if (this.props.touched && this.props.error) {
      text = this.props.error;
    } else if (this.props.touched && this.props.warning) {
      text = this.props.warning;
    } else if (this.props.touched) {
      text = t('validations.strongPassword');
    }

    return (
      <PasswordValidationWrapper>
        <PasswordBackground color={this.props.touched ? 'red' : 'athensGray'} />
        <PasswordBackground
          color={this.props.error ? 'athensGray' : 'yellow'}
        />
        <PasswordBackground
          color={this.props.warning ? 'athensGray' : 'graphicGreen'}
        />
        <PasswordText>{text}</PasswordText>
      </PasswordValidationWrapper>
    );
  }
}
export default withTranslation(['global'])(PasswordValidation);
