//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavbarClosed from './NavbarClosed.jsx';
import NavbarOpened from './NavbarOpened.jsx';
import SimpleMobileHeader from './SimpleMobileHeader';
import { logout, toggleMobileNavbar } from '../../../App/reducer';
import { type Permissions, type User } from '../../../utilities/types';
import nc from '../../../assets/images/nc.png';

type Props = {
  simple: boolean,
  toggleMobileNavbar: () => void,
  permissions: Permissions,
  user: User,
  logo: ?string,
  logout: () => void,
  mobileNavBarToggle: boolean,
  platformLogoSmall: string,
  external: boolean
};

class MobileHeader extends Component<Props> {
  render() {
    if (this.props.simple) {
      return (
        <SimpleMobileHeader
          logo={this.props.platformLogoSmall || nc}
          external={this.props.external}
        />
      );
    }
    return this.props.mobileNavBarToggle ? (
      <NavbarOpened
        toggleMobileNavbar={this.props.toggleMobileNavbar}
        user={this.props.user}
        permissions={this.props.permissions}
        logo={this.props.logo}
        logout={this.props.logout}
      />
    ) : (
      <NavbarClosed
        logo={this.props.platformLogoSmall || nc}
        toggleMobileNavbar={this.props.toggleMobileNavbar}
      />
    );
  }
}

const mapStateToProps = state => ({
  platformLogoSmall: state.global.platformLogoSmall,
  user: state.global.user,
  permissions: state.global.permissions,
  logo: state.global.brandLogo,
  mobileNavBarToggle: state.global.mobileNavBarToggle
});

const mapDispatchToProps = {
  logout,
  toggleMobileNavbar
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHeader);
