import { combineReducers } from 'redux';
import users from './users';
import usersView from './usersView';
import groups from './groups';
import groupsView from './groupsView';

const reducer = combineReducers({
  users,
  groups,
  usersView,
  groupsView
});

export default reducer;
