//@flow
import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import { colorify } from 'lottie-colorify';
import lottie from 'lottie-web';
import { type ReactObjRef } from '../utilities/types';

import notFound from '../assets/animations/not-found';
import coffeeCup from '../assets/animations/coffee-cup';
import pageLoader from '../assets/animations/page-loader';
import smallerLoader from '../assets/animations/smaller-loader';
import noAssets from '../assets/animations/no-assets';
import paymentCheck from '../assets/animations/payment-check';
import exclamation from '../assets/animations/exclamation';
import magnifyingGlass from '../assets/animations/magnifying-glass';
import getVerified from '../assets/animations/get-verified';
import { factoryNetCentsUser } from '../utilities/factorySettings';

const animations = {
  'not-found': notFound,
  'coffee-cup': coffeeCup,
  'page-loader': pageLoader,
  'smaller-loader': smallerLoader,
  'no-assets': noAssets,
  'payment-check': paymentCheck,
  'magnifying-glass': magnifyingGlass,
  'get-verified': getVerified,
  exclamation
};

type Props = {
  loopInterval?: number,
  name: string,
  autoplay?: boolean,
  className?: string,
  loop: boolean,
  boomerang?: boolean,
  playOnScroll?: boolean,
  theme: {
    brand: string,
    mercury: string,
    white: string,
    isWhiteLabel: boolean
  }
};
type LottieAnimation = {
  goToAndPlay: (value: number) => void,
  setDirection: (value: number) => void,
  play: () => void,
  removeEventListener: (type: string, callback: Function) => void
};
type State = {
  direction: number
};

class Lottie extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.el = React.createRef();
    this.state = {
      direction: 1
    };
  }
  static defaultProps = {
    autoplay: true,
    loopInterval: 0,
    loop: false
  };
  el: ReactObjRef<'div'>;
  loop: IntervalID;
  animation: LottieAnimation;

  componentDidMount() {
    let { autoplay } = this.props;
    const el = this.el.current;
    if (!el) {
      return;
    }
    if (this.props.playOnScroll) {
      autoplay = window.innerHeight + window.scrollY - 300 > el.offsetTop;
      window.addEventListener('scroll', this.handleScroll);
    }

    this.animation = lottie.loadAnimation({
      container: el,
      renderer: 'svg',
      loop: this.props.loop,
      autoplay,
      animationData: colorify(
        this.handleColorFilters(),
        animations[this.props.name]
      )
    });

    if (this.props.loopInterval) {
      this.loop = setInterval(() => {
        this.animation.goToAndPlay(0);
      }, this.props.loopInterval);
    }
    if (this.props.boomerang) {
      this.animation.addEventListener('complete', this.handleLoop);
    }
  }

  componentWillUnmount() {
    clearInterval(this.loop);
    if (this.props.boomerang) {
      this.animation.removeEventListener('complete', this.handleLoop);
    }
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleColorFilters = () => {
    /**
     * @description
     * Can't use this.props.theme.isWhitelabel. Initial loading screen needs to know if NetCents user before api calls are made
     */
    if (!factoryNetCentsUser()) {
      switch (this.props.name) {
        case 'not-found':
        case 'no-assets':
          return;
        case 'coffee-cup':
          return Array(7).fill(this.props.theme.brand);
        case 'page-loader':
          return Array(4).fill(this.props.theme.mercury);
        case 'smaller-loader':
          return Array(1).fill(this.props.theme.brand);
        case 'payment-check':
          return [
            this.props.theme.brand,
            this.props.theme.white,
            this.props.theme.white,
            this.props.theme.brand,
            this.props.theme.brand,
            this.props.theme.brand
          ];
        default:
          return [];
      }
    }
  };

  handleScroll = () => {
    const el = this.el.current;
    if (!el) {
      return;
    }
    const aboveBottom =
      window.innerHeight + window.scrollY - 300 > el.offsetTop;
    const belowTop = true;
    const inView = aboveBottom && belowTop;
    if (inView) {
      this.animation.play();
      window.removeEventListener('scroll', this.handleScroll);
    }
  };

  handleLoop = () => {
    const direction = this.state.direction * -1;
    this.animation.setDirection(direction);
    this.animation.play();
    this.setState({ direction });
  };

  render() {
    return <LottieDiv ref={this.el} className={this.props.className} />;
  }
}

export const LottieDiv = styled.div`
  /*  */
`;

export default withTheme(Lottie);
