export const LOAD_ASSET_MANAGEMENT_TRANSACTIONS =
  'LOAD_ASSET_MANAGEMENT_TRANSACTIONS';
export const SET_ASSET_MANAGEMENT_TRANSACTIONS =
  'SET_ASSET_MANAGEMENT_TRANSACTIONS';

export const setAssetManagementTransactions = payload => ({
  type: SET_ASSET_MANAGEMENT_TRANSACTIONS,
  payload
});
export const loadAssetManagementTransactions = () => ({
  type: LOAD_ASSET_MANAGEMENT_TRANSACTIONS
});

export default (state = [], action) => {
  switch (action.type) {
    case SET_ASSET_MANAGEMENT_TRANSACTIONS:
      return action.payload.transactions;
    default:
      return state;
  }
};
