//@flow
import { createSelector } from 'reselect';

const getSupportedLanguages = state => state.global.language.supportedLanguages;

const getActiveLanguages = state => state.global.language.activeLanguage;

const filterLanguages = (activeLanguage, supportedLanguages) => {
  const filteredLanguages = supportedLanguages.filter(
    language => language.locale !== activeLanguage.locale
  );
  return sortFilteredLanguages(filteredLanguages);
};

const sortFilteredLanguages = filteredLanguages =>
  filteredLanguages.sort((a, b) => a.language - b.language);

export const filteredLanguages: Function = createSelector(
  getActiveLanguages,
  getSupportedLanguages,
  filterLanguages
);
