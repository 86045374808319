import styled from 'styled-components/macro';
import {
  maxContentSize,
  paddingDefault,
  paddingDesktop
} from '../../styles/utils';
import { PrimaryButton } from '../../components/ButtonStyles';
import PrimaryTabBar from '../../components/Tabbar/PrimaryTabbar';
import { ToggleWrapperStyles } from '../AppStyles';

export const MarketingDiv = styled.div`
  padding: 3rem 3rem 6rem;
  position: relative;
  > * {
    max-width: ${maxContentSize}px;
  }
  @media screen and (min-width: 768px) {
    padding: 1rem 6rem 7rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 1rem ${paddingDefault} 7rem;
  }
  @media screen and (min-width: 1440px) {
    padding: 1rem ${paddingDesktop} 7rem;
  }
`;

export const MarketingCollateralDiv = styled.div`
  h4 {
    margin-bottom: 12px;
  }
`;

export const DownloadImgLinkA = styled.a`
  &:hover {
    cursor: pointer;
  }
  img {
    width: 318px;
    margin-bottom: 35px;
    margin-top: 30px;
  }
  @media screen and (min-width: 768px) {
    img {
      width: 445px;
    }
  }
`;

export const DownloadCryptoBadgeButton = styled(PrimaryButton)`
  width: 308px;
  font-weight: 600;
  padding: 30px;
`;

export const CollateralItemDiv = styled.div`
  margin-top: 80px;
  h4 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
  }
  a {
    cursor: pointer;
    ${props => props.theme.fontStackHeader};
    font-weight: 500;
    color: ${props => props.theme.blue};
    text-decoration: underline;
    letter-spacing: 0.04em;
  }
`;

export const BorderDiv = styled.div`
  border-right: 1px solid ${props => props.theme.dustyGray};
  margin: 0px 15px;
`;

export const DownloadLinkA = styled.a`
  padding: 1rem;
  color: black;
  ${props => props.theme.fontStackHeader};
  height: 100%;
  &:hover {
    color: ${props => props.theme.blue};
  }
`;

export const DownloadLinkDesktopA = styled.a`
  color: black;
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
`;

export const SalesMaterialItemDiv = styled.div`
  margin-bottom: 5px;
  svg {
    fill: ${props => props.theme.blue};
    min-width: 10px;
    width: 10px;
    height: 10px;
    margin-right: 16px;
  }
  @media screen and (min-width: 1024px) {
    padding: 1rem;
    margin-bottom: 5px;
    span,
    ${BorderDiv} {
      display: none;
    }
    &:hover {
      span,
      ${BorderDiv} {
        display: inline-block;
      }
    }
  }
`;

export const FileNameLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  ${props => props.theme.fontStackHeader};
  cursor: pointer;
`;

export const SalesMaterialItemHeader = styled.div`
  display: flex;
  &:hover {
    ${FileNameLabel} {
      color: ${props => props.theme.blue};
    }
  }
`;

export const SalesMaterialDescription = styled.div`
  padding-left: calc(26px + 1rem);
  @media screen and (min-width: 1024px) {
    padding-left: 27px;
    margin-top: 5px;
  }
  p {
    max-width: 895px;
  }
`;

export const StyledPrimaryTabBar = styled(PrimaryTabBar)`
  width: 100%;
`;

export const ToggleWrapper = styled.div`
  ${ToggleWrapperStyles}
  max-width: 1520px;

`;
