//@flow
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SidebarOpened from './SidebarOpened.jsx';
import SidebarClosed from './SidebarClosed.jsx';
import {
  SidebarContainerDiv,
  ArrowLeftDiv,
  SidebarWrapperDiv,
  LogoImg,
  LogoWrapper,
  ClosedLogoImg,
  ArrowIcon
} from './SidebarStyles';
import { toggleSidebar, showTooltip, hideTooltip } from '../../../App/reducer';
import netcents from '../../../assets/images/logo-netcents.png';
import nc from '../../../assets/images/nc.png';
import { type Permissions, type User } from '../../../utilities/types';

export type TooltipProps = {
  tooltip: {
    title: string,
    top: number,
    left: number
  },
  showTooltip: (payload: {
    title: string,
    top: number,
    left: number
  }) => void,
  hideTooltip: () => void
};
type Props = {
  ...$Exact<TooltipProps>,
  sidebarToggle: boolean,
  toggleSidebar: () => void,
  permissions: Permissions,
  user: User,
  brandLogo: ?string,
  platformLogo: string,
  platformLogoSmall: string,
  userType: string
};
class Sidebar extends Component<Props> {
  handleSideBarToggle = () => {
    this.props.toggleSidebar();
  };

  render() {
    if (!this.props.user) {
      return null;
    }
    return (
      <SidebarContainerDiv>
        <LogoWrapper>
          <Link to="/">
            {this.props.sidebarToggle ? (
              <LogoImg src={this.props.platformLogo || netcents} alt="logo" />
            ) : (
              <ClosedLogoImg
                src={this.props.platformLogoSmall || nc}
                alt="logo"
              />
            )}
          </Link>
        </LogoWrapper>
        <SidebarWrapperDiv opened={this.props.sidebarToggle}>
          {this.props.sidebarToggle ? (
            <SidebarOpened
              brandLogo={this.props.brandLogo}
              permissions={this.props.permissions}
              user={this.props.user}
              userType={this.props.userType}
            />
          ) : (
            <SidebarClosed
              permissions={this.props.permissions}
              user={this.props.user}
              tooltip={this.props.tooltip}
              showTooltip={this.props.showTooltip}
              hideTooltip={this.props.hideTooltip}
              sidebarToggle={this.props.sidebarToggle}
              userType={this.props.userType}
            />
          )}
        </SidebarWrapperDiv>
        <ArrowLeftDiv sidebarToggle={this.props.sidebarToggle}>
          <ArrowIcon
            onClick={this.handleSideBarToggle}
            name={this.props.sidebarToggle ? 'arrowLeftO' : 'arrowRightO'}
          />
        </ArrowLeftDiv>
      </SidebarContainerDiv>
    );
  }
}

const mapStateToProps = state => ({
  sidebarToggle: state.global.sidebarToggle,
  permissions: state.global.permissions,
  user: state.global.user,
  brandLogo: state.global.brandLogo,
  loading: state.global.loading,
  platformLogo: state.global.platformLogo,
  platformLogoSmall: state.global.platformLogoSmall,
  tooltip: state.global.tooltip,
  userType: state.global.userType
});

const mapDispatchToProps = {
  toggleSidebar,
  showTooltip,
  hideTooltip
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
