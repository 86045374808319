import { takeLatest, put, select } from 'redux-saga/effects';
import i18n from '../../i18n';
import { getRequest, handleSagaError } from '../../utilities/requests';
import { saveAs } from 'file-saver';
import {
  LOAD_BILLING_INVOICES,
  SET_PAGE,
  setBillingInvoices,
  failedBillingInvoicesLoad,
  DOWNLOAD_INVOICE
} from './reducer';
import { i18nMoment } from '../../utilities/dates';

function* handleLoadBillingInvoices() {
  try {
    const url = '/merchant_billing';
    const response = yield getRequest(url);
    yield put(setBillingInvoices(response.data));
  } catch (error) {
    yield put(failedBillingInvoicesLoad());
  }
}

export function* loadBillingInvoicesSaga() {
  yield takeLatest(
    [LOAD_BILLING_INVOICES, SET_PAGE],
    handleLoadBillingInvoices
  );
}

const downloadUrl = invoice => {
  const { invoice_type, id } = invoice;
  switch (invoice_type) {
    case 'asset management':
      return `/asset_management_service_invoices/${id}/download`;
    case 'monthly charge':
      return `/monthly_charges/${id}/download`;
    default:
      return '';
  }
};

function* handleDownloadInvoice(action) {
  try {
    const url = downloadUrl(action.invoice);
    const response = yield getRequest(url, {}, { responseType: 'blob' });
    const companyName = yield select(state => state.global.companyName);
    const date = i18nMoment({
      data: action.invoice.created_at,
      type: 'format',
      additionalData: 'L'
    });
    const fileName = `${companyName.replace(' ', '-')}-invoice-${date}-${
      action.invoice.invoice_number
    }`;
    saveAs(response.data, fileName);
  } catch (error) {
    yield handleSagaError(error, i18n.t('account:errorMessage.downloadError'));
  }
}

export function* downloadInvoiceSaga() {
  yield takeLatest(DOWNLOAD_INVOICE, handleDownloadInvoice);
}
