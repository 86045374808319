// @flow
import { type Options } from '../components/DropdownInput';
import { type Permissions } from '../utilities/types';

//Unused
export function titleize(string: string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
}

export function calculateTableColumns(
  windowSize: ?string,
  initialColumn: number
) {
  let columns;
  if (windowSize === 'mobile') {
    columns = initialColumn;
  } else if (windowSize === 'ipad') {
    columns = initialColumn + 1;
  } else {
    columns = initialColumn + 2;
  }
  return columns;
}

export const arrayToOptions = (
  array: Array<any>,
  textKey: string,
  valueKey: string,
  options: { allowBlank?: boolean, blankLabel?: string } = {
    allowBlank: true,
    blankLabel: ''
  }
) => {
  if (!Array.isArray(array)) {
    return [];
  }
  const result: Array<Options> = array.map(group => ({
    text: group[textKey],
    value: group[valueKey]
  }));
  if (options.allowBlank || options.blankLabel) {
    result.unshift({ text: options.blankLabel || '', value: '' });
  }
  return result;
};

export const hasSettingsAccess = (permissions: Permissions) =>
  permissions.payout_plan.read || permissions.currency_permission.read;
