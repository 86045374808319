import axios from 'axios';
import i18n from '../../i18n';
import { SubmissionError } from 'redux-form';
import jwtDecode from 'jwt-decode';
import { setUser, setInitialState } from '../reducer';
import { setToken } from '../../utilities/tokenStorage';
import { VERIFY_TWO_FACTOR_AUTH_MODAL } from '../../modules/ModalConductor/modalTypes';
import { showModal } from '../../modules/ModalConductor/reducer';

const handleSubmit = async (values, dispatch) => {
  const url = '/sessions';
  try {
    const response = await axios.post(url, values);
    handleResponse(response, dispatch);
    return response;
  } catch (error) {
    let message = i18n.t('global:errorMessage.default');
    if (error.response) {
      message =
        error.response.data.error || error.response.data.details || message;
    }
    throw new SubmissionError({ _error: message });
  }
};

function handleResponse(response, dispatch) {
  try {
    const token = response.data.jwt;
    const userDetails = jwtDecode(token);
    window.pendo.initialize({
      visitor: {
          id: userDetails.id,
          email: userDetails.email,
          full_name: `${userDetails.first_name} ${userDetails.last_name}`
      },
      account: {
        id: userDetails.merchant_application_id,
        name: userDetails.business_name
      }
    });

    if (userDetails.two_factor_method) {
      dispatch(
        showModal(VERIFY_TWO_FACTOR_AUTH_MODAL, {
          two_factor_method: userDetails.two_factor_method,
          token
        })
      );
    } else {
      handleSignInSuccess(response, dispatch, token, userDetails);
    }
  } catch (error) {
    //
  }
}

export function handleSignInSuccess(response, dispatch, token, userDetails) {
  setToken(token);
  dispatch(setInitialState(response.data.initial_state));
  dispatch(setUser(userDetails));
}

export default handleSubmit;
