import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const textLinkBase = css`
  ${props => props.theme.fontStackBody};
  text-decoration: underline;
  color: ${props => props.theme.brand};
  transition: color ease 0.25s;
  cursor: pointer;
  svg {
    transition: fill ease 0.25s;
    fill: ${props => props.theme.brand};
  }
  &:hover {
    color: ${props => props.theme.indigo};
    svg {
      fill: ${props => props.theme.indigo};
    }
  }
`;

export const TextLink = styled.a`
  ${textLinkBase}
`;
export const TextReactLink = styled(Link)`
  ${textLinkBase}
  ${props =>
    (props.styled || {}).underline ||
    css`
      text-decoration: none;
    `}
    ${props =>
      props.bold &&
      css`
        font-weight: 700;
      `}
      ${props =>
        props.underline &&
        css`
          text-decoration: underline;
        `}
`;

const greyTextLinkBase = css`
  ${props => props.theme.fontStackBody};
  text-decoration: underline;
  color: ${props => props.theme.dustierGray};
  transition: color ease 0.25s;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;
export const GreyTextLink = styled.a`
  ${greyTextLinkBase}
`;

export const GreyTextReactLink = styled(Link)`
  ${greyTextLinkBase}
`;

const linkBase = css`
  ${props => props.theme.fontStackHeader};
  line-height: 17px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: ${props => (props.bold ? 600 : 400)};
  transition: color ease 0.25s;
  cursor: pointer;
  ${props =>
    (props.styled || {}).underline &&
    css`
      text-decoration: underline;
    `}
  svg {
    margin-left: 3px;
    margin-bottom: 1px;
    height: 7px;
    transition: fill ease 0.25s;
  }
`;

const greyLinkBase = css`
  ${linkBase};
  color: ${props => props.theme.dustierGray};
  svg {
    fill: ${props => props.theme.dustierGray};
  }
  &:hover {
    color: ${props => props.theme.dustyGray};
    svg {
      fill: ${props => props.theme.dustyGray};
    }
  }
`;

export const GreyLink = styled.a`
  ${greyLinkBase}
`;

export const GreyStyledLink = styled(Link)`
  ${greyLinkBase}
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`;

export const brandedLinkBase = css`
  ${linkBase};
  color: ${props => props.theme.brand};
  svg {
    fill: ${props => props.theme.brand};
  }
  &:hover {
    ${props =>
      props.isWhiteLabel &&
      css`
        opacity: 0.8;
      `}
    color: ${props => props.theme.indigo};
    svg {
      fill: ${props => props.theme.indigo};
    }
  }
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`;

export const BrandedLink = styled.a`
  ${brandedLinkBase};
`;

export const BrandedStyledLink = styled(Link)`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${brandedLinkBase};

  &:lang(de) {
    font-weight: 400;
    text-transform: capitalize;
  }
`;

const brandedTextLinkBase = css`
  ${textLinkBase}
  font-size: 1.2rem;
  font-weight: 300;
`;

export const BrandedTextLink = styled(Link)`
  ${brandedTextLinkBase}
  padding: 2px 8px;
`;

export const BrandedTextLinkButton = styled.button`
  ${brandedTextLinkBase}
  padding: 2px 8px;
`;

export const BrandedTextLinkNormalized = styled(Link)`
  ${brandedTextLinkBase}
`;

const actionLinkBase = css`
  ${props => props.theme.fontStackBody};
  font-size: 1.2rem;
  font-weight: 300;
  padding: 2px 4px;
  cursor: pointer;
  color: black;
  @media screen and (max-width: 374px) {
    text-align: center;
  }
`;

export const ActionLink = styled(Link)`
  ${actionLinkBase}
`;

export const ActionLinkButton = styled.button`
  ${actionLinkBase}
`;
