export const SET_VIEW_OPTION = 'hostedPayments/SET_VIEW_OPTION';
export const TOGGLE_SORT = 'hostedPayments/TOGGLE_SORT';
export const HANDLE_SEARCH_CHANGE = 'hostedPayments/HANDLE_SEARCH_CHANGE';
export const TOGGLE_SHOW_BLOCKED = 'hostedPayments/TOGGLE_SHOW_BLOCKED';
export const FOCUS_SEARCH_INPUT = 'hostedPayments/FOCUS_SEARCH_INPUT';
export const BLUR_SEARCH_INPUT = 'hostedPayments/BLUR_SEARCH_INPUT';
export const SET_PER_PAGE = 'hostedPayments/SET_PER_PAGE';
export const EXPAND_HP_PLUGIN_ROW = 'EXPAND_HP_PLUGIN_ROW';
export const CHANGE_PAGE = 'hostedPayments/CHANGE_PAGE';

export const changePage = page => ({
  type: CHANGE_PAGE,
  page
});
export const expandHpPluginRow = id => ({
  type: EXPAND_HP_PLUGIN_ROW,
  id
});
const initialState = {
  viewOption: 'square',
  alphabeticalSortDirection: 'asc',
  searchValue: '',
  showBlocked: false,
  searchFocused: false,
  page: 1,
  perPage: 10,
  expandedRowId: null,
  previouslyVisitedPages: [1]
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEW_OPTION:
      return {
        ...state,
        viewOption: action.viewOption,
        expandedRowId: null
      };
    case TOGGLE_SORT:
      return {
        ...state,
        alphabeticalSortDirection:
          state.alphabeticalSortDirection === 'asc' ? 'desc' : 'asc'
      };
    case HANDLE_SEARCH_CHANGE:
      return {
        ...state,
        searchValue: action.searchValue,
        expandedRowId: null,
        page: 1,
        previouslyVisitedPages: [1]
      };
    case TOGGLE_SHOW_BLOCKED:
      return {
        ...state,
        showBlocked: !state.showBlocked,
        expandedRowId: null,
        page: 1,
        previouslyVisitedPages: [1]
      };
    case FOCUS_SEARCH_INPUT:
      return {
        ...state,
        searchFocused: true,
        expandedRowId: null
      };
    case BLUR_SEARCH_INPUT:
      return {
        ...state,
        searchFocused: false
      };

    case EXPAND_HP_PLUGIN_ROW:
      return {
        ...state,
        expandedRowId: state.expandedRowId === action.id ? null : action.id
      };
    case SET_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
        page: 1,
        previouslyVisitedPages: [1]
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        previouslyVisitedPages: state.previouslyVisitedPages.includes(
          action.page
        )
          ? state.previouslyVisitedPages
          : [...state.previouslyVisitedPages, action.page]
      };
    default:
      return state;
  }
};
