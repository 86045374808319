// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { requiredField } from '../../../utilities/validations';
import {
  TwoFactorModalContentDiv,
  EnableEmailTwoFactorForm,
  StyledInputLarge
} from './TwoFactorAuthModalsStyles';
import { closeModal } from '../reducer';
import {
  EnableTwoFactorModalDiv,
  ConfirmationModalButtons,
  ConfirmationModalA
} from '../ModalConductorStyles';
import {
  updateUserTwoFactorMethod,
  requestEmailAuthCode
  // sendAuthCodeByEmail
} from '../../../App/Company/reducer';
import { ErrorP } from '../../../components/SaveOverlayStyles';
import handleSubmit from './handleSubmit';

type Props = {
  closeModal: () => void,
  handleSubmit: (values: any) => void,
  error: ?string,
  two_factor_method?: string,
  t: TFunction,
  lang: string
};

class VerifyTwoFactorAuthModal extends Component<Props> {
  handleConfirmClick = () => {
    this.props.handleSubmit();
  };
  render() {
    const { t } = this.props;
    const title =
      this.props.two_factor_method === 'totp'
        ? t('verification:twoFactorAuth.google.title')
        : t('verification:twoFactorAuth.email.title');
    const message =
      this.props.two_factor_method === 'totp'
        ? t('verification:twoFactorAuth.google.message')
        : t('verification:twoFactorAuth.email.message');
    return (
      <EnableTwoFactorModalDiv>
        <TwoFactorModalContentDiv>
          <h4>{title}</h4>
          <p>{message}</p>
          <EnableEmailTwoFactorForm onSubmit={this.props.handleSubmit}>
            {/* Consider: Cleaning up input so that user does not enter spaces as shown in Google Authenticator app. */}
            <Field
              name="otp_code"
              hasPlaceholder
              component={StyledInputLarge}
              placeholder="XXXXXX"
              validate={requiredField}
            />
            {this.props.error && <ErrorP>{this.props.error}</ErrorP>}
          </EnableEmailTwoFactorForm>
        </TwoFactorModalContentDiv>
        <ConfirmationModalButtons>
          <ConfirmationModalA
            onClick={this.handleConfirmClick}
            lang={this.props.lang}
          >
            {t('global:actions.login')}
          </ConfirmationModalA>
          <ConfirmationModalA
            onClick={this.props.closeModal}
            lang={this.props.lang}
          >
            {t('global:actions.cancel')}
          </ConfirmationModalA>
        </ConfirmationModalButtons>
      </EnableTwoFactorModalDiv>
    );
  }
}

// const mapStateToProps = state => ({
//   // twoFactorMethod: state.global.user.two_factor_method
// });

const mapDispatchToProps = {
  requestEmailAuthCode,
  updateUserTwoFactorMethod
};

const onSubmitSuccess = (response, dispatch) => {
  dispatch(closeModal());
};

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'VerifyTwoFactorAuthModal',
    onSubmit: handleSubmit,
    onSubmitSuccess
  })(withTranslation(['global', 'verification'])(VerifyTwoFactorAuthModal))
);
