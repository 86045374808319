import {
  LOAD_PENDING_MANUAL_PAYOUTS_ERROR,
  SET_PENDING_MANUAL_PAYOUTS
} from '.';

const initialState = {
  loadPendingPayoutsError: false,
  loading: true,
  pendingPayouts: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PENDING_MANUAL_PAYOUTS:
      return {
        ...state,
        pendingPayouts: action.pendingPayouts,
        loading: false,
        loadPendingPayoutsError: false
      };

    case LOAD_PENDING_MANUAL_PAYOUTS_ERROR:
      return {
        ...state,
        loadPendingPayoutsError: true
      };
    default:
      return state;
  }
};
