// @flow
import React, { Component } from 'react';
import { NavButtonLink, NavIcon } from './NavbarStyles';

type Props = {
  borderRight: ?boolean,
  to: string,
  name: string,
  displayName?: string,
  iconOnly?: boolean
};
type State = {
  hover: boolean
};

export default class NavbarItem extends Component<Props, State> {
  state = { hover: false };

  onNavbarEnter = () => {
    if (!this.state.hover) this.setState({ hover: true });
  };

  onNavbarLeave = () => {
    if (this.state.hover) this.setState({ hover: false });
  };

  render() {
    return (
      <NavButtonLink
        to={this.props.to}
        borderRight={this.props.borderRight}
        onMouseEnter={this.onNavbarEnter}
        onMouseLeave={this.onNavbarLeave}
        hover={this.state.hover}
      >
        <NavIcon name={this.props.name} />
        {this.props.iconOnly || <h5>{this.props.displayName}</h5>}
      </NavButtonLink>
    );
  }
}
