import { takeLatest, put } from 'redux-saga/effects';
import { getRequest } from '../../utilities/requests';
import { LOAD_FEES, setFees, failedFeesLoad } from './reducer';

function* handleLoadFees() {
  const url = '/fees';
  try {
    const response = yield getRequest(url);
    yield put(setFees(response.data.fees));
  } catch {
    yield put(failedFeesLoad());
  }
}

export function* loadFeesSaga() {
  yield takeLatest(LOAD_FEES, handleLoadFees);
}
