import { SET_RECIPIENTS } from '../../Recipients/reducer';

export const TOGGLE_ADD_RECIPIENT = 'TOGGLE_ADD_RECIPIENT';
export const SET_NEW_INVOICE_DATA = 'SET_NEW_INVOICE_DATA';
export const SET_VISIBLE_TOOLTIP_ID = 'SET_VISIBLE_TOOLTIP_ID';
export const LOAD_INVOICE_CURRENCIES = 'LOAD_INVOICE_CURRENCIES';
export const SET_INVOICE_CURRENCIES = 'SET_INVOICE_CURRENCIES';

export const toggleAddRecipient = () => ({
  type: TOGGLE_ADD_RECIPIENT
});

export const setNewInvoiceData = payload => ({
  type: SET_NEW_INVOICE_DATA,
  payload
});

export const setVisibleTooltipId = activeTooltip => ({
  type: SET_VISIBLE_TOOLTIP_ID,
  activeTooltip
});

export const loadInvoiceCurrencies = () => ({ type: LOAD_INVOICE_CURRENCIES });

export const setInvoiceCurrencies = payload => ({
  type: SET_INVOICE_CURRENCIES,
  payload
});

const initialState = {
  addRecipient: false,
  displayRecipientOptions: false,
  activeTooltip: '',
  invoiceCurrencies: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ADD_RECIPIENT:
      return {
        ...state,
        addRecipient: !state.addRecipient
      };
    case SET_RECIPIENTS:
      if (action.recipients.invoice_recipients.length === 0) {
        return {
          ...state,
          addRecipient: true,
          displayRecipientOptions: false
        };
      } else {
        return {
          ...state,
          displayRecipientOptions: true
        };
      }
    case SET_NEW_INVOICE_DATA:
      return {
        ...state,
        recurringInvoiceFrequencies:
          action.payload.recurring_invoice_frequencies
      };
    case SET_VISIBLE_TOOLTIP_ID:
      return {
        ...state,
        activeTooltip: action.activeTooltip
      };
    case SET_INVOICE_CURRENCIES:
      return {
        ...state,
        invoiceCurrencies: [...action.payload.invoice_currencies]
      };
    default:
      return state;
  }
};
