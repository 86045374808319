import { combineReducers } from 'redux';
import { SET_INITIAL_STATE } from '../reducer';

import invoices from './Invoices/reducer';
import recipients from './Recipients/reducer';
import recurring from './Recurring/reducer';

const SET_ACTIVE_TAB = 'invoices/SET_ACTIVE_TAB';

export const setActiveTab = activeTab => ({
  type: SET_ACTIVE_TAB,
  activeTab
});

const communal = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case SET_INITIAL_STATE:
      return {
        ...state,
        recurringInvoiceFrequencies:
          action.payload.invoice.recurring_invoice_frequencies
      };

    default:
      return state;
  }
};

const reducer = combineReducers({
  communal,
  invoices,
  recipients,
  recurring
});

export default reducer;
