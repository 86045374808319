import styled, { css } from 'styled-components';

export const OutlineSelectContainer = styled.div`
  width: 150px;
  height: 54px;
  border: 1px solid ${props => props.theme.indigo};
  :hover {
    border: 2px solid ${props => props.theme.indigo};
  }
  ${props =>
    props.isDropdownActive &&
    css`
      border: 2px solid ${props => props.theme.indigo};
    `}
  position: relative;
  border-radius: 2px;
`;

export const OutlineSelectWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > svg {
    height: 15.6px;
    width: 15.6px;
    fill: ${props => props.theme.indigo};
  }
  > h3 {
    color: ${props => props.theme.indigo};
    text-transform: unset;
    font-weight: 400;
    padding-right: 8px;
  }
`;

export const FilledSelectContainer = styled.div`
  cursor: pointer;
  background-color: ${props => props.theme.brand};
  border-radius: 2px;
  width: 315px;
  height: 47px;
  position: relative;
`;
export const FilledSelectWrapper = styled.div`
  height: 100%;
  padding-left: 28px;
  padding-right: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > h3 {
    color: ${props => props.theme.white};
    padding-right: 8px;
  }
  > svg {
    height: 15.6px;
    width: 15.6px;
    fill: ${props => props.theme.white};
  }
`;

export const OptionsWrapper = styled.div`
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  border-width: 0px;
  border-color: white;
  width: 100%;
  /* TODO: rework transition */
  /* visibility: hidden;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s, top linear 0s; */

  ${props =>
    props.dropdownActive &&
    css`
      background-color: white;
      margin-top: ${props => props.height + 10}px;
      top: 0;
      /* border: 0.13rem solid ${props => props.theme.indigo}; */
      border-color: ${props => props.theme.indigo};
      border-style: solid;
      border-width: 0.13rem;

      /* visibility: visible;
      transform: translateY(0%);
      transition-delay: 0s, 0s, 1.3s; */
    `};
`;

export const OptionUl = styled.ul`
  padding: unset;
  margin: unset;
`;

export const OptionLi = styled.li`
  padding-left: 21px;
  align-items: center;
  display: flex;
  height: 50px;
  width: 100%;
  opacity: 0;
  font-weight: 400;
  /* TODO: */
  /* transition: opacity 0.2s linear; */
  cursor: pointer;
  ${props =>
    props.dropdownActive &&
    css`
      opacity: 1;
    `};
  :hover {
    color: ${props => props.theme.indigo};
  }
  ${props =>
    !props.last &&
    css`
      border-bottom: 1px solid ${props => props.theme.mercury};
    `}
`;
