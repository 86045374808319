import { takeEvery, put } from 'redux-saga/effects';
import i18n from '../../i18n';
import {
  BLOCK_DEVICE,
  UNBLOCK_DEVICE,
  updateDevice,
  DELETE_DEVICE,
  setDeviceDeleted
} from './reducer';
import {
  patchRequest,
  deleteRequest,
  handleSagaError
} from '../../utilities/requests';
import { showSuccessModal } from '../../modules/ModalConductor/reducer';

// const getDevices = () => {
//   const url = '/devices';
//   return getRequest(url);
// };

// function* handleLoadDevices() {
//   const response = yield getDevices();
// }

// export function* loadDevices() {
//   yield takeEvery(LOAD_DEVICES, handleLoadDevices);
// }

function* handleUpdateDevice(id, payload, successModal) {
  try {
    const url = `/device/users/${id}`;
    const response = yield patchRequest(url, payload);
    yield put(updateDevice(response.data.device));
    yield put(showSuccessModal(successModal.title, successModal.message));
  } catch (error) {
    yield handleSagaError(error);
  }
}

function* handleBlockDevice(action) {
  const modalPayload = {
    title: i18n.t('posTerminals:modal.blockNotice.title'),
    message: i18n.t('posTerminals:modal.blockNotice.message')
  };
  yield handleUpdateDevice(action.id, { blocked: true }, modalPayload);
}

export function* blockDevice() {
  yield takeEvery(BLOCK_DEVICE, handleBlockDevice);
}

function* handleUnblockDevice(action) {
  const modalPayload = {
    title: i18n.t('posTerminals:modal.unblockNotice.title'),
    message: i18n.t('posTerminals:modal.unblockNotice.message')
  };
  yield handleUpdateDevice(action.id, { blocked: false }, modalPayload);
}

export function* unblockDevice() {
  yield takeEvery(UNBLOCK_DEVICE, handleUnblockDevice);
}

function* handleDeleteDevice(action) {
  try {
    const url = `/device/users/${action.id}`;
    yield deleteRequest(url);
    yield put(setDeviceDeleted(action.id));
    yield put(
      showSuccessModal(
        i18n.t('posTerminals:modal.deleteNotice.title'),
        i18n.t('posTerminals:modal.deleteNotice.message')
      )
    );
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* deleteDevice() {
  yield takeEvery(DELETE_DEVICE, handleDeleteDevice);
}
