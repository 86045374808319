//@flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavItemWrapper, NavItemContainer } from './SidebarItemStyles';
import { navIcon } from '../../../utilities/icons';
import { type TooltipProps } from '.';
import { type ReactObjRef } from '../../../utilities/types';

type OpenProps = {
  name: string,
  active: boolean,
  formattedNavItem: string,
  opened: true,
  iconName: string
};

type ClosedProps = {
  ...$Exact<TooltipProps>,
  name: string,
  active: boolean,
  formattedNavItem: string,
  opened?: false | void,
  userType: string,
  iconName: string
};
type Props = OpenProps | ClosedProps;

class SidebarItem extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.sideBarItemRef = React.createRef();
  }
  sideBarItemRef: ReactObjRef<'div'>;

  onSidebarItemEnter = () => {
    if (!this.props.opened) {
      const el = this.sideBarItemRef.current;
      if (el) {
        const top = el.offsetTop;
        const left = el.offsetLeft;
        this.props.showTooltip({
          title: this.props.name,
          top,
          left
        });
      }
    }
  };

  render() {
    return (
      <Link
        to={this.props.formattedNavItem}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <NavItemContainer
          opened={this.props.opened}
          active={this.props.active}
          ref={this.sideBarItemRef}
          onMouseEnter={
            this.props.userType === 'desktop'
              ? this.onSidebarItemEnter
              : undefined
          }
        >
          <NavItemWrapper active={this.props.active} opened={this.props.opened}>
            {navIcon(this.props.iconName.toLowerCase(), this.props.active)}
            {this.props.opened && <h5>{this.props.name}</h5>}
          </NavItemWrapper>
        </NavItemContainer>
      </Link>
    );
  }
}

export default SidebarItem;
