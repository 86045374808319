import { debounce, put, select, takeLatest } from '@redux-saga/core/effects';
import i18n from '../../../../i18n';
import { showSuccessModal } from '../../../../modules/ModalConductor/reducer';
import { history } from '../../../../utilities/history';
import {
  getRequest,
  postRequest,
  handleSagaError
} from '../../../../utilities/requests';
import { updateInvoice } from '../../Invoices/Index/reducer/invoices';
import {
  CHANGE_PAGE,
  CLEAR_CALENDAR_FILTER,
  SET_CALENDAR_SAGA,
  SET_FREQUENCY_FILTER,
  SET_PER_PAGE_FILTER,
  SET_SEARCH_FILTER
} from './reducer/filters';
import {
  CANCEL_RECURRING_BILLING,
  LOAD_RECURRING_INVOICES,
  setRecurringInvoices
} from './reducer/view';

function* invoicesFilters() {
  const limit = yield select(
    state => state.invoices.recurring.index.filters.perPage
  );
  const offset = yield select(
    state => state.invoices.recurring.index.filters.page
  );
  const frequency = yield select(
    state => state.invoices.recurring.index.filters.frequency
  );
  const search = yield select(
    state => state.invoices.recurring.index.filters.search
  );
  const startDate = yield select(
    state => state.invoices.recurring.index.filters.startDate
  );
  const endDate = yield select(
    state => state.invoices.recurring.index.filters.endDate
  );
  const config = {
    limit,
    offset,
    search,
    frequency
  };
  if (startDate && endDate) {
    config.recurring_start_date = startDate;
    config.recurring_due_date = endDate;
  }
  // if (process.env.NODE_ENV !== 'production') {
  //   // TEMPORARY
  //   config.sandbox_mode = 'true';
  // }
  return config;
}

function* handleLoadRecurringInvoices() {
  try {
    const url = '/recurring_invoice_cycles';
    const params = yield invoicesFilters();
    const response = yield getRequest(url, params);
    yield put(setRecurringInvoices(response.data));
  } catch (error) {
    console.log({ error });
    history.push('/error');
  }
}

export function* recurringInvoicesSaga() {
  yield takeLatest(
    [
      LOAD_RECURRING_INVOICES,
      CHANGE_PAGE,
      SET_FREQUENCY_FILTER,
      SET_CALENDAR_SAGA,
      CLEAR_CALENDAR_FILTER,
      SET_PER_PAGE_FILTER
    ],
    handleLoadRecurringInvoices
  );
}

export function* recurringInvoicesSearchSaga() {
  yield debounce(200, SET_SEARCH_FILTER, handleLoadRecurringInvoices);
}

function* handleCancelRecurringBillingSaga(action) {
  /**
   * @description
   * Cancel Recurring is called from invoice show and from recurring billing
   */
  try {
    const url = `/recurring_invoice_cycles/${action.id}/cancel`;
    const response = yield postRequest(url);
    yield put(updateInvoice(response.data));
    // TODO: updateRecurring(response.data) ?
    yield put(
      showSuccessModal(
        i18n.t('invoices:modal.cancelRecurringBillingNotice.title'),
        i18n.t('invoices:modal.cancelRecurringBillingNotice.message')
      )
    );
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* cancelRecurringBillingSaga() {
  yield takeLatest(CANCEL_RECURRING_BILLING, handleCancelRecurringBillingSaga);
}
