// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import Lottie from '../../../components/Lottie';
import { defaultSubmitHandler } from '../../../utilities/requests';
import {
  ConditionallyApprovedModalForm,
  ModalTitle,
  StyledCheckboxInput,
  StyledLottieDiv,
  StyledSubmitButton,
  StyledTextLink
} from './ConditionallyApprovedModalStyles';
import { acknowledgeConditionalApproval } from '../../../utilities/validations';
import {
  setAcknowledgeConditionalApproval,
  refreshSession
} from '../../../App/reducer';
import { connect } from 'react-redux';
import { history } from '../../../utilities/history';
export type SuccessModalPayload = {};
type Props = {
  ...$Exact<SuccessModalPayload>,
  handleSubmit: () => void,
  error: string,
  submitting: boolean,
  t: TFunction
};

class ConditionallyApprovedModal extends Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <ConditionallyApprovedModalForm onSubmit={this.props.handleSubmit}>
        <ModalTitle>{t('conditionallyApprovedModal.title')}</ModalTitle>
        <p>
          {t('conditionallyApprovedModal.conditionallyApproved')}{' '}
          <StyledTextLink href="mailto:merchantsupport@net-cents.com">
            merchantsupport@net-cents.com.
          </StyledTextLink>
        </p>

        <StyledLottieDiv>
          <Lottie name={'exclamation'} playOnScroll loop />
        </StyledLottieDiv>

        <Field
          component={StyledCheckboxInput}
          validate={acknowledgeConditionalApproval}
          name="acknowledge_conditional_approval"
          label={t('conditionallyApprovedModal.label')}
        />
        <StyledSubmitButton
          error={this.props.error}
          value="OK"
          disabled={this.props.submitting}
        />
      </ConditionallyApprovedModalForm>
    );
  }
}

const onSubmitSuccess = (response, dispatch, props) => {
  props.setAcknowledgeConditionalApproval();
  props.refreshSession();
  history.go(0);
  props.closeModal();
};

const mapDispatchToProps = {
  setAcknowledgeConditionalApproval,
  refreshSession
};

export default connect(
  null,
  mapDispatchToProps
)(
  withTranslation('verification')(
    reduxForm({
      form: 'ConditionallyApprovedModal',
      onSubmit: defaultSubmitHandler(
        '/merchant_document_collection/acknowledge_conditional_approval'
      ),
      onSubmitSuccess
    })(ConditionallyApprovedModal)
  )
);
