import i18n from '../i18n';

export const last12Months = (short = false) => {
  const theMonths = i18nMoment({ type: 'months' });
  const shortMonths = i18nMoment({ type: 'monthsShort' });
  const today = new Date();
  let aMonth = today.getMonth() + 1;
  let i;
  const months = short ? shortMonths : theMonths;
  const list = [];
  for (i = 0; i < 12; i++) {
    if (aMonth > 11) {
      aMonth = 0;
    }
    list.push(months[aMonth]);
    aMonth++;
  }
  return list;
};

export const i18nMoment = ({ data, additionalData = {}, type } = {}) =>
  i18n.t('global:date', {
    value: {
      date: { data, additionalData },
      type
    }
  });
