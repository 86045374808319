import styled from 'styled-components/macro';

export const PasswordValidationWrapper = styled.div`
  top: 100%;
`;

export const PasswordBackground = styled.div`
  height: 3px;
  width: 70px;
  display: inline-block;
  margin-right: 3px;
  margin-top: 10px;
  background-color: ${props => props.theme[props.color]};
`;

export const PasswordText = styled.small`
  color: ${props => props.theme.dustyGray};
  vertical-align: middle;
  padding-top: 4px;
  display: inline-block;
`;
