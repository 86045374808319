// @flow

// import i18n from '../i18n';
import { type Permissions, type User } from './types';
import { TFunction } from 'react-i18next';

export const routes = (t: TFunction) => [
  { key: 'Dashboard', value: t('routeNames.dashboard'), iconName: 'Dashboard' },
  {
    key: 'Hosted Payments',
    value: t('routeNames.hostedPayments'),
    iconName: 'Hosted Payments'
  },
  {
    key: 'Ecommerce Plugins',
    value: t('routeNames.ecommercePlugins'),
    iconName: 'Ecommerce Plugins'
  },
  {
    key: 'POS Terminals',
    value: t('routeNames.posTerminals'),
    iconName: 'POS Terminals'
  },
  { key: 'Invoices', value: t('routeNames.invoices'), iconName: 'Invoices' },
  {
    key: 'Transactions',
    value: t('routeNames.transactions'),
    iconName: 'Transactions'
  },
  { key: 'Payouts', value: t('routeNames.payouts'), iconName: 'Payouts' },
  {
    key: 'Asset Management',
    value: t('routeNames.assetManagement'),
    iconName: 'Asset Management'
  }
];

export const formatRoute = (route: string) => {
  if (route === 'Dashboard') {
    return '/';
  }
  return `/${route.replace(/\s/g, '-').toLowerCase()}`;
};

export const hasReadPermission = (
  route: string,
  permissions: Permissions,
  user: User
) => {
  switch (route) {
    case 'Dashboard':
      return (
        permissions.transaction.read || permissions.sandbox_transaction.read
      );
    case 'Hosted Payments':
      return permissions.hosted_payments_plugin.read;
    case 'Ecommerce Plugins':
      return permissions.ecommerce_plugin.read;
    case 'POS Terminals':
      return permissions.device_user.read;
    case 'Invoices':
      return permissions.invoice.create;
    case 'Transactions':
      return (
        permissions.transaction.read || permissions.sandbox_transaction.read
      );
    case 'Payouts':
      return permissions.payout.read;
    case 'Asset Management':
      return (
        user.asset_management_enabled && permissions.asset_transaction.read
      );
    default:
      break;
  }
};

export const totalEnabledRoutes = (
  permissions: Permissions,
  user: User,
  t: TFunction
) =>
  routes(t).reduce(
    (acc, val) =>
      hasReadPermission(val.key, permissions, user) ? acc + 1 : acc,
    0
  );
