import { combineReducers } from 'redux';
import filters from './filters';
import invoices from './invoices';
import view from './view';

const reducer = combineReducers({
  invoices,
  filters,
  view
});

export default reducer;
