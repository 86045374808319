import { takeLatest, put, call, all } from 'redux-saga/effects';
import i18n from '../../i18n';
import {
  getRequest,
  postRequest,
  handleSagaError
} from '../../utilities/requests';
import {
  setCompanyDetails,
  LOAD_COMPANY_DETAILS,
  setCompanyContact,
  LOAD_COMPANY_CONTACT,
  SELECT_TWO_FACTOR_METHOD,
  REQUEST_EMAIL_AUTH_CODE,
  setSubmittingState,
  LOAD_USER_PROMO,
  setUserPromo
} from './reducer';
import { history } from '../../utilities/history';
import {
  ENABLE_EMAIL_TWO_FACTOR_AUTH_MODAL,
  ENABLE_GOOGLE_AUTH_MODAL
} from '../../modules/ModalConductor/modalTypes';
import { showModal, closeModal } from '../../modules/ModalConductor/reducer';

function getCompanyDetails() {
  const url = '/company_details';
  return getRequest(url);
}

function getMailing() {
  const url = '/mailing_addresses';
  return getRequest(url);
}

function getContacts() {
  const url = '/company_contacts';
  return getRequest(url);
}

function getBank() {
  const url = '/depository_banks';
  return getRequest(url);
}

function* handleLoadProfile() {
  try {
    const [
      companyDetails,
      mailingAddress,
      companyContacts,
      bankDetails
    ] = yield all([
      call(getCompanyDetails),
      call(getMailing),
      call(getContacts),
      call(getBank)
    ]);
    yield put(
      setCompanyDetails(
        companyDetails.data.company_detail,
        mailingAddress.data.mailing_address,
        companyContacts.data.company_contact,
        bankDetails.data.depository_bank,
        bankDetails.data.bank_number_identification_methods
      )
    );
  } catch (error) {
    history.push('/error');
  }
}

export function* loadProfileDetails() {
  yield takeLatest(LOAD_COMPANY_DETAILS, handleLoadProfile);
}

function* handleLoadCompanyContact() {
  try {
    const response = yield getContacts();
    yield put(setCompanyContact(response.data.company_contact));
  } catch (error) {
    //
  }
}

export function* loadCompanyContactSaga() {
  yield takeLatest(LOAD_COMPANY_CONTACT, handleLoadCompanyContact);
}

function* handleSelectTwoFactorMethod(action) {
  const payload = {
    two_factor_method: action.authType
  };
  const url = '/two_factor_authentication';
  try {
    const response = yield postRequest(url, payload);
    yield put(setSubmittingState(false));
    yield put(closeModal());
    if (action.authType === 'email') {
      yield put(showModal(ENABLE_EMAIL_TWO_FACTOR_AUTH_MODAL));
    } else {
      yield put(
        showModal(ENABLE_GOOGLE_AUTH_MODAL, {
          qrUri: response.data.qr_uri,
          backupCode: response.data.backup_code
        })
      );
    }
  } catch (error) {
    yield handleSagaError(error, i18n.t('account:errorMessage.request'));
  }
}

export function* selectTwoFactorMethodSaga() {
  yield takeLatest(SELECT_TWO_FACTOR_METHOD, handleSelectTwoFactorMethod);
}

function* getEmailAuthCode() {
  try {
    const url = '/two_factor_authentication/email_auth_code';
    yield getRequest(url);
  } catch (error) {
    yield handleSagaError(error, i18n.t('account:errorMessage.email'));
  }
}

export function* requestAuthCodeSaga() {
  yield takeLatest(REQUEST_EMAIL_AUTH_CODE, getEmailAuthCode);
}

function* handleLoadUserPromo() {
  try {
    const url = '/user_promos/valid';
    const response = yield getRequest(url);
    yield put(setUserPromo(response.data));
  } catch (error) {
    if (error.response && error.response.status === 404) {
      yield put(setUserPromo(null));
    } else {
      history.push('/error');
    }
  }
}

export function* loadUserPromoSaga() {
  yield takeLatest(LOAD_USER_PROMO, handleLoadUserPromo);
}
