// @flow
import * as React from 'react';
import { Default, Mobile } from '../../components/Responsive';
import MobileHeader from './MobileHeader';
import { BackgroundDiv, SimpleLayoutDiv, LogoLink } from './LayoutStyles';
import { LogoImg } from './Sidebar/SidebarStyles';
import SimpleFooter from './SimpleFooter';

type Props = {
  children?: React.Node,
  logo: string,
  acceptRefundsPage?: boolean,
  external: boolean
};

export default class SimpleLayout extends React.Component<Props> {
  render() {
    return (
      <BackgroundDiv>
        <Mobile>
          <MobileHeader simple external={this.props.external} />
        </Mobile>
        <Default>
          <LogoLink to="/" disabled={this.props.external}>
            <LogoImg src={this.props.logo} alt="logo" />
          </LogoLink>
        </Default>
        <SimpleLayoutDiv acceptRefundsPage={this.props.acceptRefundsPage}>
          {this.props.children}
        </SimpleLayoutDiv>
        <SimpleFooter />
      </BackgroundDiv>
    );
  }
}
