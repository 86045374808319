import i18n from '../../i18n';
export const SET_REFUND_OPTION = 'SET_REFUND_OPTION';
export const LOAD_REFUND_DATA = 'LOAD_REFUND_DATA';
export const LOAD_MANAGE_REFUND_DATA = 'LOAD_MANAGE_REFUND_DATA';
export const LOAD_REFUNDS_FAILURE = 'LOAD_REFUNDS_FAILURE';
export const SET_NEW_REFUND_DATA = 'SET_NEW_REFUND_DATA';
export const SET_MANAGE_REFUND_DATA = 'SET_MANAGE_REFUND_DATA';
export const SET_SELECTED_REFUND = 'SET_SELECTED_REFUND';
export const SET_NORMALIZE_STATE = 'SET_NORMALIZE_STATE';
export const SET_REFUND_CANCELLED = 'SET_REFUND_CANCELLED';
// export const CLOSE_TOOLTIP = 'CLOSE_TOOLTIP';
// export const HANDLE_TOOLTIP = 'HANDLE_TOOLTIP';

export const setRefundOption = refundOption => ({
  type: SET_REFUND_OPTION,
  refundOption
});

export const loadRefundData = id => ({
  type: LOAD_REFUND_DATA,
  id
});

export const loadManageRefundData = id => ({
  type: LOAD_MANAGE_REFUND_DATA,
  id
});

export const setNewRefundData = payload => ({
  type: SET_NEW_REFUND_DATA,
  payload
});

export const loadRefundsFailure = () => ({
  type: LOAD_REFUNDS_FAILURE
});

export const setManageRefundData = data => ({
  type: SET_MANAGE_REFUND_DATA,
  data
});

export const setSelectedRefund = id => ({
  type: SET_SELECTED_REFUND,
  id
});

export const normalizeState = () => ({
  type: SET_NORMALIZE_STATE
});

export const setRefundCancelled = id => ({
  type: SET_REFUND_CANCELLED,
  id
});

// export const closeTooltip = () => ({
//   type: CLOSE_TOOLTIP
// });

// export const handleTooltip = () => ({
//   type: HANDLE_TOOLTIP
// });

const initialState = {
  newRefundData: [],
  manageRefundData: { refundHistory: [] },
  loading: true,
  refundOption: '',
  selectedRefund: ''
  // tooltipActive: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_REFUND_DATA:
      return {
        ...state,
        newRefundData: action.payload,
        loading: false
      };
    case SET_MANAGE_REFUND_DATA:
      return {
        ...state,
        manageRefundData: {
          refundHistory: action.data.refund_history,
          iso4217: action.data.iso4217,
          settlementMethod: action.data.settlement_method
        },
        loading: false
      };
    case SET_REFUND_CANCELLED:
      return {
        ...state,
        manageRefundData: {
          ...state.manageRefundData,
          refundHistory: state.manageRefundData.refundHistory.map(x => {
            if (x.id === action.id) {
              return {
                ...x,
                status: i18n.t('global:actions.cancelled')
              };
            } else {
              return x;
            }
          })
        },
        loading: false
      };
    case SET_SELECTED_REFUND:
      return {
        ...state,
        selectedRefund: action.id
      };
    case SET_REFUND_OPTION:
      return {
        ...state,
        refundOption:
          action.refundOption === state.refundOption
            ? state.refundOption
            : action.refundOption
      };
    case LOAD_REFUNDS_FAILURE:
      return {
        ...state,
        error: i18n.t('refunds:errorMessage.refundLoadDataFailure')
      };
    case SET_NORMALIZE_STATE:
      return {
        ...state,
        loading: true,
        refundOption: '',
        selectedRefund: ''
      };

    // case HANDLE_TOOLTIP:
    //   return {
    //     ...state,
    //     tooltipActive: !state.tooltipActive
    //   };
    // case CLOSE_TOOLTIP:
    //   return {
    //     ...state,
    //     tooltipActive: false
    //   };
    default:
      return state;
  }
};
