// @flow
import React, { Component } from 'react';
import i18n from '../../i18n.js';
import { withTranslation, TFunction } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import SubmitButton from '../../components/SubmitButton';
import Input from '../../components/Input';
import { setSignInMessage } from '../SignIn/reducer';
import { history } from '../../utilities/history';
import { defaultSubmitHandler } from '../../utilities/requests';
import { requiredField } from '../../utilities/validations';
import { GreyTextReactLink } from '../../components/LinkStyles';

type Props = {
  handleSubmit: (values: any) => void,
  error: ?string,
  submitting: boolean,
  t: TFunction
};

class RequestReset extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <h1>{t('unauthenticated:resetPassword.header.title')}</h1>
        <Field
          type="email"
          label={t('unauthenticated:resetPassword.form.fields.email')}
          name="email"
          autocomplete="email"
          validate={requiredField}
          component={Input}
        />

        <SubmitButton
          value={t('global:actions.submit')}
          error={this.props.error}
          disabled={this.props.submitting}
          marginTop="3rem"
        />
        <GreyTextReactLink to="/signin">
          {t('global:actions.cancel')}
        </GreyTextReactLink>
      </form>
    );
  }
}

const onSubmitSuccess = (result, dispatch) => {
  history.push('/signin');
  dispatch(setSignInMessage(i18n.t('unauthenticated:modal.resetConfirmation')));
};

export default reduxForm({
  form: 'requestReset',
  onSubmit: defaultSubmitHandler('/password_resets'),
  onSubmitSuccess
})(withTranslation(['unauthenticated', 'global'])(RequestReset));
