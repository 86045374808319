import {
  takeLatest,
  put,
  select,
  throttle,
  takeEvery,
  debounce
} from 'redux-saga/effects';
import i18n from '../../i18n';
import { saveAs } from 'file-saver';
import {
  LOAD_TRANSACTIONS,
  setTransactions,
  SET_AMOUNT_FILTER,
  SET_SOURCE_FILTER,
  SET_DEVICE_FILTER,
  SET_STATUS_FILTER,
  SET_CALENDAR_SAGA,
  CLEAR_CALENDAR_FILTER,
  SET_SEARCH_FILTER,
  CLEAR_TRANSACTION_FILTERS,
  setPaginatorTotal,
  CHANGE_PAGINATOR_PAGE,
  setPaginatorCurrent,
  LOAD_PAYOUT_TRANSACTIONS,
  REMOVE_PAYOUT_TRANSACTIONS,
  MARK_COMPLETE,
  loadTransactions,
  toggleExpandedTransaction,
  DOWNLOAD_TRANSACTIONS_CSV,
  transactionsFailedLoad,
  REFUND_IN_PROGRESS,
  LOAD_WALLET_BALANCES,
  setWalletBalances,
  MARK_COMPLETE_REFUND
} from './reducer';
import {
  getRequest,
  patchRequest,
  handleSagaError
} from '../../utilities/requests';
import { ROUTE_CHANGED } from '../reducer';
import {
  showSuccessModal,
  showModal
} from '../../modules/ModalConductor/reducer';
import { history } from '../../utilities/history';
import { i18nMoment } from '../../utilities/dates';

const doTransactions = config => {
  const url = '/transactions';
  return getRequest(url, config);
};

const doDownloadTransactionCsv = config => {
  const url = '/transactions.csv';
  return getRequest(url, config, { responseType: 'blob' });
};

// For saga requests, format needs to be in YY/MM/DD to work with backend. Do not localize.
const handleI18nMoment = data =>
  i18nMoment({
    data,
    type: 'format',
    additionalData: 'YY/MM/DD'
  });

function* handleTransactionFilters(action) {
  try {
    const verified = yield select(state => state.global.user.verified);
    if (!verified) {
      return;
    }
    const payoutId = yield select(state => state.transactions.payoutId);
    const source = yield select(state => state.transactions.sourceFilter);
    const device_id = yield select(state => state.transactions.deviceFilter);
    const status = yield select(state => state.transactions.statusFilter);
    const perPage = yield select(state => state.transactions.amountFilter);
    const calendarStart = yield select(
      state => state.transactions.calendarStart
    );
    const calendarEnd = yield select(state => state.transactions.calendarEnd);
    const search = yield select(state => state.transactions.searchFilter);
    const currentPage = yield select(state => state.transactions.currentPage);

    const actions_required_notification = true; //check if transaction is viewed or not
    const config = {
      per_page: perPage,
      page: currentPage,
      actions_required_notification
    };

    if (search) {
      if (search.length === 1) {
        yield put({ type: CLEAR_TRANSACTION_FILTERS });
      }
      config.search = search;
    } else {
      Object.assign(config, {
        source,
        device_id,
        status
      });
      if (calendarEnd) {
        config.start_date = handleI18nMoment(calendarStart);
        config.end_date = handleI18nMoment(calendarEnd);
      }
    }
    const path = window.location.pathname.toLowerCase();
    config.actions_required = path.endsWith('action');
    config.is_sandbox = path.endsWith('sandbox');

    if (payoutId) {
      config.payout_id = payoutId;
    }

    if (action.downloadCsv) {
      yield handleDownloadTransactions(config);
    } else {
      yield handleTransactions(config, perPage, currentPage);
    }
  } catch (error) {
    yield put(transactionsFailedLoad());
  }
}

function* handleDownloadTransactions(config) {
  try {
    const response = yield doDownloadTransactionCsv(config);
    const time = i18nMoment({
      type: 'format',
      additionalData: 'L'
    });
    saveAs(response.data, `transactions-${time}.csv`);
  } catch (error) {
    yield handleSagaError(error, i18n.t('transactions:modal.downloadError'));
  }
}

function* handleTransactions(config, perPage, currentPage) {
  const response = yield doTransactions(config);
  yield calculatePagination(response, perPage, currentPage);
  yield put(setTransactions(response.data));
}

function* calculatePagination(response, perPage, currentPage) {
  const unRoundedTotal = response.data.all_transactions_total / perPage;
  const totalPages = Math.ceil(unRoundedTotal);

  if (response.data.all_transactions_total < currentPage * perPage - perPage) {
    const hasPages = totalPages === 0 ? 1 : totalPages;
    yield put(setPaginatorCurrent(1));
    yield put(setPaginatorTotal(hasPages));
  } else if (response.data.all_transactions_total > perPage) {
    yield put(setPaginatorTotal(totalPages));
  } else {
    yield put(setPaginatorTotal(1));
    if (currentPage * perPage <= totalPages) {
      yield put(setPaginatorCurrent(1));
    }
  }
}

export function* transactionSaga() {
  yield takeLatest(
    [
      LOAD_TRANSACTIONS,
      SET_AMOUNT_FILTER,
      SET_SOURCE_FILTER,
      SET_DEVICE_FILTER,
      SET_STATUS_FILTER,
      SET_CALENDAR_SAGA,
      CLEAR_CALENDAR_FILTER,
      CHANGE_PAGINATOR_PAGE,
      REMOVE_PAYOUT_TRANSACTIONS
    ],
    handleTransactionFilters
  );
}

export function* debounceTransactionSaga() {
  yield debounce(200, SET_SEARCH_FILTER, handleTransactionFilters);
}

function* handleRouteChange(action) {
  if (
    action.to.startsWith('/transactions') &&
    action.from.startsWith('/transactions')
  ) {
    yield handleTransactionFilters(action);
  }
}

export function* transactionToggleSaga() {
  yield takeLatest(ROUTE_CHANGED, handleRouteChange);
}

export function* throttledTransactionSaga() {
  yield throttle(500, LOAD_PAYOUT_TRANSACTIONS, handleTransactionFilters);
}

export function* throttledTransactionCsvSaga() {
  yield throttle(5000, DOWNLOAD_TRANSACTIONS_CSV, handleTransactionFilters);
}

export function* markTransactionComplete(id) {
  const url = `/transactions/process_invalid_payment/${id}`;
  yield patchRequest(url);
}

function* handleMarkComplete(action) {
  try {
    yield markTransactionComplete(action.id);
    yield put(
      showSuccessModal(
        i18n.t('transactions:modal.successNotice.title'),
        i18n.t('transactions:modal.successNotice.message')
      )
    );
    yield put(loadTransactions());
    yield put(toggleExpandedTransaction(null));
  } catch (error) {
    yield handleSagaError(error);
  }
}

function* handleMarkCompleteRefund(action) {
  try {
    const { id } = action;
    yield markTransactionComplete(id);
    yield put(showModal());
    history.push({ pathname: `/refunds/${id}/new`, state: { id } });
  } catch (error) {
    yield handleSagaError(error);
  }
}

function* handleRefundInProgress(action) {
  try {
    const { id } = action;
    yield put(showModal());
    history.push({ pathname: `/refunds/${id}`, state: { id } });
    yield put(loadTransactions());
    yield put(toggleExpandedTransaction(null));
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* markCompleteSaga() {
  yield takeEvery(MARK_COMPLETE, handleMarkComplete);
}

export function* markCompleteRefundSaga() {
  yield takeEvery(MARK_COMPLETE_REFUND, handleMarkCompleteRefund);
}

export function* handleRefundInProgressSaga() {
  yield takeEvery(REFUND_IN_PROGRESS, handleRefundInProgress);
}

function* handleLoadWalletBalances() {
  try {
    const url = '/users/wallet_balances';
    const response = yield getRequest(url);
    yield put(setWalletBalances(response.data));
  } catch (error) {
    history.push('/error');
  }
}

export function* loadWalletBalancesSaga() {
  yield takeEvery(LOAD_WALLET_BALANCES, handleLoadWalletBalances);
}
