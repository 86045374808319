//@flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { Desktop, LgDesktop, NotLgDesktop } from '../../components/Responsive';
import {
  DashboardBannerActionLink,
  InProgressBannerWrapper,
  InProgressBannerImg,
  InProgressBannerContainer,
  InProgressDescriptionRegularP,
  InProgressDescriptionSpan,
  InProgressTitleP,
  InProgressBannerDiv,
  DashboardBannerActionA,
  CloseIcon,
  ActionButtonDiv,
  ImageContainer
} from './DashboardStyles';

type Props = {
  verificationState: string,
  hasPayouts: boolean,
  userId: number,
  dashboardScreen: boolean,
  t: TFunction,
  merchantSupportScreen: boolean
};
type State = { display: boolean };

class InProgressBanner extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const hideBanner =
      props.hasPayouts ||
      localStorage.getItem(`kyc_docs_approved_${props.userId}`);
    if (hideBanner) {
      this.state = { display: false };
    }
  }

  state = { display: true };

  handleClose = () => {
    if (this.props.verificationState === 'kyc_docs_approved') {
      localStorage.setItem(`kyc_docs_approved_${this.props.userId}`, 'true');
    }
    this.setState({ display: false });
  };

  renderBanner = () => {
    const { t } = this.props;
    let icon = '';
    let title;
    let description;
    let action = '';

    switch (this.props.verificationState) {
      case 'company_details_submitted':
        icon = 'hourglass-circle';
        title = t('dashboard:inProgressBanner.companyDetailsSubmitted.title');
        description = (
          <InProgressDescriptionRegularP>
            {t(
              'dashboard:inProgressBanner.companyDetailsSubmitted.merchantSupportTeam'
            )}{' '}
            <InProgressDescriptionSpan>
              {t(
                'dashboard:inProgressBanner.companyDetailsSubmitted.knowledgeBase'
              )}
            </InProgressDescriptionSpan>
          </InProgressDescriptionRegularP>
        );
        action = 'knowledgeBase';
        break;
      case 'company_details_approved':
        icon = 'conditional-approval';
        title = t('dashboard:inProgressBanner.companyDetailsApproved.title');
        description = (
          <InProgressDescriptionRegularP>
            {t(
              'dashboard:inProgressBanner.companyDetailsApproved.conditionallyApproved'
            )}{' '}
            <InProgressDescriptionSpan>
              merchantsupport@net-cents.com.
            </InProgressDescriptionSpan>
          </InProgressDescriptionRegularP>
        );
        action = 'upload';
        break;
      case 'company_details_denied':
        icon = 'denied-rejected';
        title = t('dashboard:inProgressBanner.companyDetailsDenied.title');
        description = (
          <InProgressDescriptionRegularP>
            {t(
              'dashboard:inProgressBanner.companyDetailsDenied.complianceTeam'
            )}{' '}
            <InProgressDescriptionSpan>
              merchantsupport@net-cents.com.
            </InProgressDescriptionSpan>
          </InProgressDescriptionRegularP>
        );
        action = true;
        break;
      case 'kyc_docs_submitted':
        icon = 'hourglass-circle';
        title = t('dashboard:inProgressBanner.kycDocsSubmitted.title');
        description = (
          <InProgressDescriptionRegularP>
            {t('dashboard:inProgressBanner.kycDocsSubmitted.teamWillReview')}{' '}
            <InProgressDescriptionSpan>
              {t(
                'dashboard:inProgressBanner.kycDocsSubmitted.checkOutKnowledgeBase'
              )}
            </InProgressDescriptionSpan>
          </InProgressDescriptionRegularP>
        );
        action = 'knowledgeBase';
        break;
      case 'kyc_docs_rejected':
        icon = 'warning-circle';
        title = t('dashboard:inProgressBanner.kycDocsRejected.title');
        description = (
          <InProgressDescriptionRegularP>
            {t('dashboard:inProgressBanner.kycDocsRejected.reUpload')}
          </InProgressDescriptionRegularP>
        );
        action = 'upload';
        break;
      case 'kyc_docs_approved':
        icon = 'checkmark-circle';
        title = t('dashboard:inProgressBanner.kycDocsApproved.title');
        description = (
          <InProgressDescriptionRegularP>
            {t('dashboard:inProgressBanner.kycDocsApproved.fullyApproved')}{' '}
            <InProgressDescriptionSpan>
              merchantsupport@net-cents.com.
            </InProgressDescriptionSpan>
          </InProgressDescriptionRegularP>
        );
        action = 'firstTransactionChecklist';
        break;
    }

    if (action === 'knowledgeBase') {
      action = (
        <ActionButtonDiv>
          <DashboardBannerActionA
            href="https://merchant-support.net-cents.com/en/knowledge"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('global:links.knowledgeBase')}
          </DashboardBannerActionA>
        </ActionButtonDiv>
      );
    } else if (action === 'upload') {
      action = (
        <ActionButtonDiv>
          <DashboardBannerActionLink to="/upload-documents">
            {t('dashboard:inProgressBanner.uploadDocs')}
          </DashboardBannerActionLink>
        </ActionButtonDiv>
      );
    } else if (action === 'firstTransactionChecklist') {
      action = (
        <ActionButtonDiv>
          <DashboardBannerActionA
            href="https://merchant-support.net-cents.com/en/knowledge/firsttransactionchecklist"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t(
              'dashboard:inProgressBanner.kycDocsApproved.firstTransactionChecklist'
            )}
          </DashboardBannerActionA>
        </ActionButtonDiv>
      );
    }
    if (!this.state.display) {
      return <div />;
    }

    // CloseIcon should only be hidden on banners that contain the "Upload Documents" call to action
    const isCloseIconVisible = ![
      'kyc_docs_rejected',
      'company_details_approved'
    ].includes(this.props.verificationState);

    return (
      <InProgressBannerContainer
        action={Boolean(action)}
        dashboardScreen={this.props.dashboardScreen}
        merchantSupportScreen={this.props.merchantSupportScreen}
        data-cy="in-progress-banner"
      >
        <ImageContainer
          merchantSupportScreen={this.props.merchantSupportScreen}
        >
          <Desktop>
            {icon && (
              <InProgressBannerImg
                // $FlowFixMe
                src={require(`../../assets/images/${icon}.png`).default}
                alt="banner image"
              />
            )}
          </Desktop>
          <InProgressBannerWrapper>
            <InProgressTitleP>{title}</InProgressTitleP>
            <InProgressBannerDiv>
              {description}
              <LgDesktop>{Boolean(action) && action}</LgDesktop>
            </InProgressBannerDiv>
            <NotLgDesktop>{Boolean(action) && action}</NotLgDesktop>
          </InProgressBannerWrapper>
          {isCloseIconVisible && (
            <CloseIcon name="x" onClick={this.handleClose} />
          )}
        </ImageContainer>
      </InProgressBannerContainer>
    );
  };

  render() {
    return this.renderBanner();
  }
}

export default withTranslation(['dashboard', 'global'])(InProgressBanner);
