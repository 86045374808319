import { takeLatest, put } from 'redux-saga/effects';
import { getRequest } from '../../utilities/requests';
import {
  setChartData,
  failedChartDataLoad,
  LOAD_CHART_DATA,
  LOAD_DASHBOARD_TRANSACTIONS,
  setDashboardTransactions,
  setBusinessInsights,
  LOAD_BUSINESS_INSIGHTS,
  failedDashboardTransactionLoad
} from './reducer';

function* handleLoadChartData() {
  try {
    const url = '/transactions/chart_data';
    const response = yield getRequest(url);
    yield put(setChartData(response.data.data));
  } catch (error) {
    yield put(failedChartDataLoad());
  }
}

export function* loadChartDataSaga() {
  yield takeLatest(LOAD_CHART_DATA, handleLoadChartData);
}

function* handleLoadTransactions(action) {
  try {
    const url = '/transactions';
    const params = {
      actions_required: true,
      per_page: 5,
      is_sandbox: action.is_sandbox
    };
    const response = yield getRequest(url, params);
    yield put(
      setDashboardTransactions(
        response.data.transactions,
        response.data.all_transactions_total
      )
    );
  } catch (error) {
    yield put(failedDashboardTransactionLoad());
  }
}

export function* loadDashboardTransactionsSaga() {
  yield takeLatest(LOAD_DASHBOARD_TRANSACTIONS, handleLoadTransactions);
}

function* handleLoadBusinessInsights() {
  try {
    const url = '/transactions/business_insights';
    const response = yield getRequest(url);
    yield put(setBusinessInsights(response.data));
  } catch (error) {
    // yield put(failedChartDataLoad());
  }
}

export function* loadBusinessInsightsSaga() {
  yield takeLatest(LOAD_BUSINESS_INSIGHTS, handleLoadBusinessInsights);
}
