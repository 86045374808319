// @flow
import { css } from 'styled-components';

export const maxWidthDefault = 1520;

export const maxContentSize = 1360;
export const paddingMobile = '2rem';
export const paddingDefault = '6rem';
export const marginDefault = '6rem';
export const spacingDefault = '12rem';

export const paddingDesktop = '8rem';
export const marginDesktop = '8rem';
export const spacingDesktop = '16rem';

// export const paddingTopTablet = marginTopTablet
export const paddingTopTablet = '5rem';
export const marginTopTablet = '5rem';
export const paddingBottomTablet = '2rem';
export const marginBottomTablet = '2rem';

export const paddingTopDesktop = '9rem';
export const marginTopDesktop = '9rem';
export const paddingBottomDesktop = '5rem';
export const marginBottomDesktop = '5rem';

export const defaultContainer = css`
  max-width: ${maxWidthDefault}px;
  padding: 0 ${paddingMobile};
  @media screen and (min-width: 768px) {
    padding: 0rem ${paddingDefault};
  }
  /* @media screen and (min-width: 1024px) {
    padding: 0 ${paddingDefault};
  } */
  @media screen and (min-width: 1440px) {
    padding: 0 ${paddingDesktop};
  }
`;

export const contentMinWidthMedia = (...args: any) => css`
  @media (min-width: ${props => (props.theme.sidebarOpened ? 1200 : 1024)}px) {
    ${css(...args)}
  }
`;
export const contentMaxWidthMedia = (...args: any) => css`
  @media (max-width: ${props => (props.theme.sidebarOpened ? 1199 : 1023)}px) {
    ${css(...args)}
  }
`;

export const arrow = (size: number, skew: number = 1) => css`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    border-left: ${size * skew}px solid transparent;
    border-right: ${size * skew}px solid transparent;
    border-top: ${size * skew}px solid transparent;
    border-bottom: ${size * skew}px solid transparent;
  }
`;

export const arrowDown = ({
  size,
  left = '50%',
  color,
  skew = 1,
  right = 'unset'
}: {
  size: number,
  left: string,
  color: string,
  skew: number,
  right: string
} = {}) => css`
  ${arrow(size, skew)};
  &::after {
    border-top: ${size}px solid ${color};
    top: 99%;
    left: ${left};
    transform: translateX(-50%);
    right: ${right};
  }
`;

export const arrowUp = ({
  size,
  left = '50%',
  color,
  skew = 1
}: { size: number, left: string, color: string, skew: number } = {}) => css`
  ${arrow(size, skew)};
  &::after {
    border-bottom: ${size}px solid ${color};
    top: 0;
    left: ${left};
    transform: translate(-50%, -100%);
  }
`;

export const arrowLeft = ({
  size,
  left = '1%',
  color,
  skew = 1
}: { size: number, left: string, color: string, skew: number } = {}) => css`
  ${arrow(size, skew)};
  &::after {
    border-right: ${size}px solid ${color};
    top: 50%;
    left: ${left};
    transform: translate(-100%, -50%);
  }
`;

export const arrowRight = (
  size: number,
  color: string,
  skew: number = 1
) => css`
  ${arrow(size, skew)};
  &::after {
    border-left: ${size}px solid ${color};
    top: 50%;
    left: 100%;
    transform: translate(0, -50%);
  }
`;

export const overflowEllipsis = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

// export const currencyColor = (iso: string) => {
//   switch (iso.toUpperCase()) {
//     case 'USD':
//       return '#3c3b6e';
//     case 'CAD':
//       return '#e12415';
//     case 'EUR':
//       return '#ffcc00';
//     case 'NCCO':
//       return '#4a90e2';
//     case 'BTC':
//       return '#f7931a';
//     case 'ETH':
//       return '#527698';
//     case 'LTC':
//       return '#bebebe';
//     case 'XEM':
//       return '#00cdbc';
//     case 'BCH':
//       return '#4cca47';
//     // case 'TRX':
//     // case 'XVG':
//     // case 'ZEN':
//     default:
//       break;
//   }
// };
