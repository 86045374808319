// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  EnableTwoFactorModalDiv,
  ConfirmationModalA,
  ConfirmationModalButtons
} from '../ModalConductorStyles';
import { connect } from 'react-redux';
import TwoFactorMethodType from './TwoFactorMethodType';
import {
  selectTwoFactorMethod,
  setSubmittingState
} from '../../../App/Company/reducer';
import { closeModal } from '../reducer';
import { TwoFactorModalContentDiv } from './TwoFactorAuthModalsStyles';
import SectionLoader from '../../../components/SectionLoader';

type Props = {
  closeModal: () => void,
  selectTwoFactorMethod: (authType: string) => void,
  setSubmittingState: (submittingState: boolean) => void,
  isSubmitting: boolean,
  t: TFunction,
  lang: string
};

class SelectTwoFactorMethodModal extends Component<Props> {
  handleClick = authType => {
    this.props.setSubmittingState(true);
    this.props.selectTwoFactorMethod(authType);
  };

  render() {
    const { t } = this.props;
    return (
      <EnableTwoFactorModalDiv>
        <TwoFactorModalContentDiv>
          {this.props.isSubmitting ? (
            <SectionLoader />
          ) : (
            <>
              <h4>{t('verification:twoFactorAuth.title')}</h4>
              <TwoFactorMethodType
                authType="email"
                handleClick={this.handleClick}
                title={t('verification:twoFactorAuth.email.title')}
                text={t('verification:twoFactorAuth.email.selectMessage')}
              />
              <TwoFactorMethodType
                authType="totp"
                handleClick={this.handleClick}
                title={t('verification:twoFactorAuth.google.title')}
                text={t('verification:twoFactorAuth.google.selectMessage')}
              />
            </>
          )}
        </TwoFactorModalContentDiv>
        <ConfirmationModalButtons>
          <ConfirmationModalA
            onClick={this.props.closeModal}
            lang={this.props.lang}
          >
            {t('global:actions.cancel')}
          </ConfirmationModalA>
        </ConfirmationModalButtons>
      </EnableTwoFactorModalDiv>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: state.company.isSubmitting
});

const mapDispatchToProps = {
  selectTwoFactorMethod,
  setSubmittingState,
  closeModal
};

export default connect(
  // null,
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['global', 'verification'])(SelectTwoFactorMethodModal));
