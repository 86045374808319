import { SET_INITIAL_STATE } from '../reducer';
export const ADD_ECOM_PLUGIN = 'ADD_ECOM_PLUGIN';
export const DELETE_PLUGIN = 'ecom/DELETE_PLUGIN';
export const SET_PLUGIN_DELETED = 'ecom/SET_PLUGIN_DELETED';

export const addEcomPlugin = plugin => ({
  type: ADD_ECOM_PLUGIN,
  plugin
});

export const deletePlugin = id => ({
  type: DELETE_PLUGIN,
  id
});
export const setPluginDeleted = id => ({
  type: SET_PLUGIN_DELETED,
  id
});

const initialState = { plugins: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return { ...state, plugins: action.payload.ecommerce_plugins };
    case ADD_ECOM_PLUGIN:
      return { ...state, plugins: [...state.plugins, action.plugin] };
    case SET_PLUGIN_DELETED:
      return {
        ...state,
        plugins: state.plugins.filter(plugin => plugin.id !== action.id)
      };
    default:
      return state;
  }
};
