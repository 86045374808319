import { SET_INITIAL_STATE } from '../reducer';
export const TOGGLE_API_STATUS = 'TOGGLE_API_STATUS';
export const SET_WEBHOOK_TEST_DATA = 'SET_WEBHOOK_TEST_DATA';
export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const SET_WEBHOOK_UNPAUSE_DATA = 'SET_WEBHOOK_UNPAUSE_DATA';

export const updateApiKey = apiKey => ({
  type: UPDATE_API_KEY,
  apiKey
});
export const toggleApiStatus = apiStatus => ({
  type: TOGGLE_API_STATUS,
  apiStatus
});
export const setWebhookTestData = webhookResponse => ({
  type: SET_WEBHOOK_TEST_DATA,
  webhookResponse
});

export const setWebhookUnpauseData = () => ({
  type: SET_WEBHOOK_UNPAUSE_DATA
});

const initialState = {
  apiKeysStatus: 'live',
  webhookResponse: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return {
        ...state,
        apiKeys: action.payload.merchant_apis,
        pausedWebhooks: action.payload.paused_webhooks
      };
    case TOGGLE_API_STATUS:
      return { ...state, apiKeysStatus: action.apiStatus };
    case UPDATE_API_KEY:
      return {
        ...state,
        apiKeys: state.apiKeys.map(apiKey =>
          apiKey.id === action.apiKey.id ? action.apiKey : apiKey
        )
      };
    case SET_WEBHOOK_TEST_DATA:
      return {
        ...state,
        webhookResponse: action.webhookResponse
          ? {
            status: action.webhookResponse.status,
            body: action.webhookResponse.body
          }
          : undefined
      };
    case SET_WEBHOOK_UNPAUSE_DATA:
      return {
        ...state,
        pausedWebhooks: false
      };
    default:
      return state;
  }
};
