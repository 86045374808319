// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  ConfirmationModalButtons,
  ErrorModalDiv,
  SuccessModalA,
  ConfirmationModalContentDiv
} from './ModalConductorStyles';
import { DefaultIcon } from '../../components/Icon';
export type ErrorModalPayload = {
  message: string,
  onConfirm?: () => void,
  confirmText?: string
};
type Props = {
  closeModal: () => void,
  ...$Exact<ErrorModalPayload>,
  t: TFunction
};

class ErrorModal extends Component<Props> {
  confirm = () => {
    this.props.closeModal();
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  };
  render() {
    return (
      <ErrorModalDiv>
        <ConfirmationModalContentDiv>
          <DefaultIcon name="warning" />
          <p>{this.props.message}</p>
        </ConfirmationModalContentDiv>
        <ConfirmationModalButtons>
          <SuccessModalA onClick={this.confirm}>
            {this.props.confirmText || this.props.t('actions.ok')}
          </SuccessModalA>
        </ConfirmationModalButtons>
      </ErrorModalDiv>
    );
  }
}
export default withTranslation(['global'])(ErrorModal);
