import { ROUTE_CHANGED } from '../../reducer';

export const LOAD_AVAILABLE_ASSETS = 'LOAD_AVAILABLE_ASSETS';
export const SET_AVAILABLE_ASSETS = 'SET_AVAILABLE_ASSETS';
export const SET_SUBMIT_SUCCEEDED =
  'AssetManagement/Currencies/SET_SUBMIT_SUCCEEDED';

export const setSubmitSucceeded = () => ({
  type: SET_SUBMIT_SUCCEEDED
});
export const setAvailableAssets = availableAssets => ({
  type: SET_AVAILABLE_ASSETS,
  availableAssets
});
export const loadAvailableAssets = () => ({
  type: LOAD_AVAILABLE_ASSETS
});

const initialState = { loading: true };
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAILABLE_ASSETS:
      return {
        ...state,
        availableAssets: action.availableAssets,
        loading: false
      };
    case SET_SUBMIT_SUCCEEDED:
      return {
        ...state,
        submitSucceeded: true
      };
    case ROUTE_CHANGED:
      return {
        ...state,
        submitSucceeded: false
      };
    default:
      return state;
  }
};
