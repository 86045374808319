import { SET_ASSET_BALANCES } from './balances';
import {
  SET_ASSET_MANAGEMENT_TRANSACTIONS,
  LOAD_ASSET_MANAGEMENT_TRANSACTIONS
} from './transactions';
import {
  SET_TRANSACTION_TYPE,
  SET_PAGE,
  SET_SEARCH_VALUE,
  SET_PER_PAGE,
  SET_CURRENCY_FILTER,
  SET_STATUS_FILTER,
  SET_EXTERNAL_USER_ID_FILTER
} from './transactionsFilters';

export const FAILED_ASSET_MANAGEMENT_TRANSACTION_LOAD =
  'FAILED_ASSET_MANAGEMENT_TRANSACTION_LOAD';
export const SET_SEARCH_ACTIVE_STATE = 'SET_SEARCH_ACTIVE_STATE';
export const EXPAND_ASSET_MANAGEMENT_TRANSACTION_ROW =
  'EXPAND_ASSET_MANAGEMENT_TRANSACTION_ROW';
const SET_ACTIVE_TAB = 'assetManagement/SET_ACTIVE_TAB';

export const setActiveTab = activeTab => ({
  type: SET_ACTIVE_TAB,
  activeTab
});

export const expandAssetManagementTransactionRow = id => ({
  type: EXPAND_ASSET_MANAGEMENT_TRANSACTION_ROW,
  id
});
export const setSearchActiveState = active => ({
  type: SET_SEARCH_ACTIVE_STATE,
  active
});
export const failedAssetManagementTransactionLoad = () => ({
  type: FAILED_ASSET_MANAGEMENT_TRANSACTION_LOAD
});

const initialState = {
  balancesLoading: true,
  transactionsLoading: true,
  transactionsFailedLoad: false,
  transactionCount: 0,
  statusOptions: [],
  externalUserIdOptions: [],
  visitedPages: [1],
  searchActive: false,
  expandedRowId: null
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSET_BALANCES:
      return {
        ...state,
        balancesLoading: false
      };
    case FAILED_ASSET_MANAGEMENT_TRANSACTION_LOAD:
      return {
        ...state,
        transactionsFailedLoad: true
      };
    case LOAD_ASSET_MANAGEMENT_TRANSACTIONS:
      return {
        ...state,
        transactionsLoading: true
      };
    case SET_ASSET_MANAGEMENT_TRANSACTIONS:
      return {
        ...state,
        transactionCount: action.payload.transaction_count,
        statusOptions: action.payload.status_options,
        externalUserIdOptions: action.payload.external_user_id_options,
        transactionsLoading: false,
        transactionsFailedLoad: false
      };
    case SET_PAGE:
      return {
        ...state,
        visitedPages: state.visitedPages.includes(action.page)
          ? state.visitedPages
          : [...state.visitedPages, action.page]
      };
    case SET_TRANSACTION_TYPE:
      return {
        ...state,
        visitedPages: [1],
        transactionsLoading: true
      };
    case SET_SEARCH_VALUE:
    case SET_PER_PAGE:
    case SET_CURRENCY_FILTER:
    case SET_STATUS_FILTER:
    case SET_EXTERNAL_USER_ID_FILTER:
      return {
        ...state,
        visitedPages: [1]
      };
    case SET_SEARCH_ACTIVE_STATE:
      return {
        ...state,
        searchActive: action.active
      };
    case EXPAND_ASSET_MANAGEMENT_TRANSACTION_ROW:
      return {
        ...state,
        expandedRowId: state.expandedRowId === action.id ? null : action.id
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };
    default:
      return state;
  }
};
