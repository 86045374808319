import { takeLatest } from 'redux-saga/effects';
import { TOGGLE_SIDEBAR } from '../../../App/reducer';

function handleSidebarToggle() {
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 50);
}

export function* sidebarToggleSaga() {
  yield takeLatest(TOGGLE_SIDEBAR, handleSidebarToggle);
}
