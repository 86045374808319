import styled, { css } from 'styled-components/macro';
import { ButtonInput } from './ButtonStyles';

export const SaveOverlayDiv = styled.div`
  position: fixed;
  background-color: white;
  border: ${props => props.theme.defaultBorder};
  top: -10.5rem;
  left: ${props => (props.sidebarToggle ? '231px' : '69px')};
  right: 0;
  height: 10rem;
  z-index: 110;
  padding-right: 6rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: top ease 0.2s;
  input {
    margin-top: 0;
  }
  @media screen and (max-width: 767px) {
    left: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    justify-content: center;
    flex-direction: column-reverse;
  }
  ${props =>
    props.visible &&
    css`
      top: 0;
    `}
`;

export const SaveButtonDiv = styled.div`
  @media screen and (min-width: 768px) {
    input {
      width: 200px;
    }
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-left: 3rem;
    align-items: center;
  }
  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: column;
      }
    `}
`;

export const SaveButton = styled(ButtonInput)`
  padding: 1rem;
  height: 5rem;
  @media screen and (max-width: 767px) {
    margin-top: 2.5rem;
  }
  ${props =>
    props.overlay &&
    css`
      @media screen and (min-width: 768px) {
        margin-left: 3rem;
        width: 200px;
        /* width: 180px; */
      }
    `}
`;

export const SavePromptP = styled.p`
  text-align: center;
  margin-top: 0.6rem;
  font-weight: 700;
  color: ${props => props.theme.black};
  @media screen and (min-width: 768px) {
    width: 200px;
    margin-top: 0;
  }
  ${props =>
    props.vertical &&
    css`
      margin-bottom: 10px;
    `}
`;

export const SaveSuccessP = styled.p`
  text-align: center;
  margin-top: 0.6rem;
  margin-right: 2rem;
  color: ${props => props.theme.brand};
`;

export const ErrorP = styled.p`
  text-align: center;
  margin-top: 0.6rem;
  color: ${props => props.theme.red};
  @media screen and (min-width: 768px) {
    text-align: right;
  }
`;
