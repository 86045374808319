import {
  UPDATE_ACCEPTED_CURRENCIES,
  ROUTE_CHANGED,
  SET_INITIAL_STATE
} from '../../App/reducer';
export const SET_SUBMIT_SUCCEEDED = 'AcceptedCurrencies/SET_SUBMIT_SUCCEEDED';
export const SET_TOLERANCE_FORM_SUBMIT_SUCCEEDED =
  'SET_TOLERANCE_FORM_SUBMIT_SUCCEEDED';
export const TOGGLE_UNDERPAID_TOLERANCE_TYPE =
  'TOGGLE_UNDERPAID_TOLERANCE_TYPE';

export const setSubmitSucceeded = () => ({
  type: SET_SUBMIT_SUCCEEDED
});
export const setToleranceFormSubmitSucceeded = () => ({
  type: SET_TOLERANCE_FORM_SUBMIT_SUCCEEDED
});

export const updateAcceptedCurrencies = payload => ({
  type: UPDATE_ACCEPTED_CURRENCIES,
  payload
});
export const toggleUnderpaidToleranceType = () => ({
  type: TOGGLE_UNDERPAID_TOLERANCE_TYPE
});

const initialState = {
  submitSucceeded: false,
  toleranceFormSubmitSucceeded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBMIT_SUCCEEDED:
      return {
        ...state,
        submitSucceeded: true
      };
    case SET_TOLERANCE_FORM_SUBMIT_SUCCEEDED:
      return {
        ...state,
        toleranceFormSubmitSucceeded: true
      };
    case ROUTE_CHANGED:
      return {
        ...state,
        submitSucceeded: false
      };
    case TOGGLE_UNDERPAID_TOLERANCE_TYPE:
      return {
        ...state,
        underpaidToleranceType: 'off'
      };

    case SET_INITIAL_STATE:
      return {
        ...state,
        activeButton:
          action.payload.payment_tolerances.underpaid_tolerance_type,
        overpaidActiveButton:
          action.payload.payment_tolerances.overpaid_tolerance_type
      };
    default:
      return state;
  }
};
