//@flow
import React from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import errorGirl from '../../assets/images/500-vector-girl-color.png';
import ErrorPage from './ErrorPage';

type Props = { t: TFunction };
class Error500 extends React.Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <ErrorPage
        errorGirl={errorGirl}
        title={t('errorPages.500.title')}
        subTitle={t('errorPages.500.subtitle')}
      />
    );
  }
}
export default withTranslation(['unauthenticated'])(Error500);
