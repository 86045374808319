import { put, takeLatest } from '@redux-saga/core/effects';
import { getRequest, handleSagaError } from '../../../../utilities/requests';
import { LOAD_RECURRING_INVOICE, setRecurringInvoice } from './reducer';

function* handleLoadRecurringInvoice(action) {
  try {
    const url = `/recurring_invoice_cycles/${action.id}`;
    const response = yield getRequest(url);
    yield put(setRecurringInvoice(response.data));
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* recurringInvoiceSaga() {
  yield takeLatest(LOAD_RECURRING_INVOICE, handleLoadRecurringInvoice);
}
