import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle`

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: top;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  margin: 0;
  background-color: transparent;
}



html {
  font-size: 62.5%;
}
h1,
h2,
h3,
h4,
h5 {
  ${props => props.theme.fontStackHeader};
  font-weight: 600;
  text-transform: uppercase;
}
h6 {
  ${props => props.theme.fontStackBody};
  font-size: 1.2rem;
  font-weight: 700;
}

li {
  list-style: none
}

p {
  ${props => props.theme.fontStackBody};
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.8rem;
}
td {
  ${props => props.theme.fontStackBody};
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 2rem;
  @media and (max-width: 767px) {
    font-size: 1.2rem;
  }
}
th {
  ${props => props.theme.fontStackHeader};
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}
small {
  font-size: 1.2rem;
}
strong {
  font-weight: 600;
}

* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

a:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

a[role='button'] {
  cursor: pointer;
}
/* html {
  font-size: 62.5%;
} */

body,
input,
aside,
input::placeholder,
table {
  ${props => props.theme.fontStackBody};
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
}
/* input[type='submit'], */
button {
  ${props => props.theme.fontStackHeader};
  font-weight: 300;
  font-size: 1.3rem;
  border: none;
  padding: 0;
  margin: 0;
}

button:focus,
input[type='submit']:focus {
  outline: none;
}

@media screen and (max-width: 767px) {
  select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"]{
    font-size: 16px;
  }
}

body {
   scrollbar-width: none
}

input,
button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 0 !important;
  background-color: unset;
}

td {
  font-variant-numeric: tabular-nums;
}

h1 {
  font-size: 2.6rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.4rem;
}
h5 {
  font-size: 1.3rem;
  line-height: 1.7rem;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.2rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.4rem;
  }
  h4 {
    font-size: 1.3rem;
  }
  h5 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 374px) {
  h1 {
    font-size: 2.1rem;
  }
  h2 {
    font-size: 1.7rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1.2rem;
  }
}


`;
