//@flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  ProfileIconWrapperDiv,
  ProfileIconTitleP,
  LogoImg,
  ProfileIconTitleDiv
} from './LayoutStyles';

type Props = {
  logo: ?string,
  t: TFunction
};

class Profile extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <ProfileIconWrapperDiv to="/account/branding">
        {this.props.logo ? (
          <LogoImg src={this.props.logo} />
        ) : (
          <ProfileIconTitleDiv>
            <ProfileIconTitleP>{t('actions.upload')}</ProfileIconTitleP>
            <ProfileIconTitleP>{t('links.logo')}</ProfileIconTitleP>
          </ProfileIconTitleDiv>
        )}
      </ProfileIconWrapperDiv>
    );
  }
}

export default withTranslation(['global'])(Profile);
