export const SET_USERS = 'SET_USERS';
export const LOAD_USERS = 'LOAD_USERS';
export const FAILED_USERS_LOAD = 'FAILED_USERS_LOAD';
export const ADD_USER = 'ADD_USER';
export const UPDATE_SUB_USER = 'UPDATE_SUB_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_USER_DELETED = 'SET_USER_DELETED';

export const addUser = user => ({
  type: ADD_USER,
  user
});
export const failedUsersLoad = () => ({
  type: FAILED_USERS_LOAD
});
export const loadUsers = () => ({
  type: LOAD_USERS
});
export const setUsers = users => ({
  type: SET_USERS,
  users
});
export const updateSubUser = subUser => ({
  type: UPDATE_SUB_USER,
  subUser
});
export const deleteUser = id => ({
  type: DELETE_USER,
  id
});
export const setUserDeleted = id => ({
  type: SET_USER_DELETED,
  id
});

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return action.users;
    case ADD_USER:
      return [...state, action.user];
    case UPDATE_SUB_USER:
      return state.map(subUser =>
        subUser.id === action.subUser.id ? action.subUser : subUser
      );
    case SET_USER_DELETED:
      return state.filter(user => user.id !== action.id);
    default:
      return state;
  }
};
