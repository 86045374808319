//@flow
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { WithDropdownWrapper } from './styles';

type Props = {
  handleClickOption: (option: any) => void,
  initialOption?: any,
  disabled?: boolean
};

// TODO: turn into Custom Hook

const withDropdown = (Component: any) => {
  const WrappedComponent = (props: Props) => {
    const [isDropdownActive, setDropdownActive] = useState(false);
    const [activeOption, setActiveOption] = useState(
      props.initialOption || null
    );
    const toggleDropdown = () => {
      setDropdownActive(!isDropdownActive);
    };

    const setSelectedOption = (option: any) => {
      setActiveOption(option);
      setDropdownActive(!isDropdownActive);
      props.handleClickOption(option);
    };

    const handleBlur = () => {
      if (!isDropdownActive) return;
      toggleDropdown();
    };
    return (
      <WithDropdownWrapper
        onBlur={handleBlur}
        tabIndex="0"
        disabled={props.disabled}
      >
        <Component
          {...props}
          activeOption={activeOption}
          isDropdownActive={isDropdownActive}
          toggleDropdown={toggleDropdown}
          setSelectedOption={setSelectedOption}
        />
      </WithDropdownWrapper>
    );
  };

  return withTranslation(['global'])(WrappedComponent);
};

export default withDropdown;
