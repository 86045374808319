// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import {
  passwordValidation,
  passwordConfirm
} from '../../utilities/validations';
import Input from '../../components/Input';
import SubmitButton from '../../components/SubmitButton';
import { setSignInMessage } from '../SignIn/reducer';
import { history } from '../../utilities/history';
import { GreyTextReactLink } from '../../components/LinkStyles';

type Props = {
  handleSubmit: (values: any) => void,
  error: ?string,
  submitting: boolean,
  t: TFunction
};

class UpdatePassword extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <h1>{t('unauthenticated:resetPassword.header.title')}</h1>
        <Field
          name="password"
          label={t('unauthenticated:resetPassword.form.fields.password')}
          validate={passwordValidation}
          type="password"
          autocomplete="new-password"
          component={Input}
          passwordValidation
        />
        <Field
          name="password_confirmation"
          label={t('unauthenticated:resetPassword.form.fields.confirmPassword')}
          validate={passwordConfirm}
          type="password"
          autocomplete="new-password"
          component={Input}
        />

        <SubmitButton
          value={t('global:actions.submit')}
          error={this.props.error}
          disabled={this.props.submitting}
          marginTop="3rem"
        />
        <GreyTextReactLink to="/signin">
          {t('global:actions.cancel')}
        </GreyTextReactLink>
      </form>
    );
  }
}

const onSubmitSuccess = (result, dispatch, props) => {
  history.push('/signin');
  dispatch(
    setSignInMessage(props.t('unauthenticated:modal.updateConfirmation'))
  );
};

export default reduxForm({
  form: 'updatePassword',
  onSubmitSuccess
})(withTranslation(['unauthenticated', 'global'])(UpdatePassword));
