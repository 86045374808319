//@flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

type Props = {|
  component: Function,
  isAuthenticated: boolean,
  isAuthorized: boolean,
  exact?: boolean,
  path: string
|};

type State = {
  prevAuthenticated?: boolean
};

export default class AuthRoute extends React.Component<Props, State> {
  state = {};

  static defaultProps = {
    isAuthorized: true
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    return {
      prevAuthenticated: state.prevAuthenticated || props.isAuthenticated
    };
  }

  render() {
    const {
      component: Component,
      isAuthenticated,
      isAuthorized,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={routeProps => {
          if (!isAuthenticated) {
            return (
              <Redirect
                to={{
                  pathname: '/signin',
                  state: this.state.prevAuthenticated
                    ? undefined
                    : {
                        from: routeProps.location.pathname,
                        redirectCount: routeProps.location.state
                          ? routeProps.location.state.redirectCount + 1
                          : 0
                      }
                }}
              />
            );
          } else if (isAuthorized) {
            return <Component {...routeProps} />;
          } else {
            return <Redirect to={{ pathname: '/' }} />;
          }
        }}
      />
    );
  }
}
