// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { confirmEmailToken } from './sagas';
import PageLoader from '../../components/PageLoader';
import { type Match } from 'react-router-dom';
import { history } from '../../utilities/history';

type Props = {
  confirmEmailToken: (token: string) => void,
  match: Match
};

class ConfirmEmail extends Component<Props> {
  componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      this.props.confirmEmailToken(token);
    } else {
      history.push('/page_not_found');
    }
  }

  render() {
    return <PageLoader />;
  }
}

// const mapStateToProps = (state) => ({

// })

const mapDispatchToProps = {
  confirmEmailToken
};

export default connect(
  null,
  mapDispatchToProps
)(ConfirmEmail);
