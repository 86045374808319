// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  LanguageListItem,
  LanguageList,
  Divider,
  ChevronDown
} from './LanguageListItemStyles';
import { filteredLanguages } from './selectors';
import { changeLocale } from '../../i18n';
import { toggleLanguageDropDown, setLanguageData } from '../../App/reducer';

type Props = {
  languageDropDownOpen: boolean,
  activeLanguage: Language,
  filteredLanguages: Language[],
  setLanguageData: Language => void,
  toggleLanguageDropDown: (typeof undefined | boolean) => void,
  fixedHeader: boolean
};
type State = {
  scrollValue: number
};
type Language = {
  language: string,
  locale: string
};
class LanguageDropDown extends Component<Props, State> {
  state = {
    scrollValue: 0
  };
  componentDidMount() {
    window.addEventListener('click', this.handleDropDown);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleDropDown);
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({ scrollValue: window.scrollY });
  };

  handleLanguageSelect = (item: Language) => {
    localStorage.setItem('locale', item.locale);
    changeLocale(item.locale);
    this.props.setLanguageData(item);
    this.props.toggleLanguageDropDown();
  };

  handleToggleLanguageDropDown = () => {
    this.props.toggleLanguageDropDown();
  };

  handleDropDown = (e: any) => {
    if (
      this.props.languageDropDownOpen &&
      JSON.stringify(this.props.activeLanguage) !== e.target.dataset.tag
    ) {
      this.props.toggleLanguageDropDown();
    }
  };
  handleDropDownOpenedItems = (item: { language: string }) =>
    `${item.language}`;

  render() {
    return (
      <LanguageList
        open={this.props.languageDropDownOpen}
        fixedHeader={this.props.fixedHeader}
        onClick={this.handleToggleLanguageDropDown}
        data-tag={JSON.stringify(this.props.activeLanguage)}
        hideOnScroll={this.state.scrollValue > 0}
      >
        <LanguageListItem
          selected
          open={this.props.languageDropDownOpen}
          data-tag={JSON.stringify(this.props.activeLanguage)}
        >
          {!this.props.languageDropDownOpen
            ? this.props.activeLanguage.locale
            : this.handleDropDownOpenedItems(this.props.activeLanguage)}
          <ChevronDown
            name="chevronDown"
            data-tag={JSON.stringify(this.props.activeLanguage)}
            selected={this.props.activeLanguage.language}
            open={this.props.languageDropDownOpen}
          />
        </LanguageListItem>
        {this.props.languageDropDownOpen &&
          this.props.filteredLanguages.map((item: Language) => (
            <React.Fragment key={item.language}>
              <Divider open={this.props.languageDropDownOpen} />
              <LanguageListItem
                key={item.language}
                onClick={() => this.handleLanguageSelect(item)}
                open={this.props.languageDropDownOpen}
              >
                {this.handleDropDownOpenedItems(item)}
              </LanguageListItem>
            </React.Fragment>
          ))}
      </LanguageList>
    );
  }
}

const mapDispatchToProps = {
  toggleLanguageDropDown,
  setLanguageData
};

const mapStateToProps = state => ({
  activeLanguage: state.global.language.activeLanguage,
  filteredLanguages: filteredLanguages(state),
  languageDropDownOpen: state.global.language.languageDropDownOpen
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageDropDown);
