import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const TabHr = styled.hr`
  height: 2px;
  background-color: black;
  margin: 0;
  position: absolute;
  top: calc(100% - 2px);
  border: 0;
  z-index: 1;
  ${props =>
    props.transitionActive &&
    css`
      transition: left 0.2s ease, width 0.2s ease;
    `}
`;
export const tabContainer = css`
  @media screen and (max-width: 767px) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TabsDiv = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 1.5rem;
  padding-top: 3rem;
  @media screen and (max-width: 767px) {
    margin-left: 2rem;
    ${props =>
      props.largePaddingTop &&
      css`
        padding-top: 6rem;
      `}
  }
`;

export const SpacerTab = styled.div`
${props =>
  !props.mobile &&
  css`
    @media screen and (max-width: 767px) {
      display: none;
    }
  `}
  ${props =>
    !props.tablet &&
    css`
      @media screen and (max-width: 1023px) {
        display: none;
      }
    `}
    border-bottom: ${props => props.theme.defaultBorder};
    flex-grow: 1;
`;

export const MobileTabSpacerDiv = styled.div`
  padding: 0 1rem;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const tabStyles = css`
  ${props => props.theme.fontStackHeader};
  position: relative;
  border-bottom: ${props => props.theme.defaultBorder};
  line-height: 1.8rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.8rem 0.8rem;
  transition: color ease 0.25s;
  color: ${props => props.theme.dustierGray};
  flex-grow: 1;
  text-align: center;
  white-space: nowrap;
  &:hover {
    color: black;
  }
  &.active {
    color: black;

    &::after {
      content: '';
      display: block;
      border-bottom: 2px solid #000;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -1px;
      animation-name: easein;
      animation-duration: 0.2s;
    }
  }

  @keyframes easein {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }
`;

export const TabNavLink = styled(NavLink)`
  ${tabStyles}
`;
