import { put, takeLatest } from 'redux-saga/effects';
import { getRequest, handleSagaError } from '../../../../utilities/requests';
import { LOAD_INVOICE_CURRENCIES, setInvoiceCurrencies } from './reducer';

function* handleLoadInvoiceCurrencies() {
  try {
    const url = '/invoices/get_invoice_currencies';
    const response = yield getRequest(url);
    yield put(setInvoiceCurrencies(response.data));
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* loadInvoiceCurrenciesSaga() {
  yield takeLatest(LOAD_INVOICE_CURRENCIES, handleLoadInvoiceCurrencies);
}
