import {
  TOGGLE_FILTER,
  SET_TYPE_FILTER,
  SET_STATUS_FILTER,
  SET_AMOUNT_FILTER,
  SET_CALENDAR_START_FILTER,
  SET_CALENDAR_END_FILTER,
  CLEAR_CALENDAR_FILTER,
  TOGGLE_CALENDAR_FILTER,
  OPEN_FILTER_TOGGLE
} from '.';

const initialState = {
  toggleFilterState: true,
  typeFilter: undefined,
  statusFilter: undefined,
  amountFilter: 10,
  calendarFilterState: false,

  calendarStart: null,
  calendarEnd: null,
  isMobile: false
};

const displayActiveFilter = (state, key) => ({
  ...state,
  calendarFilterState: false,
  [key]: !state[key]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FILTER:
      return {
        ...state,
        toggleFilterState: !state.toggleFilterState
      };
    case OPEN_FILTER_TOGGLE:
      return {
        ...state,
        toggleFilterState: true
      };
    case SET_TYPE_FILTER:
      return {
        ...state,
        typeFilter: action.typeFilter
      };
    case SET_STATUS_FILTER:
      return {
        ...state,
        statusFilter: action.statusFilter
      };
    case SET_AMOUNT_FILTER:
      return {
        ...state,
        amountFilter: action.amountFilter
      };
    case SET_CALENDAR_START_FILTER:
      return {
        ...state,
        calendarStart: action.calendarStart
      };
    case SET_CALENDAR_END_FILTER:
      return {
        ...state,
        calendarEnd: action.calendarEnd
      };
    case CLEAR_CALENDAR_FILTER:
      return {
        ...state,
        calendarStart: null,
        calendarEnd: null,
        calendarFilterState: false
      };
    case TOGGLE_CALENDAR_FILTER:
      return displayActiveFilter(state, 'calendarFilterState');
    default:
      return state;
  }
};
