// @flow
import React, { Component } from 'react';
import NavbarItems from './NavbarItems';
import { connect } from 'react-redux';
import { logout } from '../../App/reducer';
import { Desktop, Tablet } from '../../components/Responsive';
import { type Permissions } from '../../utilities/types';

type Props = {
  logout: () => void,
  permissions: Permissions,
  simple: boolean,
  languageDropDownOpen: boolean
};

class Navbar extends Component<Props> {
  render() {
    if (this.props.simple) {
      return null;
    }
    return (
      <React.Fragment>
        <Desktop>
          <NavbarItems
            desktop
            logout={this.props.logout}
            permissions={this.props.permissions}
            open={this.props.languageDropDownOpen}
          />
        </Desktop>
        <Tablet>
          <NavbarItems
            logout={this.props.logout}
            permissions={this.props.permissions}
            open={this.props.languageDropDownOpen}
          />
        </Tablet>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.global.permissions,
  languageDropDownOpen: state.global.language.languageDropDownOpen
});

const mapDispatchToProps = {
  logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
