import { takeLatest, takeEvery, put } from 'redux-saga/effects';
import i18n from '../../i18n';
import {
  LOAD_USERS,
  setUsers,
  failedUsersLoad,
  DELETE_USER,
  setUserDeleted
} from './reducer/users';
import {
  getRequest,
  deleteRequest,
  handleSagaError
} from '../../utilities/requests';
import { setGroups, failedGroupsLoad, LOAD_GROUPS } from './reducer/groups';
import { showSuccessModal } from '../../modules/ModalConductor/reducer';

function* handleLoadUsers() {
  const url = '/subordinate_users';
  try {
    const response = yield getRequest(url);
    yield put(setUsers(response.data.subordinate_users));
  } catch (error) {
    yield put(failedUsersLoad());
  }
}

export function* loadUsersSaga() {
  yield takeLatest(LOAD_USERS, handleLoadUsers);
}

function* handleLoadGroups() {
  const url = '/roles';
  try {
    const response = yield getRequest(url);
    yield put(
      setGroups(response.data.roles, response.data.permissions_details)
    );
  } catch (error) {
    yield put(failedGroupsLoad());
  }
}

export function* loadGroupsSaga() {
  yield takeLatest(LOAD_GROUPS, handleLoadGroups);
}

function* handleDeleteUser(action) {
  try {
    const url = `/subordinate_users/${action.id}`;
    const response = yield deleteRequest(url);
    yield put(setUserDeleted(response.data.id));
    yield put(
      showSuccessModal(
        i18n.t('settings:modal.deletedUserNotice.title'),
        i18n.t('settings:modal.deletedUserNotice.message')
      )
    );
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* deleteUserSaga() {
  yield takeEvery(DELETE_USER, handleDeleteUser);
}
