export const SET_TRANSACTION_TYPE = 'SET_TRANSACTION_TYPE';
export const SET_PAGE = 'AssetTransactions/SET_PAGE';
export const SET_EXTERNAL_USER_ID_FILTER =
  'AssetTransactions/SET_EXTERNAL_USER_ID_FILTER';
export const SET_STATUS_FILTER = 'AssetTransactions/SET_STATUS_FILTER';
export const SET_CURRENCY_FILTER = 'AssetTransactions/SET_CURRENCY_FILTER';
export const SET_PER_PAGE = 'AssetTransactions/SET_PER_PAGE';
export const SET_SEARCH_VALUE = 'AssetTransactions/SET_SEARCH_VALUE';

export const setSearchValue = searchValue => ({
  type: SET_SEARCH_VALUE,
  searchValue
});
export const setPerPage = perPage => ({
  type: SET_PER_PAGE,
  perPage
});
export const setCurrencyFilter = currency_id => ({
  type: SET_CURRENCY_FILTER,
  currency_id
});
export const setStatusFilter = status => ({
  type: SET_STATUS_FILTER,
  status
});
export const setExternalUserIdFilter = external_user => ({
  type: SET_EXTERNAL_USER_ID_FILTER,
  external_user
});
export const setPage = page => ({
  type: SET_PAGE,
  page
});
export const setTransactionType = transactionType => ({
  type: SET_TRANSACTION_TYPE,
  transactionType
});

// this slice of state is submitted to server with each transaction request
const initialState = {
  transactionType: 'deposit',
  external_user: undefined,
  status: undefined,
  currency_id: undefined,
  perPage: 10,
  page: 1,
  searchValue: ''
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.transactionType,
        page: 1
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page
      };
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue,
        page: 1
      };
    case SET_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
        page: 1
      };
    case SET_CURRENCY_FILTER:
      return {
        ...state,
        currency_id: action.currency_id,
        page: 1
      };
    case SET_STATUS_FILTER:
      return {
        ...state,
        status: action.status,
        page: 1
      };
    case SET_EXTERNAL_USER_ID_FILTER:
      return {
        ...state,
        external_user: action.external_user,
        page: 1
      };
    default:
      return state;
  }
};
