//@flow
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import {
  handleFiat,
  handleCrypto,
  handleSymbol,
  intlNumberFormat
} from './utilities/numberFormatting';

import { initReactI18next } from 'react-i18next';
/**
 * @description
 * English
 */
import en_acceptRefund from './locales/en/acceptRefund';
import en_account from './locales/en/account';
import en_assetManagement from './locales/en/assetManagement';
import en_dashboard from './locales/en/dashboard';
import en_ecommercePlugins from './locales/en/ecommercePlugins';
import en_fees from './locales/en/fees';
import en_global from './locales/en/global';
import en_hostedPayments from './locales/en/hostedPayments';
import en_invoices from './locales/en/invoices';
import en_marketing from './locales/en/marketing';
import en_payouts from './locales/en/payouts';
import en_posTerminals from './locales/en/posTerminals';
import en_refunds from './locales/en/refunds';
import en_settings from './locales/en/settings';
import en_support from './locales/en/support';
import en_transactions from './locales/en/transactions';
import en_unauthenticated from './locales/en/unauthenticated';
import en_verification from './locales/en/verification';
import en_merchantDocumentCollection from './locales/en/merchantDocumentCollection';

/**
 * @description
 * French
 */

import fr_global from './locales/fr/global';

/**
 * @description
 * German
 */
import de_acceptRefund from './locales/de/acceptRefund';
import de_account from './locales/de/account';
import de_assetManagement from './locales/de/assetManagement';
import de_dashboard from './locales/de/dashboard';
import de_ecommercePlugins from './locales/de/ecommercePlugins';
import de_fees from './locales/de/fees';
import de_global from './locales/de/global';
import de_hostedPayments from './locales/de/hostedPayments';
import de_invoices from './locales/de/invoices';
import de_marketing from './locales/de/marketing';
import de_payouts from './locales/de/payouts';
import de_posTerminals from './locales/de/posTerminals';
import de_refunds from './locales/de/refunds';
import de_settings from './locales/de/settings';
import de_support from './locales/de/support';
import de_transactions from './locales/de/transactions';
import de_unauthenticated from './locales/de/unauthenticated';
import de_verification from './locales/de/verification';
import de_merchantDocumentCollection from './locales/de/merchantDocumentCollection';

/**
 * @description
 * Spanish
 */
import es_global from './locales/es/global';

const resources = {
  en: {
    acceptRefund: en_acceptRefund,
    account: en_account,
    assetManagement: en_assetManagement,
    dashboard: en_dashboard,
    ecommercePlugins: en_ecommercePlugins,
    fees: en_fees,
    global: en_global,
    hostedPayments: en_hostedPayments,
    invoices: en_invoices,
    marketing: en_marketing,
    merchantDocumentCollection: en_merchantDocumentCollection,
    payouts: en_payouts,
    posTerminals: en_posTerminals,
    refunds: en_refunds,
    settings: en_settings,
    support: en_support,
    transactions: en_transactions,
    unauthenticated: en_unauthenticated,
    verification: en_verification
  },
  fr: {
    global: fr_global
  },
  de: {
    acceptRefund: de_acceptRefund,
    account: de_account,
    assetManagement: de_assetManagement,
    dashboard: de_dashboard,
    ecommercePlugins: de_ecommercePlugins,
    fees: de_fees,
    global: de_global,
    hostedPayments: de_hostedPayments,
    invoices: de_invoices,
    marketing: de_marketing,
    merchantDocumentCollection: de_merchantDocumentCollection,
    payouts: de_payouts,
    posTerminals: de_posTerminals,
    refunds: de_refunds,
    settings: de_settings,
    support: de_support,
    transactions: de_transactions,
    unauthenticated: de_unauthenticated,
    verification: de_verification
  },
  es: { global: es_global }
};

i18n.on('languageChanged', function(lng) {
  moment.locale(lng);
});

const handleDate = (
  value: {
    date: {
      data: string,
      additionalData?: string | { duration: number, durationType: string }
    },
    type: string
  } = {}
) => {
  // init moment locale
  const locale = i18n.language;
  moment.locale(locale);
  const newMoment = () => moment(value.date.data);
  const additionalData = value.date.additionalData;

  switch (value.type) {
    case 'format':
      // $FlowFixMe
      return newMoment().format(additionalData);
    case 'subtract':
    case 'add':
      // $FlowFixMe
      return newMoment()[value.type](
        // $FlowFixMe
        (additionalData.duration, additionalData.durationType)
      );
    case 'months':
      return newMoment().months();
    case 'monthsShort':
      // $FlowFixMe
      return newMoment().monthsShort();
    default:
      return newMoment();
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',

    // keySeparator: false, // we do not use keys in form messages.welcome
    // Only enable logging of missing keys in development
    saveMissing: process.env.NODE_ENV === 'development',
    missingKeyHandler:
      process.env.NODE_ENV === 'development'
        ? (lng, ns, key) => {
            console.warn(`Missing i18n key: ${key}`);
          }
        : undefined, // No missing key handler in production
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, rawFormat, lng) => {
        let formatted;
        switch (rawFormat) {
          case 'uppercase':
            return value.toUpperCase();
          // these should be under same conditional, but might be costly to change
          case 'fiat':
            return handleFiat(value, lng);
          case 'crypto':
            return handleCrypto(value, lng);
          //
          case 'currencySymbol':
            return handleSymbol(value, lng);
          case 'percent':
            formatted = intlNumberFormat({
              lng,
              maxDigits: 2,
              minDigits: 2,
              style: 'percent'
            });
            return formatted.format(value);
          case 'date': //'date'
            return handleDate(value);
          // return moment(value.date).format('dddd');
        }
      }
    }
  });

export const supportedLocale = (locale: string) => {
  for (const language of supportedLanguages) {
    if (language.locale === locale) {
      return language;
    }
  }
};

export const getLocale = () => localStorage.getItem('locale');

export const setLocale = (locale: string) =>
  localStorage.setItem('locale', locale);

export const changeLocale = (locale: string) => i18n.changeLanguage(locale);

export const fallbackLocale = () => 'en';

export const supportedLanguages = [
  /**
   *  @Description For multi-language drop down menu
   *  Make sure to import locale file from moment (top of file) if new language is added
   */

  {
    locale: 'en',
    language: 'English'
  },
  // {
  //   locale: 'fr',
  //   language: 'Français'
  // },
  {
    locale: 'de',
    language: 'Deutsche'
  }
  // {
  //   locale: 'es',
  //   language: 'Español'
  // }
];

export default i18n;
