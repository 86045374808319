export const LOAD_RECIPIENTS = 'LOAD_RECIPIENTS';
export const SET_RECIPIENTS = 'SET_RECIPIENTS';
export const UPDATE_RECIPIENT = 'UPDATE_RECIPIENT';
export const DELETE_RECIPIENT = 'DELETE_RECIPIENT';
export const SET_RECIPIENT_DELETED = 'SET_RECIPIENT_DELETED';
export const ADD_NEW_RECIPIENT = 'ADD_NEW_RECIPIENT';
export const HANDLE_SEARCH_CHANGE = 'recipients/HANDLE_SEARCH_CHANGE';
export const EXPAND_INVOICE_RECIPIENT_ROW = 'EXPAND_INVOICE_RECIPIENT_ROW';
export const LOAD_RECIPIENT = 'LOAD_RECIPIENT';
export const SET_RECIPIENT = 'SET_RECIPIENT';
export const CLEAR_RECIPIENT = 'CLEAR_RECIPIENT';
export const CHANGE_RECIPIENTS_PAGE = 'CHANGE_RECIPIENTS_PAGE';
export const SET_AMOUNT_FILTER = 'RECIPIENTS/SET_AMOUNT_FILTER';

export const loadRecipients = () => ({
  type: LOAD_RECIPIENTS
});

export const setRecipients = recipients => ({
  type: SET_RECIPIENTS,
  recipients
});

export const updateRecipient = recipient => ({
  type: UPDATE_RECIPIENT,
  recipient
});

export const deleteRecipient = recipientId => ({
  type: DELETE_RECIPIENT,
  recipientId
});

export const setRecipientDeleted = id => ({
  type: SET_RECIPIENT_DELETED,
  id
});

export const addNewRecipient = recipient => ({
  type: ADD_NEW_RECIPIENT,
  recipient
});

export const handleSearchChange = searchValue => ({
  type: HANDLE_SEARCH_CHANGE,
  searchValue
});

export const expandInvoiceRecipientRow = id => ({
  type: EXPAND_INVOICE_RECIPIENT_ROW,
  id
});

export const loadRecipient = id => ({
  type: LOAD_RECIPIENT,
  id
});

export const setRecipient = recipient => ({
  type: SET_RECIPIENT,
  recipient
});

export const clearRecipient = () => ({
  type: CLEAR_RECIPIENT
});

export const changeRecipientsPage = pageNumber => ({
  type: CHANGE_RECIPIENTS_PAGE,
  pageNumber
});

export const setAmountFilter = amountFilter => ({
  type: SET_AMOUNT_FILTER,
  amountFilter
});

const initialState = {
  invoice_recipients: [],
  invoice_recipient: null,
  loadingRecipient: true,
  recipients_view: { searchValue: '', expandedRowId: null },
  currentPage: 1,
  amountFilter: 10,
  previouslyVisited: [1],
  searchFilterState: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECIPIENTS:
      return {
        ...state,
        invoice_recipients: action.recipients.invoice_recipients
      };
    case UPDATE_RECIPIENT:
      return {
        ...state,
        invoice_recipients: state.invoice_recipients.map(recipient =>
          recipient.id === action.recipient.id ? action.recipient : recipient
        )
      };
    case SET_RECIPIENT_DELETED:
      return {
        ...state,
        invoice_recipients: state.invoice_recipients.filter(
          recipient => recipient.id !== action.id
        )
      };
    case ADD_NEW_RECIPIENT:
      return {
        ...state,
        invoice_recipients: [...state.invoice_recipients, action.recipient]
      };
    case HANDLE_SEARCH_CHANGE:
      return {
        ...state,
        currentPage: 1,
        previouslyVisited: [1],
        recipients_view: {
          searchValue: action.searchValue
        }
      };
    case EXPAND_INVOICE_RECIPIENT_ROW:
      return {
        ...state,
        recipients_view: {
          ...state.recipients_view,
          expandedRowId: state.expandedRowId === action.id ? null : action.id
        }
      };
    case SET_RECIPIENT:
      return {
        ...state,
        invoice_recipient: action.recipient,
        loadingRecipient: false
      };
    case CLEAR_RECIPIENT:
      return {
        ...state,
        invoice_recipient: null,
        loadingRecipient: true
      };
    case CHANGE_RECIPIENTS_PAGE:
      return {
        ...state,
        currentPage: action.pageNumber,
        previouslyVisited: state.previouslyVisited.includes(action.pageNumber)
          ? state.previouslyVisited
          : [...state.previouslyVisited, action.pageNumber]
      };
    case SET_AMOUNT_FILTER:
      return {
        ...state,
        amountFilter: action.amountFilter
      };

    default:
      return state;
  }
};
