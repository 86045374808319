import styled from 'styled-components/macro';
import CheckboxInput from '../../../components/CheckboxInput';
import SubmitButton from '../../../components/SubmitButton';
import { TextLink } from '../../../components/LinkStyles';

export const ConditionallyApprovedModalForm = styled.form`
  height: 685px;
  width: 620px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    text-transform: unset;
    text-align: center;
    line-height: 32px;
    width: 435px;
  }
  > p {
    margin-top: 15px;
    width: 435px;
    text-align: center;
    line-height: 23px;
  }
  > small {
    width: 435px;
    text-align: center;
    margin-top: 0px;
  }
  @media screen and (max-width: 767px) {
    width: 311px;
    > h2 {
      width: 265px;
      line-height: 23px;
    }
    > p,
    small {
      width: 265px;
      line-height: 20px;
    }
    > small {
      margin-bottom: 0;
    }
  }
`;

export const StyledLottieDiv = styled.div`
  width: 62px;
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  width: 345px;
  > label {
    line-height: 22px;
  }
  @media screen and (max-width: 767px) {
    width: 209px;
  }
`;

export const StyledSubmitButton = styled(SubmitButton)`
  width: 359px;
  height: 59px;
  @media screen and (max-width: 767px) {
    margin-top: 2.5rem;
    width: 203px;
  }
`;

export const StyledTextLink = styled(TextLink)`
  text-decoration: none;
`;

export const ModalTitle = styled.h1`
  width: 265px;
  text-transform: capitalize;
  text-align: center;

  @media (min-width: 768px) {
    width: 475px;
    margin-top: unset;
  }
`;
