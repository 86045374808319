import {
  TOGGLE_EXPANDED_PAYOUTS,
  SET_PAGINATOR_CURRENT,
  SET_PAGINATOR_TOTAL,
  CHANGE_PAGINATOR_PAGE,
  SET_PAYOUTS,
  SET_WINDOW_SIZE,
  SET_CALENDAR_END_FILTER,
  SET_STATUS_FILTER,
  SET_AMOUNT_FILTER,
  SET_TYPE_FILTER,
  PREVIOUSLY_VISITED,
  LOAD_PAYOUTS_ERROR
} from '.';
import { ROUTE_CHANGED } from '../../../App/reducer';

const initialState = {
  payoutsList: [],
  loading: true,
  currentPage: 1,
  previouslyVisited: [1],
  loadPayoutsError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYOUTS:
      return {
        ...state,
        payoutsList: action.payoutsList,
        loading: false
      };
    case TOGGLE_EXPANDED_PAYOUTS:
      return {
        ...state,
        expandedRow:
          action.currentRow === state.expandedRow ? null : action.currentRow
      };
    case SET_PAGINATOR_TOTAL:
      return {
        ...state,
        paginatorTotal: action.paginatorTotal
      };
    case SET_PAGINATOR_CURRENT:
      return {
        ...state,
        currentPage: action.currentPage,
        previouslyVisited: [1]
      };
    case CHANGE_PAGINATOR_PAGE:
      return {
        ...state,
        currentPage: action.pageNumber,
        expandedRow: null,
        previouslyVisited: state.previouslyVisited.includes(action.pageNumber)
          ? state.previouslyVisited
          : [...state.previouslyVisited, action.pageNumber]
      };
    case PREVIOUSLY_VISITED:
      return {
        ...state,
        previouslyVisited: action.previouslyVisited
      };
    case SET_WINDOW_SIZE:
      return {
        ...state,
        windowSize: action.windowSize
      };
    case SET_CALENDAR_END_FILTER:
    case SET_STATUS_FILTER:
    case SET_AMOUNT_FILTER:
    case SET_TYPE_FILTER:
    case ROUTE_CHANGED:
      return {
        ...state,
        expandedRow: null,
        previouslyVisited: [1]
      };
    case LOAD_PAYOUTS_ERROR:
      return {
        ...state,
        loadPayoutsError: true,
        paginatorTotal: null
      };
    default:
      return state;
  }
};
