import { takeLatest, put } from 'redux-saga/effects';
import {
  REFRESH_SESSION,
  setInitialState,
  setUser,
  skipLoad,
  HANDLE_APP_LOAD,
  setTenant,
  SET_REFERRAL_COOKIE,
  setAppConfig,
  SET_LANGUAGE_DATA
} from './reducer';
import jwtDecode from 'jwt-decode';
import { getRequest, handleSagaError } from '../utilities/requests';
import { setToken, getDecodedToken, getToken } from '../utilities/tokenStorage';
import { history } from '../utilities/history';
import axios from 'axios';

const postRefreshSession = () => {
  const url = '/sessions/refresh';
  return getRequest(url);
};

function* handleRefreshSession() {
  try {
    if (!getToken()) {
      return;
    }
    const response = yield postRefreshSession();
    yield handleResponse(response);
  } catch (error) {
    yield put(skipLoad());
    if (error.response) {
      if (error.response.status === 503) {
        // TODO: WHITELABEL - implement maintenance page for tenants (DO NOT REMOVE UNTIL COMPLETED)
        // history.push('/maintenance');
        history.push('/error');
      } else if (error.response.status >= 500) {
        history.push('/error');
      }
    } else {
      history.push('/error');
    }
  }
}

function* handleResponse(response) {
  const token = response.data.jwt;
  const userDetails = jwtDecode(token);
  setToken(token);
  yield put(setUser(userDetails));
  yield put(setInitialState(response.data.initial_state));
}

export function* refreshSession() {
  yield takeLatest(REFRESH_SESSION, handleRefreshSession);
}

function* handleTenantLoad() {
  const host = window.location.hostname;
  const url = '/tenants/show';
  const response = yield axios.get(url, { params: { host } });
  const { tenant } = response.data;
  if (response.data.tenant) {
    let { api_host } = tenant;
    api_host = `https://${api_host}`;
    if (process.env.NODE_ENV === 'development') {
      const devURL = (
        process.env.REACT_APP_AXIOS_BASEURL || 'http://localhost:3001'
      ).replace('http://', '');
      api_host = api_host.replace('localhost', devURL).replace('https', 'http');
    }
    axios.defaults.baseURL = `${api_host}/web/v2`;
    tenant.logo = api_host + tenant.logo;
    tenant.small_logo = api_host + tenant.small_logo;
  }
  yield put(setTenant(tenant));
}

function* loadConfig() {
  try {
    const response = yield axios.get('/config.json', { baseURL: undefined });
    const { baseApiUrl } = response.data;
    axios.defaults.baseURL = `${baseApiUrl}/web/v2`;
    yield put(setAppConfig(response.data));
  } catch (error) {
    history.push('/error');
  }
}

function* handleAppLoad() {
  try {
    yield loadConfig();

    yield* handleTenantLoad();

    const userDetails = getDecodedToken();
    if (userDetails) {
      window.pendo.initialize({
        visitor: {
            id: userDetails.id,
            email: userDetails.email,
            full_name: `${userDetails.first_name} ${userDetails.last_name}`
        },
        account: {
          id: userDetails.merchant_application_id,
          name: userDetails.business_name
        }
      });
      yield handleRefreshSession();
    } else {
      yield put(skipLoad());
    }
  } catch (error) {
    yield put(skipLoad());
    history.push(
      error.response && error.response.status === 503
        ? '/maintenance'
        : '/error'
    );
  }
}

export function* loadAppSaga() {
  yield takeLatest(HANDLE_APP_LOAD, handleAppLoad);
}

function setReferralCookie(action) {
  const now = new Date();
  const twentyYearsFromNow = new Date();
  twentyYearsFromNow.setDate(now.getFullYear() + 20);
  let domain;
  if (window.location.hostname === 'localhost') {
    domain = 'localhost';
  } else {
    domain = '.net-cents.com';
  }
  const cookieString = `nc_referral_code=${encodeURI(
    action.code
  )};path=/;expires=${twentyYearsFromNow.toUTCString()};domain=${domain}`;
  document.cookie = cookieString;
}

export function* referralCookieSaga() {
  yield takeLatest(SET_REFERRAL_COOKIE, setReferralCookie);
}

function* handleSetLocale(action) {
  try {
    const locale = action.languageData.locale;
    axios.defaults.headers.common['Accept-Language'] = locale;
  } catch (e) {
    yield handleSagaError(e);
  }
}

export function* setLocaleSaga() {
  yield takeLatest(SET_LANGUAGE_DATA, handleSetLocale);
}
