// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SignUpDiv, SignUpBackground } from './SignUpStyles';
import Form from './Form';

type Props = {
  companyName: string,
  termsOfServiceUrl: string
};

class SignUp extends Component<Props> {
  render() {
    return (
      <SignUpBackground>
        <SignUpDiv>
          <Form
            companyName={this.props.companyName}
            termsOfServiceUrl={this.props.termsOfServiceUrl}
          />
        </SignUpDiv>
      </SignUpBackground>
    );
  }
}

const mapStateToProps = state => ({
  companyName: state.global.companyName,
  termsOfServiceUrl: state.global.tenant.termsOfServiceUrl
});

export default connect(mapStateToProps)(SignUp);
