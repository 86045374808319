export const LOAD_USAGE_DETAILS = 'AssetManagement/LOAD_USAGE_DETAILS';
export const SET_USAGE_DETAILS = 'AssetManagement/SET_USAGE_DETAILS';
export const SKIP_LOAD_USAGE_DETAILS =
  'AssetManagement/SKIP_LOAD_USAGE_DETAILS';

export const setUsageDetails = payload => ({
  type: SET_USAGE_DETAILS,
  payload
});
export const loadUsageDetails = () => ({
  type: LOAD_USAGE_DETAILS
});
export const skipLoadUsageDetails = () => ({
  type: SKIP_LOAD_USAGE_DETAILS
});

const initialState = {
  currentUsage: {},
  pricingPlans: [],
  loading: true
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USAGE_DETAILS: {
      const activeFeeGroup = action.payload.fee_groups.find(
        group =>
          group.id ===
          action.payload.current_usage.asset_management_fee_group_id
      );
      return {
        ...state,
        currentUsage: action.payload.current_usage,
        pricingPlans: action.payload.fee_groups,
        costPerApiCall: activeFeeGroup.request_overage_fee,
        loading: false
      };
    }
    case SKIP_LOAD_USAGE_DETAILS: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};
