export const SHOW_QUICK_LINKS = 'SHOW_QUICK_LINKS';
export const HIDE_QUICK_LINKS = 'HIDE_QUICK_LINKS';
export const LOAD_CHART_DATA = 'Dashboard/LOAD_CHART_DATA';
export const SET_CHART_DATA = 'Dashboard/SET_CHART_DATA';
export const FAILED_CHART_DATA_LOAD = 'Dashboard/FAILED_CHART_DATA_LOAD';
export const LOAD_DASHBOARD_TRANSACTIONS = 'LOAD_DASHBOARD_TRANSACTIONS';
export const SET_DASHBOARD_TRANSACTIONS = 'SET_DASHBOARD_TRANSACTIONS';
export const TOGGLE_EXPANDED_TRANSACTION =
  'Dashboard/TOGGLE_EXPANDED_TRANSACTION';
export const LOAD_BUSINESS_INSIGHTS = 'LOAD_BUSINESS_INSIGHTS';
export const SET_BUSINESS_INSIGHTS = 'SET_BUSINESS_INSIGHTS';
export const FAILED_DASHBOARD_TRANSACTION_LOAD =
  'FAILED_DASHBOARD_TRANSACTION_LOAD';
export const SET_VISIBLE_TOOLTIP_ID = 'SET_VISIBLE_TOOLTIP_ID';

export const failedDashboardTransactionLoad = () => ({
  type: FAILED_DASHBOARD_TRANSACTION_LOAD
});

export const setBusinessInsights = payload => ({
  type: SET_BUSINESS_INSIGHTS,
  payload
});
export const loadBusinessInsights = () => ({
  type: LOAD_BUSINESS_INSIGHTS
});
export const toggleExpandedTransaction = id => ({
  type: TOGGLE_EXPANDED_TRANSACTION,
  id
});
export const setDashboardTransactions = (transactions, count) => ({
  type: SET_DASHBOARD_TRANSACTIONS,
  transactions,
  count
});
export const loadDashboardTransactions = is_sandbox => ({
  type: LOAD_DASHBOARD_TRANSACTIONS,
  is_sandbox
});
export const failedChartDataLoad = () => ({
  type: FAILED_CHART_DATA_LOAD
});
export const setChartData = data => ({
  type: SET_CHART_DATA,
  data
});
export const loadChartData = () => ({
  type: LOAD_CHART_DATA
});
export const hideQuickLinks = () => ({
  type: HIDE_QUICK_LINKS
});
export const showQuickLinks = () => ({
  type: SHOW_QUICK_LINKS
});

export const setVisibleTooltipId = activeTooltip => ({
  type: SET_VISIBLE_TOOLTIP_ID,
  activeTooltip
});

const initialState = {
  quickLinksVisible: true,
  chartData: null,
  failedChartLoad: false,
  transactions: [],
  transactionsLoading: true,
  payouts: [],
  payoutsLoading: true,
  expandedTransaction: null,
  businessInsights: [],
  activeTooltip: ''
};
export default (state = initialState, action) => {
  switch (action.type) {
    case FAILED_DASHBOARD_TRANSACTION_LOAD:
      return {
        ...state,
        failedTransactionLoad: true
      };
    case SHOW_QUICK_LINKS:
      return {
        ...state,
        quickLinksVisible: true
      };
    case HIDE_QUICK_LINKS:
      return {
        ...state,
        quickLinksVisible: false
      };
    case SET_CHART_DATA:
      return {
        ...state,
        chartData: action.data
      };
    case FAILED_CHART_DATA_LOAD:
      return {
        ...state,
        failedChartLoad: true
      };
    case SET_DASHBOARD_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
        transactionsLoading: false,
        transactionsCount: action.count,
        failedTransactionLoad: false
      };
    case TOGGLE_EXPANDED_TRANSACTION:
      return {
        ...state,
        expandedTransaction:
          action.id === state.expandedTransaction ? null : action.id
      };
    case SET_BUSINESS_INSIGHTS:
      return {
        ...state,
        businessInsights: action.payload
      };
    case SET_VISIBLE_TOOLTIP_ID:
      return {
        ...state,
        activeTooltip: action.activeTooltip
      };
    default:
      return state;
  }
};
