const fonts = {
  //add fonts here
  fontStackHeader: 'font-family: canada-type-gibson, helvetica, sans-serif;',
  fontStackBody: 'font-family: museo-sans, helvetica, sans-serif;'
  //
};
const colors = {
  brand: '#4a90e2', //Passed in through the database
  mariner: '#2379dc',
  denim: '#166bcd',

  purple: '#a957fc',
  blue: '#4a90e2',
  inactiveBlue: '#DDE7F4',
  azure: '#306AB0',
  indigo: '#397BCA',
  biscay: '#192f67',
  black: '#000000',
  USD: '#3c3b6e',
  CAD: '#e12415',
  EUR: '#ffcc00',
  NCCO: '#4a90e2',
  BTC: '#f7931a',
  ETH: '#527698',
  LTC: '#bebebe',
  // XEM: '#00cdbc',
  BCH: '#4cca47',

  red: '#FF3161',
  orange: '#FF7A00',
  // blueBgRed: '#9F0606', // not used in this project
  green: '#00BC7C',
  graphicGreen: '#04F4A2',
  yellow: '#FFBC00',
  cannonPink: '#9C5075',

  //greys
  mercury: '#D9DDE1',
  white: '#ffffff',
  concrete: '#f5f5f6',
  dustyGray: '#878E95',
  dustierGray: '#B0B5BB',
  alto: '#d1d1d1',
  darkGray: '#acacac',
  athensGray: '#F8F8FA',

  //password
  touched: '#ff3161',
  error: '#ffea00',
  warning: '#04f4a2'
};

const fontSize = {
  h8: '1.3rem',
  h9: '1.2rem'

  // h6:
  // h7
};

const dropShadow = {
  defaultDropShadow: 'box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);',
  contentDropShadow: 'box-shadow: rgba(0, 0, 0, 0.77) 0px 0px 8px -4px;',
  topDropShadow: 'box-shadow: 0px -2px 4px rgba(134, 134, 134, 0.36);',
  bottomDropShadow: 'box-shadow: rgba(114, 113, 113, 0.26) 2px 3px 4px 0px;',
  tabletHeaderShadow: 'box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.16);',
  tooltipRectangleArrowUpDropShadow:
    'filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.15));',
  tooltipRectangleDropShadow:
    'filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));',
  sidebarTooltipDropShadow: `filter: drop-shadow(0px 2px 2px ${
    colors.darkGray
  });`
};

const height = {};

const width = {};

export default function theme(extras) {
  let overrides;
  if (extras?.isWhiteLabel) {
    overrides = {
      blue: extras.brand,
      mariner: extras.brand,
      denim: extras.brand,
      inactiveBlue: extras.brand,
      indigo: extras.brand,
      biscay: extras.brand
    };
  }
  return {
    ...fonts,
    ...colors,
    ...fontSize,
    ...dropShadow,
    ...height,
    ...width,
    defaultBorder: `1px solid ${colors.mercury}`,
    ...extras,
    ...overrides
  };
}
