export const SET_DEPOSIT_ADDRESS = 'SET_DEPOSIT_ADDRESS';
export const LOAD_DEPOSIT_ADDRESS = 'LOAD_DEPOSIT_ADDRESS';
export const FAILED_DEPOSIT_ADDRESS_LOAD = 'FAILED_DEPOSIT_ADDRESS_LOAD';

export const failedDepositAddressLoad = currency => ({
  type: FAILED_DEPOSIT_ADDRESS_LOAD,
  currency
});
export const setDepositAddress = (currency, address, destinationTag) => ({
  type: SET_DEPOSIT_ADDRESS,
  currency,
  address,
  destinationTag
});

export const loadDepositAddress = currency => ({
  type: LOAD_DEPOSIT_ADDRESS,
  currency
});

export default (state = {}, action) => {
  switch (action.type) {
    case SET_DEPOSIT_ADDRESS:
      return {
        ...state,
        [action.currency]: {
          address: action.address,
          destinationTag: action.destinationTag
        }
      };
    case LOAD_DEPOSIT_ADDRESS:
      return {
        ...state,
        [action.currency]: undefined
      };
    case FAILED_DEPOSIT_ADDRESS_LOAD:
      return {
        ...state,
        [action.currency]: false
      };
    default:
      return state;
  }
};
