// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { requiredField } from '../../../utilities/validations';
import {
  TwoFactorModalContentDiv,
  EnableEmailTwoFactorForm,
  StyledInputLarge
} from './TwoFactorAuthModalsStyles';
import { showSuccessModal } from '../reducer';
import {
  EnableTwoFactorModalDiv,
  ConfirmationModalButtons,
  ConfirmationModalA
} from '../ModalConductorStyles';
import { defaultSubmitHandler } from '../../../utilities/requests';
import {
  updateUserTwoFactorMethod,
  requestEmailAuthCode
} from '../../../App/Company/reducer';
import { ErrorP } from '../../../components/SaveOverlayStyles';

type Props = {
  closeModal: () => void,
  handleSubmit: (values: any) => void,
  requestEmailAuthCode: () => void,
  error: ?string,
  twoFactorMethod: ?string,
  title: string,
  message: string,
  t: TFunction,
  language: string
};

class DisableTwoFactorAuthModal extends Component<Props> {
  componentDidMount() {
    if (this.props.twoFactorMethod === 'email') {
      this.props.requestEmailAuthCode();
    }
  }
  handleConfirmClick = () => {
    this.props.handleSubmit();
  };
  render() {
    const { t } = this.props;
    return (
      <EnableTwoFactorModalDiv>
        <TwoFactorModalContentDiv>
          <h4>{this.props.title}</h4>
          <p>{this.props.message}</p>
          <EnableEmailTwoFactorForm onSubmit={this.props.handleSubmit}>
            <Field
              name="otp_code"
              hasPlaceholder
              component={StyledInputLarge}
              placeholder="XXXXXX"
              validate={requiredField}
            />
            {this.props.error && <ErrorP>{this.props.error}</ErrorP>}
          </EnableEmailTwoFactorForm>
        </TwoFactorModalContentDiv>
        <ConfirmationModalButtons>
          <ConfirmationModalA
            onClick={this.handleConfirmClick}
            lang={this.props.language}
          >
            {t('global:actions.disable2fa')}
          </ConfirmationModalA>
          <ConfirmationModalA
            onClick={this.props.closeModal}
            lang={this.props.language}
          >
            {t('global:actions.cancel')}
          </ConfirmationModalA>
        </ConfirmationModalButtons>
      </EnableTwoFactorModalDiv>
    );
  }
}

const mapStateToProps = state => ({
  twoFactorMethod: state.global.user.two_factor_method,
  language: state.global.language.activeLanguage.locale
});

const mapDispatchToProps = {
  requestEmailAuthCode,
  updateUserTwoFactorMethod
};

const onSubmitSuccess = (response, dispatch, props) => {
  dispatch(updateUserTwoFactorMethod(response.two_factor_method));
  dispatch(
    showSuccessModal(
      props.t('verification:modal.twoFactorDisabledConifrmation.title'),
      props.t('verification:modal.twoFactorDisabledConifrmation.message')
    )
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'DisableTwoFactorAuthModal',
    onSubmit: defaultSubmitHandler('/two_factor_authentication/deactivate'),
    onSubmitSuccess
  })(withTranslation(['global', 'verification'])(DisableTwoFactorAuthModal))
);
