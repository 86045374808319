import { takeLatest, put } from 'redux-saga/effects';
import { getRequest } from '../../utilities/requests';
import {
  setMerchantDocumentCollection,
  LOAD_MERCHANT_DOCUMENT_COLLECTION
} from './reducer';

function* handleLoadMerchantDocumentCollection() {
  const url = '/merchant_document_collection';
  const response = yield getRequest(url);
  yield put(
    setMerchantDocumentCollection(response.data.merchant_document_collection)
  );
}

export function* loadMerchantDocumentCollectionSaga() {
  yield takeLatest(
    LOAD_MERCHANT_DOCUMENT_COLLECTION,
    handleLoadMerchantDocumentCollection
  );
}
