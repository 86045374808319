import { takeLatest, put } from 'redux-saga/effects';
import { getRequest } from '../../utilities/requests';
import { setSignInErrorMessage, setSignInMessage } from '../SignIn/reducer';
import { history } from '../../utilities/history';
import i18n from '../../i18n';
import { setConfirmedAt } from '../reducer';
import { select } from 'redux-saga/effects';

export const CONFIRM_EMAIL_TOKEN = 'CONFIRM_EMAIL_TOKEN';

export const confirmEmailToken = token => ({
  type: CONFIRM_EMAIL_TOKEN,
  token
});

function* handleConfirmEmailToken(action) {
  const user = yield select(state => state.global.user);
  const isAuthenticated = Boolean(user);
  try {
    const url = `/users/${action.token}/confirm_email`;
    const response = yield getRequest(url);
    yield put(
      setSignInMessage(i18n.t('unauthenticated:notice.emailConfirmed'))
    );
    // For users that are authenticated/signed in, he/she would see an updated dashboard.
    if (isAuthenticated) {
      yield put(setConfirmedAt(response.data.confirmed_at));
      history.push('/');
    } else {
      history.push('/signin');
    }
  } catch (error) {
    if (error.response.status === 500) {
      history.push('/error');
    } else {
      yield put(
        setSignInErrorMessage(i18n.t('unauthenticated:notice.invalidToken'))
      );
    }
  }
}

export function* confirmEmailTokenSaga() {
  yield takeLatest(CONFIRM_EMAIL_TOKEN, handleConfirmEmailToken);
}
