import { store } from '../../../../..';
import i18n from '../../../../../i18n';
import { CONFIRMATION_MODAL } from '../../../../../modules/ModalConductor/modalTypes';
import { SHOW_MODAL } from '../../../../../modules/ModalConductor/reducer';

export const LOAD_RECURRING_INVOICES = 'LOAD_RECURRING_INVOICES';
export const SET_RECURRING_INVOICES = 'SET_RECURRING_INVOICES';
export const CANCEL_RECURRING_BILLING = 'CANCEL_RECURRING_BILLING';
export const EXPAND_RECURRING_ROW = 'EXPAND_RECURRING_ROW';

export const loadRecurringInvoices = () => ({
  type: LOAD_RECURRING_INVOICES
});

export const setRecurringInvoices = payload => ({
  type: SET_RECURRING_INVOICES,
  payload
});

export const cancelRecurringBilling = id => ({
  type: CANCEL_RECURRING_BILLING,
  id
});

export const showCancelRecurringBilling = id => ({
  type: SHOW_MODAL,
  modal: CONFIRMATION_MODAL,
  payload: {
    onConfirm: () => store.dispatch(cancelRecurringBilling(id)),
    title: i18n.t('invoices:modal.cancelRecurringBilling.title'),
    message: i18n.t('invoices:modal.cancelRecurringBilling.message')
  }
});

export const expandRecurringRow = id => ({
  type: EXPAND_RECURRING_ROW,
  id
});

const initialState = {
  recurring_view: { searchValue: '', expandedRowId: null }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECURRING_INVOICES:
      return {
        ...state,
        recurring_invoices: action.payload.recurring_invoice_cycles
      };

    case EXPAND_RECURRING_ROW:
      return {
        ...state,
        recurring_view: {
          ...state.recurring_view,
          expandedRowId:
            state.recurring_view.expandedRowId === action.id ? null : action.id
        }
      };

    default:
      return state;
  }
};
