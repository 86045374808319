import { SET_USER } from '../reducer';

export const SET_SIGN_IN_MESSAGE = 'SET_SIGN_IN_MESSAGE';
export const SET_SIGN_IN_ERROR_MESSAGE = 'SET_SIGN_IN_ERROR_MESSAGE';

export const setSignInErrorMessage = message => ({
  type: SET_SIGN_IN_ERROR_MESSAGE,
  message
});
export const setSignInMessage = message => ({
  type: SET_SIGN_IN_MESSAGE,
  message
});

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SIGN_IN_ERROR_MESSAGE:
      return {
        ...state,
        signInErrorMessage: action.message
      };
    case SET_SIGN_IN_MESSAGE:
      return {
        ...state,
        signInMessage: action.message
      };
    case SET_USER:
      return {
        ...state,
        signInErrorMessage: undefined,
        signInMessage: undefined
      };
    default:
      return state;
  }
};
