import { combineReducers } from 'redux';

import index from './Index/reducer';
// import newInvoice from './New/reducer';
import show from './Show/reducer';

const reducer = combineReducers({
  index,
  //   new: newInvoice,
  show
});

export default reducer;
