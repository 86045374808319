// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import {
  ConfirmationModalButtons,
  ConfirmationModalDiv,
  ConfirmationModalA,
  ConfirmationModalContentDiv
} from './ModalConductorStyles';
export type ConfirmationModalPayload = {
  title: string,
  message: string,
  onConfirm: () => void,
  onCancel?: () => void,
  confirmText?: string,
  cancelText?: string
};
type Props = {
  closeModal: () => void,
  ...$Exact<ConfirmationModalPayload>,
  t: TFunction
};

class ConfirmationModal extends Component<Props> {
  cancel = () => {
    this.props.closeModal();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };
  render() {
    const { t } = this.props;
    return (
      <ConfirmationModalDiv>
        <ConfirmationModalContentDiv>
          <h4>{this.props.title}</h4>
          <p>{this.props.message}</p>
        </ConfirmationModalContentDiv>
        <ConfirmationModalButtons>
          <ConfirmationModalA onClick={this.props.onConfirm}>
            {this.props.confirmText || t('actions.confirm')}
          </ConfirmationModalA>
          <ConfirmationModalA onClick={this.cancel}>
            {this.props.cancelText || t('actions.cancel')}
          </ConfirmationModalA>
        </ConfirmationModalButtons>
      </ConfirmationModalDiv>
    );
  }
}
export default withTranslation(['global'])(ConfirmationModal);
