import { SET_GROUPS } from './groups';

const initialState = {
  loading: true,
  failedLoad: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return {
        ...state,
        loading: false,
        failedLoad: false,
        permissionsDetails: action.permissionsDetails
      };
    default:
      return state;
  }
};
