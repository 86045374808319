// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction, Trans } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import ThankYou from './ThankYou';
import Input from '../../components/Input';
import {
  passwordConfirm,
  passwordValidation,
  passwordWarn,
  requiredField,
  emailValidation,
  phoneNumber
} from '../../utilities/validations';
import CheckboxInput from '../../components/CheckboxInput';
import SubmitButton from '../../components/SubmitButton';
import { SignInP, SignUpH1, SignUpForm } from './SignUpStyles';
import { BrandedStyledLink } from '../../components/LinkStyles';
import { handleSubmit } from './handleSubmit';
import TermsOfServiceField from '../../components/TermsOfServiceField';

type Props = {
  submitSucceeded: boolean,
  error?: string,
  handleSubmit: (values: any) => void,
  submitting: boolean,
  companyName: string,
  termsOfServiceUrl: string,
  t: TFunction
};

class Form extends Component<Props> {
  render() {
    const { t, companyName } = this.props;
    if (this.props.submitSucceeded) {
      return <ThankYou companyName={this.props.companyName} />;
    }
    return (
      <>
        <SignUpH1>{t('signup.form.title', { companyName })}</SignUpH1>
        <SignUpForm onSubmit={this.props.handleSubmit}>
          <Field
            label={t('signup.form.fields.businessName')}
            name="business_name"
            component={Input}
            validate={requiredField}
          />
          <Field
            label={t('signup.form.fields.firstName')}
            name="first_name"
            autocomplete="given-name"
            component={Input}
            validate={requiredField}
          />
          <Field
            label={t('signup.form.fields.lastName')}
            name="last_name"
            autocomplete="family-name"
            component={Input}
            validate={requiredField}
          />
          <Field
            label={t('signup.form.fields.email')}
            name="email"
            type="email"
            autocomplete="email"
            component={Input}
            validate={emailValidation}
          />
          <Field
            label={t('signup.form.fields.phone')}
            name="phone_number"
            type="tel"
            autocomplete="tel"
            component={Input}
            validate={[requiredField, phoneNumber]}
          />
          <Field
            label={t('signup.form.fields.password')}
            name="password"
            type="password"
            autocomplete="new-password"
            validate={passwordValidation}
            warn={passwordWarn}
            passwordValidation
            component={Input}
          />
          <Field
            label={t('signup.form.fields.confirmPassword')}
            name="password_confirmation"
            type="password"
            autocomplete="new-password"
            validate={passwordConfirm}
            component={Input}
          />
          <Field
            label={t('signup.form.fields.promoCode')}
            name="promo_code"
            component={Input}
          />
          <TermsOfServiceField
            termsOfServiceUrl={this.props.termsOfServiceUrl}
          />
          <Field
            label={t('signup.form.fields.specialOffers')}
            name="special_offers"
            component={CheckboxInput}
          />
          <SubmitButton
            label={t('signup.form.fields.createAccount')}
            disabled={this.props.submitting}
            error={this.props.error}
            marginTop="3.5rem"
          />
          <SignInP>
            <Trans t={t} i18nKey="signup.form.existingUserButton">
              Already have an account?{' '}
              <BrandedStyledLink to="/signin">SIGN IN HERE</BrandedStyledLink>
            </Trans>
          </SignInP>
        </SignUpForm>
      </>
    );
  }
}

export default reduxForm({
  form: 'signUp',
  onSubmit: handleSubmit
})(withTranslation(['unauthenticated'])(Form));
