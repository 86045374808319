import { combineReducers } from 'redux';
import filters from './filters';
import view from './view';

const reducer = combineReducers({
  filters,
  view
});

export default reducer;
