export const LOAD_MERCHANT_APPLICATION = 'LOAD_MERCHANT_APPLICATION';
export const SET_MERCHANT_APPLICATION = 'SET_MERCHANT_APPLICATION';

export const setMerchantApplication = (
  merchantApplication,
  processingVolumes
) => ({
  type: SET_MERCHANT_APPLICATION,
  merchantApplication,
  processingVolumes
});
export const loadMerchantApplication = () => ({
  type: LOAD_MERCHANT_APPLICATION
});

const initialState = { loading: true, merchantApplication: {} };
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MERCHANT_APPLICATION:
      return {
        ...state,
        merchantApplication: action.merchantApplication,
        processingVolumes: action.processingVolumes,
        loading: false
      };
    default:
      return state;
  }
};

export const verificationFormReducer = (state = {}, action) => {
  switch (action.type) {
    case '@@redux-form/CHANGE': {
      const { form, field } = action.meta;
      if (form === 'verification') {
        if (field === 'has_website' && action.payload) {
          return {
            ...state,
            values: {
              ...state.values,
              company_detail_attributes: {
                ...state.values.company_detail_attributes,
                website: ''
              }
            },
            fields: {
              ...state.fields
              // company_detail_attributes: {
              //   ...state.fields.company_detail_attributes,
              //   website: {
              //     touched: false
              //   }
              // }
            }
          };
        }
        if (field === 'diff_mailing_address' && action.payload) {
          if (!state.values.mailing_address_attributes) {
            return {
              ...state,
              values: {
                ...state.values,
                mailing_address_attributes: {}
              }
            };
          }

          return {
            ...state,
            values: {
              ...state.values,
              mailing_address_attributes: {
                ...state.values.mailing_address_attributes,
                address_attributes: {
                  ...state.values.mailing_address_attributes.address_attributes,
                  address: '',
                  city: '',
                  country_code: null,
                  id: null,
                  state: null,
                  zip: null
                }
              }
            }
          };
        }
        if (
          field === 'company_detail_attributes.type_of_company' &&
          action.payload === 'Personal'
        ) {
          return {
            ...state,
            submitErrors: { ...state.submitErrors, article: undefined }
          };
        }
        if (field === 'company_detail_attributes.website') {
          return {
            ...state,
            values: {
              ...state.values,
              company_detail_attributes: {
                ...state.values.company_detail_attributes
              },
              has_website: 'yes'
            }
          };
        }
      }
      return state;
    }
    default:
      return state;
  }
};
