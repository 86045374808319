// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import {
  passwordValidation,
  passwordConfirm
} from '../../utilities/validations';
import Input from '../../components/Input';
import SubmitButton from '../../components/SubmitButton';
import { setSignInMessage } from '../SignIn/reducer';
import { history } from '../../utilities/history';
import TermsOfServiceField from '../../components/TermsOfServiceField';
import { AccountSetupP } from './AccountSetupStyles';

type Props = {
  handleSubmit: (values: any) => void,
  error: ?string,
  submitting: boolean,
  termsOfServiceUrl: string,
  t: TFunction
};

class AccountSetupForm extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <h1>{t('accountSetup.form.title')}</h1>
        <AccountSetupP>{t('accountSetup.form.description')}</AccountSetupP>
        <Field
          name="password"
          label={t('accountSetup.form.fields.password')}
          validate={passwordValidation}
          type="password"
          autocomplete="new-password"
          component={Input}
          passwordValidation
        />
        <Field
          name="password_confirmation"
          label={t('accountSetup.form.fields.confirmPassword')}
          validate={passwordConfirm}
          type="password"
          autocomplete="new-password"
          component={Input}
        />
        <TermsOfServiceField termsOfServiceUrl={this.props.termsOfServiceUrl} />
        <SubmitButton
          value={t('accountSetup.form.submitButton')}
          error={this.props.error}
          disabled={this.props.submitting}
          marginTop="3rem"
        />
      </form>
    );
  }
}

const onSubmitSuccess = (result, dispatch, props) => {
  history.push('/signin');
  dispatch(
    setSignInMessage(props.t('unauthenticated:modal.setupCompleteNotice'))
  );
};

export default withTranslation(['unauthenticated'])(
  reduxForm({
    form: 'accountSetupForm',
    onSubmitSuccess
  })(AccountSetupForm)
);
