import { takeLatest, put } from 'redux-saga/effects';
import { history } from '../../utilities/history';
import { setRefundFormData } from './reducer';
import axios from 'axios';

export const ACCEPT_REFUND_TOKEN = 'ACCEPT_REFUND_TOKEN';

export const acceptRefundToken = token => ({
  type: ACCEPT_REFUND_TOKEN,
  token
});

function* handleAcceptRefundToken(action) {
  try {
    const url = '/refund';
    const response = yield axios.get(url, { params: { token: action.token } });
    yield put(setRefundFormData(response.data.refund));
  } catch (error) {
    history.push('/error');
  }
}

export function* acceptRefundTokenSaga() {
  yield takeLatest(ACCEPT_REFUND_TOKEN, handleAcceptRefundToken);
}
