//@flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MobileHeader from './MobileHeader';
import Footer from './Footer';
import { Desktop, Tablet, Mobile, Default } from '../../components/Responsive';
import Sidebar from './Sidebar';
import { DesktopWrapperDiv, LayoutDiv, ContentDiv } from './LayoutStyles';
import SimpleLayout from './SimpleLayout';
import { toggleMobileNavbar, hideTooltip } from '../../App/reducer';
import { closeContentTooltip } from '../../App/reducer';
import netcents from '../../assets/images/logo-netcents.png';
import Tooltip from './Sidebar/Tooltip';
import { type TooltipProps } from './Sidebar';
import { getMerchantApplicationState } from '../../App/Dashboard/selectors';
import InProgressBanner from '../../App/Dashboard/InProgressBanner';

type Props = {
  ...$Exact<TooltipProps>,
  children?: React.Node,
  simple: ?boolean,
  logout: () => void,
  sidebarToggle: boolean,
  location: Location,
  toggleMobileNavbar: () => void,
  mobileNavBarToggle: boolean,
  platformLogo: string,
  closeContentTooltip: ?() => void,
  tooltipContentActive: ?boolean,
  verified: boolean,
  userId: number,
  merchantApplicationState: string,
  hasPayouts: boolean,
  language: string
};

class Layout extends React.Component<Props> {
  hasUnderlay = () =>
    ['account/branding'].some(x => this.props.location.pathname.includes(x));

  handleContentClick = () => {
    if (this.props.mobileNavBarToggle) {
      this.props.toggleMobileNavbar();
    }
    if (this.props.tooltipContentActive && this.props.closeContentTooltip) {
      this.props.closeContentTooltip();
    }
  };

  onSidebarItemLeave = () => {
    if (this.props.tooltip) {
      this.props.hideTooltip();
    }
  };

  render() {
    if (this.props.simple) {
      return (
        <SimpleLayout
          acceptRefundsPage={this.props.location.pathname.includes(
            'refund_confirmation/'
          )}
          logo={this.props.platformLogo || netcents}
          external={this.props.location.pathname.startsWith(
            '/refund_confirmation'
          )}
        >
          {this.props.children}
        </SimpleLayout>
      );
    }
    const refundsCustomUnderlay =
      this.props.location.pathname.includes('refund') &&
      !this.props.location.pathname.endsWith('new');

    return (
      <React.Fragment>
        <Mobile>
          <MobileHeader simple={this.props.simple} logout={this.props.logout} />
        </Mobile>
        <Tablet>{this.props.simple || <Sidebar />}</Tablet>
        <Desktop>
          {this.props.simple || (
            <DesktopWrapperDiv>
              <Sidebar />
            </DesktopWrapperDiv>
          )}
        </Desktop>
        <Default>
          {!this.props.sidebarToggle && this.props.tooltip && (
            <Tooltip tooltip={this.props.tooltip} />
          )}
        </Default>
        <LayoutDiv
          sidebarToggle={this.props.sidebarToggle}
          underlay={this.hasUnderlay()}
          onMouseEnter={this.onSidebarItemLeave}
        >
          <ContentDiv
            sidebarToggle={this.props.sidebarToggle}
            underlay={this.hasUnderlay()}
            onClick={this.handleContentClick}
            refunds={refundsCustomUnderlay}
          >
            {!this.props.location.pathname.endsWith('new') && (
              <InProgressBanner
                verificationState={this.props.merchantApplicationState}
                hasPayouts={this.props.hasPayouts}
                userId={this.props.userId}
                dashboardScreen={this.props.location.pathname.includes(
                  'dashboard'
                )}
                merchantSupportScreen={this.props.location.pathname.includes(
                  'support'
                )}
              />
            )}
            {this.props.children}
          </ContentDiv>
          <Footer
            showFees={this.props.verified}
            language={this.props.language}
          />
        </LayoutDiv>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  platformLogo: state.global.platformLogo,
  mobileNavBarToggle: state.global.mobileNavBarToggle,
  tooltipContentActive: state.global.tooltipContentActive,
  tooltip: state.global.tooltip,
  verified: state.global.user && state.global.user.verified,
  userId: state.global.user && state.global.user.id,
  merchantApplicationState: getMerchantApplicationState(state),
  hasPayouts: state.payouts.payouts.payoutsList.length > 0,
  language: state.global.language.activeLanguage.locale
});

const mapDispatchToProps = {
  toggleMobileNavbar,
  closeContentTooltip,
  hideTooltip
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
