import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import i18n from '../../../i18n';
import {
  DELETE_RECIPIENT,
  setRecipientDeleted,
  LOAD_RECIPIENT,
  setRecipient,
  setRecipients,
  LOAD_RECIPIENTS
} from './reducer';
import {
  deleteRequest,
  handleSagaError,
  getRequest
} from '../../../utilities/requests';
import { showSuccessModal } from '../../../modules/ModalConductor/reducer';
import { history } from '../../../utilities/history';

function* handleDeleteRecipient(action) {
  try {
    const url = `/invoice_recipients/${action.recipientId}`;
    yield deleteRequest(url);
    yield put(setRecipientDeleted(action.recipientId));
    yield put(
      showSuccessModal(
        i18n.t('invoices:modal.deleteRecipientNotice.title'),
        i18n.t('invoices:modal.deleteRecipientNotice.message')
      )
    );
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* deleteRecipient() {
  yield takeEvery(DELETE_RECIPIENT, handleDeleteRecipient);
}

function* handleLoadRecipient(action) {
  try {
    const url = `/invoice_recipients/${action.id}`;
    const response = yield getRequest(url);
    yield put(setRecipient(response.data));
  } catch (error) {
    yield handleSagaError(error);
  }
}

export function* loadRecipient() {
  yield takeEvery(LOAD_RECIPIENT, handleLoadRecipient);
}

function* handleLoadRecipients() {
  try {
    const url = '/invoice_recipients';
    const response = yield getRequest(url);
    yield put(setRecipients(response.data));
  } catch (error) {
    history.push('/error');
  }
}

export function* loadRecipientsSaga() {
  yield takeLatest(LOAD_RECIPIENTS, handleLoadRecipients);
}
