// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { loadMarketingFiles, downloadFile } from './reducer';
import HeaderBlock from './HeaderBlock';
import PageLoader from '../../components/PageLoader';
import { MarketingDiv } from './MarketingStyles';
import MarketingCollateral from './MarketingCollateral';
import { MarketingBody } from './Guides';
import { setActiveMarketingTab } from '../../App/reducer';
export type MarketingFile = {
  name: string,
  url: string,
  file_type: string,
  category: 'crypto_badge' | 'merchant_guide' | 'merchant_collateral',
  description: ?string
};
type Props = {
  loading: boolean,
  loadMarketingFiles: () => void,
  downloadFile: (fileName: string, url: string) => void,
  marketingFiles: MarketingFile[],
  activeMarketingTab: string,
  setActiveMarketingTab: ({
    value: string,
    text: string
  }) => void
};

class Marketing extends Component<Props> {
  componentDidMount() {
    this.props.loadMarketingFiles();
  }

  render() {
    const cryptoBadge = this.props.marketingFiles.find(
      file => file.category === 'crypto_badge'
    );
    const files = this.props.marketingFiles.filter(
      file => file.category === 'merchant_guide'
    );
    const marketingCollateral = this.props.marketingFiles.filter(
      file => file.category === 'merchant_collateral'
    );
    if (this.props.loading) {
      return <PageLoader />;
    }
    return (
      <>
        <HeaderBlock
          activeMarketingTab={this.props.activeMarketingTab}
          setActiveMarketingTab={this.props.setActiveMarketingTab}
        />
        <MarketingDiv>
          <Switch>
            {cryptoBadge && (
              <Route
                path="/marketing"
                exact
                render={routeProps => (
                  <MarketingCollateral
                    {...routeProps}
                    file={cryptoBadge}
                    downloadFile={this.props.downloadFile}
                    marketingCollateral={marketingCollateral}
                  />
                )}
              />
            )}
            <Route
              path="/marketing/guides"
              render={routeProps => (
                <MarketingBody {...routeProps} marketingFiles={files} />
              )}
            />
            <Redirect to="/marketing" />
          </Switch>
        </MarketingDiv>
      </>
    );
  }
}

const mapStateToProps = state => ({
  marketingFiles: state.marketing.marketingFiles,
  loading: state.marketing.loading,
  activeMarketingTab: state.global.activeMarketingTab
});

const mapDispatchToProps = {
  loadMarketingFiles,
  downloadFile,
  setActiveMarketingTab
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Marketing);
