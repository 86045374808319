// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import { requiredField } from '../../../utilities/validations';
import {
  TwoFactorModalContentDiv,
  EnableEmailTwoFactorForm,
  StyledInputLarge
} from './TwoFactorAuthModalsStyles';

import { showSuccessModal } from '../reducer';
import {
  EnableTwoFactorModalDiv,
  ConfirmationModalButtons,
  ConfirmationModalA
} from '../ModalConductorStyles';
import { defaultSubmitHandler } from '../../../utilities/requests';
import { updateUserTwoFactorMethod } from '../../../App/Company/reducer';
import { ErrorP } from '../../../components/SaveOverlayStyles';

type Props = {
  closeModal: () => void,
  handleSubmit: (values: any) => void,
  error: ?string,
  t: TFunction,
  lang: string
};

class EnableEmailTwoFactorAuth extends Component<Props> {
  handleConfirmClick = () => {
    this.props.handleSubmit();
  };
  render() {
    const { t } = this.props;
    return (
      <EnableTwoFactorModalDiv>
        <TwoFactorModalContentDiv>
          <h4>{t('verification:twoFactorAuth.email.title')}</h4>
          <p>{t('verification:twoFactorAuth.email.form.message')}</p>
          <EnableEmailTwoFactorForm onSubmit={this.props.handleSubmit}>
            <Field
              name="otp_code"
              component={StyledInputLarge}
              placeholder="XXXXXX"
              hasPlaceholder
              validate={requiredField}
            />
            {this.props.error && <ErrorP>{this.props.error}</ErrorP>}
          </EnableEmailTwoFactorForm>
        </TwoFactorModalContentDiv>
        <ConfirmationModalButtons>
          <ConfirmationModalA
            onClick={this.handleConfirmClick}
            lang={this.props.lang}
          >
            {t('global:actions.enable2fa')}
          </ConfirmationModalA>
          <ConfirmationModalA
            onClick={this.props.closeModal}
            lang={this.props.lang}
          >
            {t('global:actions.cancel')}
          </ConfirmationModalA>
        </ConfirmationModalButtons>
      </EnableTwoFactorModalDiv>
    );
  }
}

const onSubmitSuccess = (response, dispatch, props) => {
  dispatch(updateUserTwoFactorMethod(response.two_factor_method));
  dispatch(
    showSuccessModal(
      props.t('verification:modal.emailAuthVerificationConfirmation.title'),
      props.t('verification:modal.emailAuthVerificationConfirmation.message'),
      'payment-check'
    )
  );
};

export default reduxForm({
  form: 'EnableEmailTwoFactorAuth',
  onSubmit: defaultSubmitHandler('/two_factor_authentication/enable_2fa'),
  onSubmitSuccess,
  initialValues: { two_factor_method: 'email' }
})(withTranslation(['global', 'verification'])(EnableEmailTwoFactorAuth));
