export const SET_GROUPS = 'SET_GROUPS';
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const FAILED_GROUPS_LOAD = 'FAILED_GROUPS_LOAD';

export const failedGroupsLoad = () => ({
  type: FAILED_GROUPS_LOAD
});
export const loadGroups = () => ({
  type: LOAD_GROUPS
});
export const setGroups = (groups, permissionsDetails) => ({
  type: SET_GROUPS,
  groups,
  permissionsDetails
});

const initialState = [];
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return action.groups;
    default:
      return state;
  }
};
