// @flow
import jwtDecode from 'jwt-decode';
import { type User } from './types';

import { type $AxiosXHR } from 'axios';

export function updateToken(response: $AxiosXHR<{}>) {
  const token = (response.headers || {})['x-token'];
  if (token) {
    setToken(token);
  }
}

export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

export const clearToken = () => {
  localStorage.removeItem('token');
};

export const getToken = (): string => localStorage.getItem('token') || '';

export const getDecodedToken = (): ?User => {
  try {
    const token = getToken();
    const payload = jwtDecode(token);
    const timestamp = Math.round(new Date().getTime() / 1000);
    if (payload.exp > timestamp) {
      return payload;
    }
  } catch (error) {
    //
  }
  clearToken();
};

export const checkToken = () => {
  const token = getToken();
  const payload = jwtDecode(token);
  const timestamp = Math.round(new Date().getTime() / 1000);
  return payload.exp > timestamp;
};
