import { ROUTE_CHANGED } from '../../App/reducer';
import { SET_NORMALIZE_STATE } from '../Refunds/reducer';

export const TOGGLE_EXPANDED_TRANSACTIONS = 'TOGGLE_EXPANDED_TRANSACTIONS';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const SET_DEVICE_FILTER = 'SET_DEVICE_FILTER';
export const SET_SOURCE_FILTER = 'SET_SOURCE_FILTER';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_AMOUNT_FILTER = 'SET_AMOUNT_FILTER';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_CALENDAR_START_FILTER = 'SET_CALENDAR_START_FILTER';
export const SET_CALENDAR_END_FILTER = 'SET_CALENDAR_END_FILTER';
export const CLEAR_CALENDAR_FILTER = 'CLEAR_CALENDAR_FILTER';
export const TOGGLE_SEARCH_FILTER = 'TOGGLE_SEARCH_FILTER';
export const TOGGLE_CALENDAR_FILTER = 'TOGGLE_CALENDAR_FILTER';
export const OPEN_FILTER_TOGGLE = 'OPEN_FILTER_TOGGLE';
export const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE';
export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_CALENDAR_SAGA = 'SET_CALENDAR_SAGA';
export const CLEAR_TRANSACTION_FILTERS = 'CLEAR_TRANSACTION_FILTERS';
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER';
export const SET_PAGINATOR_TOTAL = 'SET_PAGINATOR_TOTAL';
export const SET_PAGINATOR_CURRENT = 'SET_PAGINATOR_CURRENT';
export const CHANGE_PAGINATOR_PAGE = 'CHANGE_PAGINATOR_PAGE';
export const LOAD_PAYOUT_TRANSACTIONS = 'LOAD_PAYOUT_TRANSACTIONS';
// export const SET_PAYOUT_TRANSACTIONS = 'SET_PAYOUT_TRANSACTIONS';
export const REMOVE_PAYOUT_TRANSACTIONS = 'REMOVE_PAYOUT_TRANSACTIONS';
// export const FILTER_TRANSACTIONS_PER_PAGE = 'FILTER_TRANSACTIONS_PER_PAGE';
export const PREVIOUSLY_VISITED = 'PREVIOUSLY_VISITED';
export const MARK_COMPLETE = 'MARK_COMPLETE';
export const DOWNLOAD_TRANSACTIONS_CSV = 'DOWNLOAD_TRANSACTIONS_CSV';
export const TRANSACTIONS_FAILED_LOAD = 'TRANSACTIONS_FAILED_LOAD';
export const REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS';
export const LOAD_WALLET_BALANCES = 'LOAD_WALLET_BALANCES';
export const SET_WALLET_BALANCES = 'SET_WALLET_BALANCES';
export const MARK_COMPLETE_REFUND = 'MARK_COMPLETE_REFUND';
const SET_ACTIVE_TAB = 'transactions/SET_ACTIVE_TAB';
export const SET_VISIBLE_TOOLTIP_ID = 'SET_VISIBLE_TOOLTIP_ID';

export const setActiveTab = activeTab => ({
  type: SET_ACTIVE_TAB,
  activeTab
});
export const markCompleteRefund = id => ({
  type: MARK_COMPLETE_REFUND,
  id
});
export const setWalletBalances = payload => ({
  type: SET_WALLET_BALANCES,
  payload
});
export const loadWalletBalances = () => ({
  type: LOAD_WALLET_BALANCES
});
export const transactionsFailedLoad = () => ({
  type: TRANSACTIONS_FAILED_LOAD
});
export const toggleExpandedTransaction = currentRow => ({
  type: TOGGLE_EXPANDED_TRANSACTIONS,
  currentRow
  // trxnId
});

export const toggleFilter = () => ({
  type: TOGGLE_FILTER
});

export const openFilterToggle = () => ({
  type: OPEN_FILTER_TOGGLE
});

export const setDeviceFilter = deviceFilter => ({
  type: SET_DEVICE_FILTER,
  deviceFilter
});

export const setSourceFilter = sourceFilter => ({
  type: SET_SOURCE_FILTER,
  sourceFilter
});

export const setStatusFilter = statusFilter => ({
  type: SET_STATUS_FILTER,
  statusFilter
});

export const setAmountFilter = amountFilter => ({
  type: SET_AMOUNT_FILTER,
  amountFilter
});

export const setSearchFilter = searchFilter => ({
  type: SET_SEARCH_FILTER,
  searchFilter
});

export const setCalendarStartFilter = calendarStart => ({
  type: SET_CALENDAR_START_FILTER,
  calendarStart
});

export const setCalendarEndFilter = calendarEnd => ({
  type: SET_CALENDAR_END_FILTER,
  calendarEnd
});

export const clearCalendarFilter = () => ({
  type: CLEAR_CALENDAR_FILTER
});

export const toggleSearchFilter = () => ({
  type: TOGGLE_SEARCH_FILTER
});

export const toggleCalendarFilter = () => ({
  type: TOGGLE_CALENDAR_FILTER
});

export const setWindowSize = windowSize => ({
  type: SET_WINDOW_SIZE,
  windowSize
});

export const loadTransactions = deviceId => ({
  type: LOAD_TRANSACTIONS,
  deviceId
});

export const loadPayoutTransactions = payoutId => ({
  type: LOAD_PAYOUT_TRANSACTIONS,
  payoutId
});

export const setTransactions = transactionList => ({
  type: SET_TRANSACTIONS,
  transactionList
});

// export const setPayoutTransactions = transactionList => ({
//   type: SET_PAYOUT_TRANSACTIONS,
//   transactionList
// });

export const setCalendarSaga = () => ({
  type: SET_CALENDAR_SAGA
});

export const clearSearchFilter = () => ({
  type: CLEAR_SEARCH_FILTER
});

export const setPaginatorTotal = paginatorTotal => ({
  type: SET_PAGINATOR_TOTAL,
  paginatorTotal
});

export const setPaginatorCurrent = currentPage => ({
  type: SET_PAGINATOR_CURRENT,
  currentPage
});

export const changePage = pageNumber => ({
  type: CHANGE_PAGINATOR_PAGE,
  pageNumber
});

export const removePayoutTransactions = () => ({
  type: REMOVE_PAYOUT_TRANSACTIONS
});

export const markComplete = id => ({
  type: MARK_COMPLETE,
  id
});

export const downloadTransactionsCsv = () => ({
  type: DOWNLOAD_TRANSACTIONS_CSV,
  downloadCsv: true
});

export const refundInProgress = id => ({
  type: REFUND_IN_PROGRESS,
  id
});

export const setVisibleTooltipId = activeTooltip => ({
  type: SET_VISIBLE_TOOLTIP_ID,
  activeTooltip
});

const toggleFromPath = path => {
  const match = path.match(/\/transactions(\/.+)?/i);
  if (match) {
    let toggle = 'transactions';
    if (match[1] === '/action') {
      toggle = 'action required';
    } else if (match[1] === '/sandbox') {
      toggle = 'sandbox';
    }
    return toggle;
  }
};

const initialState = {
  toggle: toggleFromPath(window.location.pathname),
  toggleFilterState: true,
  sourceFilter: undefined,
  deviceFilter: undefined,
  statusFilter: undefined,
  amountFilter: 10,
  // calendarFilter: all dates!!!
  searchFilterState: false,
  calendarFilterState: false,
  walletBalances: undefined,

  calendarStart: null,
  calendarEnd: null,
  windowSize: 'desktop',
  transactionList: [],
  loading: true,
  currentPage: 1,
  previouslyVisited: [1]
  // refundState: null
};

const displayActiveFilter = (state, key) => ({
  ...state,
  searchFilterState: false,
  calendarFilterState: false,
  [key]: !state[key],
  activeTooltip: ''
});

// const clearTransactions = state => ({
//   ...state,
//   sourceFilter: { text: 'Source', value: 'Source' },
//   deviceFilter: { text: 'Device', value: 'Device' },
//   statusFilter: { text: 'Status', value: 'Status' },
//   amountFilter: { text: 10, value: 10 },
//   calendarStart: null,
//   calendarEnd: null
// });

export default (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_CHANGED: {
      return {
        ...state,
        sourceFilter: undefined,
        deviceFilter: undefined,
        statusFilter: undefined,
        // amountFilter: { text: 10, value: 10 },
        calendarStart: null,
        calendarEnd: null,
        currentPage: 1,
        previouslyVisited: [1]
        // payoutId: null
      };
    }
    case TRANSACTIONS_FAILED_LOAD:
      return {
        ...state,
        failedLoad: true
      };
    case LOAD_TRANSACTIONS: {
      return {
        ...state,
        deviceFilter: action.deviceId
      };
    }
    case TOGGLE_EXPANDED_TRANSACTIONS:
      return {
        ...state,
        expandedRow:
          action.currentRow === state.expandedRow ? null : action.currentRow
        // trxnId: action.trxnId === state.expandedRow ? null : action.trxnId
      };
    case SET_NORMALIZE_STATE:
      return {
        ...state,
        expandedRow: null
      };
    case TOGGLE_FILTER:
      return {
        ...state,
        toggleFilterState: !state.toggleFilterState
      };
    case OPEN_FILTER_TOGGLE:
      return {
        ...state,
        toggleFilterState: true
      };
    case SET_SOURCE_FILTER:
      return {
        ...state,
        sourceFilter: action.sourceFilter,
        expandedRow: null,
        currentPage: 1,
        previouslyVisited: [1]
      };
    case SET_DEVICE_FILTER:
      return {
        ...state,
        deviceFilter: action.deviceFilter,
        expandedRow: null,
        currentPage: 1,
        previouslyVisited: [1]
      };
    case SET_STATUS_FILTER:
      return {
        ...state,
        statusFilter: action.statusFilter,
        expandedRow: null,
        currentPage: 1,
        previouslyVisited: [1]
      };
    case SET_AMOUNT_FILTER:
      return {
        ...state,
        amountFilter: action.amountFilter,
        expandedRow: null,
        previouslyVisited: [1],
        currentPage: 1
      };
    case SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.searchFilter,
        expandedRow: null,
        currentPage: 1,
        previouslyVisited: [1]
      };
    case SET_CALENDAR_START_FILTER:
      return {
        ...state,
        calendarStart: action.calendarStart
      };
    case SET_CALENDAR_END_FILTER:
      return {
        ...state,
        calendarEnd: action.calendarEnd,
        expandedRow: null,
        currentPage: 1,
        previouslyVisited: [1]
      };
    case CLEAR_CALENDAR_FILTER:
      return {
        ...state,
        currentPage: 1,
        previouslyVisited: [1],
        calendarStart: null,
        calendarEnd: null,
        calendarFilterState: false
      };
    case TOGGLE_SEARCH_FILTER:
      return displayActiveFilter(state, 'searchFilterState');
    case TOGGLE_CALENDAR_FILTER:
      return displayActiveFilter(state, 'calendarFilterState');
    case SET_WINDOW_SIZE:
      return {
        ...state,
        windowSize: action.windowSize
      };
    // case SET_PAYOUT_TRANSACTIONS:
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactionList: action.transactionList,
        loading: false,
        failedLoad: false
      };
    case CLEAR_TRANSACTION_FILTERS:
      return {
        ...state,
        sourceFilter: undefined,
        deviceFilter: undefined,
        statusFilter: undefined,
        // amountFilter: { text: 10, value: 10 },
        calendarStart: null,
        calendarEnd: null
        // payoutId: null
      };
    case REMOVE_PAYOUT_TRANSACTIONS:
      return {
        ...state,
        sourceFilter: undefined,
        deviceFilter: undefined,
        statusFilter: undefined,
        // amountFilter: { text: 10, value: 10 },
        calendarStart: null,
        calendarEnd: null,
        payoutId: null
      };
    case CLEAR_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: ''
      };
    case SET_PAGINATOR_TOTAL:
      return {
        ...state,
        paginatorTotal: action.paginatorTotal
      };
    case SET_PAGINATOR_CURRENT:
      return {
        ...state,
        currentPage: action.currentPage,
        previouslyVisited: [1]
      };
    case CHANGE_PAGINATOR_PAGE:
      return {
        ...state,
        currentPage: action.pageNumber,
        expandedRow: null,
        previouslyVisited: state.previouslyVisited.includes(action.pageNumber)
          ? state.previouslyVisited
          : [...state.previouslyVisited, action.pageNumber]
      };
    case PREVIOUSLY_VISITED:
      return {
        ...state,
        previouslyVisited: action.previouslyVisited
      };
    case LOAD_PAYOUT_TRANSACTIONS:
      return {
        ...state,
        payoutId: action.payoutId,
        loading: true
      };
    case SET_WALLET_BALANCES:
      return {
        ...state,
        walletBalances: action.payload.wallet_balances,
        instantWalletBalance: action.payload.instant_wallet_balance
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case SET_VISIBLE_TOOLTIP_ID:
      return {
        ...state,
        activeTooltip: action.activeTooltip
      };
    default:
      return state;
  }
};
