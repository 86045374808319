import {
  SET_NEW_PAYOUT_FORM_DATA,
  SET_PAYOUT_OPTION,
  SET_OTHER_WALLET_TYPE,
  LOAD_PAYOUT_FORM_DATA
} from '.';

const initialState = {
  newPayout: {},
  loading: true,
  payoutOption: '',
  otherWallet: 'false'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_PAYOUT_FORM_DATA:
      return {
        ...state,
        newPayout: action.payload,
        loading: false
      };
    case SET_PAYOUT_OPTION:
      return {
        ...state,
        payoutOption:
          action.payoutOption === state.payoutOption
            ? state.payoutOption
            : action.payoutOption
      };
    case SET_OTHER_WALLET_TYPE:
      return {
        ...state,
        otherWallet: action.value
      };
    case LOAD_PAYOUT_FORM_DATA:
      return initialState;
    default:
      return state;
  }
};
