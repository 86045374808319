//@flow
import React from 'react';
import { withTranslation, TFunction } from 'react-i18next';
import errorGirl from '../../assets/images/404-vector-girl.png';
import ErrorPage from './ErrorPage';

type Props = { t: TFunction };
class Error404 extends React.Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <ErrorPage
        errorGirl={errorGirl}
        title={t('errorPages.404.title')}
        subTitle={t('errorPages.404.subtitle')}
      />
    );
  }
}

export default withTranslation(['unauthenticated'])(Error404);
