import styled, { css } from 'styled-components/macro';
import { arrowLeft } from '../../../styles/utils';
import Icon from '../../../components/Icon';

const SidebarIcon = css`
  width: 20px;
  height: 20px;
  fill: ${props => props.theme.black};
  ${props =>
    props.active &&
    css`
      fill: ${props => props.theme.white};
    `}
`;

export const DashboardIcon = styled(Icon)`
  ${SidebarIcon};
`;
export const TransactionsIcon = styled(Icon)`
  ${SidebarIcon};
`;
export const PayoutsIcon = styled(Icon)`
  ${SidebarIcon};
`;
export const WebPluginIcon = styled(Icon)`
  ${SidebarIcon};
`;
export const HostedPaymentsIcon = styled(Icon)`
  ${SidebarIcon};
`;
export const DevicesIcon = styled(Icon)`
  ${SidebarIcon};
`;
export const CreateInvoiceIcon = styled(Icon)`
  ${SidebarIcon};
`;
export const AssetManagementIcon = styled(Icon)`
  ${SidebarIcon};
`;

export const NavItemWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: ${props => (props.opened ? 'flex-start' : 'center')};
  align-items: center;
  ${props => props.opened && 'margin-left: 30px'};
  > h5 {
    margin-left: 15px;
    color: ${props => props.active && 'white'};
    font-weight: 400;
    /* &:hover {
      color: ${props => props.theme.brand};
    } */
  }
`;

export const NavItemContainer = styled.div`
  position: relative;
  background-color: ${props => props.active && props.theme.brand};
  height: 58px;
  ${props =>
    props.opened &&
    css`
      display: flex;
      align-items: center;
    `}

  :hover {
    > div {
      > svg {
        ${props =>
          !props.active &&
          css`
            transition: fill 0.1s ease-in;
            fill: ${props => props.theme.brand};
          `}
      }
    }
  }
`;

export const TooltipDiv = styled.div`
  background-color: ${props => props.theme.athensGray};
  ${props => props.theme.sidebarTooltipDropShadow};
  left: calc(${props => props.tooltip.left}px + 60px);
  width: 170px;
  height: 55px;
  ${props => arrowLeft({ size: 8, color: props.theme.athensGray, left: '1%' })};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  position: fixed;

  top: calc(${props => props.tooltip.top}px + 101px);
  @media screen and (min-width: 1024px) {
    top: calc(${props => props.tooltip.top}px + 90px);
  }
  > h6 {
    ${props => props.theme.fontStackHeader};
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0.05em;
  }
`;
