// @flow

import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { withTranslation, TFunction } from 'react-i18next';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';
import Layout from '../modules/Layout';
import {
  setUser,
  logout,
  routeChanged,
  handleAppLoad,
  setUserType,
  refreshSession,
  setReferralCookie,
  setLanguageData
} from './reducer';
import { type Permissions, type User } from '../utilities/types';
import Navbar from '../modules/Navbar';
import AuthRoute from '../components/AuthRoute';
import ModalConductor from '../modules/ModalConductor';
import { ThemeProvider } from 'styled-components/macro';
import GlobalStyles from '../globalStyles';
import Fonts from '../styles/fonts';
import theme from '../styles/theme';
import PageLoader from '../components/PageLoader';
import NoAuthRoute from '../components/NoAuthRoute';
import ResetPassword from './ResetPassword';
import Error404 from '../components/ErrorPages/Error404';
import Error500 from '../components/ErrorPages/Error500';
import Maintenance from '../components/ErrorPages/Maintenance';
import { hasSettingsAccess } from '../utilities/misc';
import tenantFavicon from '../assets/images/tenantFavicon.png';
import ResendConfirmation from './ResendConfirmation';
import SignIn from './SignIn';
import ConfirmEmail from './ConfirmEmail';
import VerifyAuth from './VerifyAuth';
import Marketing from './Marketing';
import AccountSetup from './AccountSetup';
import SignUp from './SignUp';
import { getMerchantApplicationState } from './Dashboard/selectors';
import { CONDITIONALLY_APPROVED_MODAL } from '../modules/ModalConductor/modalTypes';
import { showModal } from '../modules/ModalConductor/reducer';
import {
  getLocale,
  fallbackLocale,
  supportedLocale,
  changeLocale,
  setLocale
} from '../i18n';
const Verification = React.lazy(() => import('./Verification'));
const MerchantDocumentCollection = React.lazy(() =>
  import('./MerchantDocumentCollection')
);
const Dashboard = React.lazy(() => import('./Dashboard'));
const Fees = React.lazy(() => import('./Fees'));
const HostedPayments = React.lazy(() => import('./HostedPayments'));
const PosTerminals = React.lazy(() => import('./PosTerminals'));
const Transactions = React.lazy(() => import('./Transactions'));
const Payouts = React.lazy(() => import('./Payouts'));
const EcommercePlugins = React.lazy(() => import('./EcommercePlugins'));
const Invoices = React.lazy(() => import('./Invoices'));
const Settings = React.lazy(() => import('./Settings'));
const Account = React.lazy(() => import('./Account'));
const MerchantSupport = React.lazy(() => import('./MerchantSupport'));
const Refunds = React.lazy(() => import('./Refunds'));
const AssetManagement = React.lazy(() => import('./AssetManagement'));
const AcceptRefund = React.lazy(() => import('./AcceptRefund'));

type Props = {
  location: Location,
  user: ?User,
  sidebarToggle: boolean,
  logout: () => void,
  loading: boolean,
  permissions: Permissions,
  routeChanged: (prevRoute: string, route: string) => void,
  handleAppLoad: () => void,
  brandColor: ?string,
  setUserType: (value: string) => void,
  companyName: string,
  refreshSession: () => void,
  isWhiteLabel: boolean,
  setReferralCookie: (code: string) => void,
  portalBaseUrl: string,
  showModal: string => void,
  hasAcknowledgedConditionalApproval: boolean,
  merchantApplicationState: string,
  t: TFunction,
  setLanguageData: ({ locale: string, language: string }) => void
};

class App extends Component<Props> {
  simpleLayoutRoutes = [
    '/verification',
    '/upload-documents',
    '/signin',
    '/sign-up',
    '/users',
    '/password_resets',
    '/resend_confirmation',
    '/page_not_found',
    '/error',
    '/maintenance',
    '/refund_confirmation',
    '/account_setup',
    '/verify_auth'
  ];

  componentDidMount() {
    this.handleUserAgent();
    this.props.handleAppLoad();
    this.handleLocale();
    const params = this.props.location.search;
    const code = new URLSearchParams(params).get('code');
    if (code) {
      this.props.setReferralCookie(code);
    }
    setInterval(() => {
      this.props.refreshSession();
    }, 5 * 60 * 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const basePath = this.props.location.pathname.split('/')[1];
      const prevBasePath = prevProps.location.pathname.split('/')[1];
      if (
        basePath !== prevBasePath ||
        this.props.location.pathname.endsWith('new')
      ) {
        window.scrollTo(0, 0);
      }
      if (prevBasePath === 'pos-terminals' && basePath === 'transactions')
        return;
      this.props.routeChanged(
        prevProps.location.pathname,
        this.props.location.pathname
      );
    }
  }

  handleUserAgent = () => {
    if (navigator.userAgent.indexOf('Mobile') !== -1) {
      this.props.setUserType('mobile');
    }
  };
  renderAcknowledgedConditionalApprovalModal = (
    requiresConditionalApprovalAcknowledgement: boolean
  ) => {
    const errorPage = ['error', 'maintenance', 'page_not_found'].some(ext =>
      this.props.location.pathname.includes(ext)
    );
    if (requiresConditionalApprovalAcknowledgement && !errorPage) {
      this.props.showModal(CONDITIONALLY_APPROVED_MODAL);
    }
  };

  handleLocale = () => {
    const localStorageLocale = getLocale();
    if (localStorageLocale) {
      return this.isLocaleSupported(localStorageLocale);
    }
    if (navigator.languages) {
      const parsedLocale = navigator.languages[0].split(/-|_/)[0];
      if (this.isLocaleSupported(parsedLocale)) {
        return setLocale(parsedLocale);
      }
    } else {
      this.fallbackLocale();
    }
  };

  isLocaleSupported = locale => {
    const languageObj = supportedLocale(locale);

    if (languageObj) {
      changeLocale(languageObj.locale);
      this.props.setLanguageData(languageObj);
      return true;
    }
  };

  fallbackLocale = () => {
    this.isLocaleSupported(fallbackLocale());
    setLocale(fallbackLocale());
  };

  handleRender = () => {
    const { permissions, user, companyName, t } = this.props;
    const isAuthenticated = Boolean(user);

    const simple = this.simpleLayoutRoutes.some(route =>
      this.props.location.pathname.startsWith(route)
    );
    const assetManagementEnabled = Boolean(
      user && user.asset_management_enabled
    );
    const refundsEnabled = Boolean(user && user.refunds_enabled);
    const verified = Boolean(user && user.verified);
    const requiresConditionalApprovalAcknowledgement =
      !this.props.hasAcknowledgedConditionalApproval &&
      this.props.merchantApplicationState === 'company_details_approved';

    this.renderAcknowledgedConditionalApprovalModal(
      requiresConditionalApprovalAcknowledgement
    );

    return (
      <>
        <GlobalStyles />
        <Fonts />
        <Helmet>
          <title>{t('appTitle', { companyName })}</title>
        </Helmet>
        {this.props.isWhiteLabel && <Favicon url={tenantFavicon} />}
        <Layout
          simple={simple}
          sidebarToggle={this.props.sidebarToggle}
          logout={this.props.logout}
        >
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <AuthRoute
                exact
                path="/"
                component={Dashboard}
                isAuthenticated={isAuthenticated}
              />
              <NoAuthRoute
                exact
                path="/signin"
                component={SignIn}
                isAuthenticated={isAuthenticated}
              />
              <NoAuthRoute
                exact
                path="/sign-up"
                component={
                  this.props.isWhiteLabel
                    ? SignUp
                    : () => {
                        window.location.href = `${
                          this.props.portalBaseUrl
                        }/business/register`;
                        return null;
                      }
                }
                isAuthenticated={isAuthenticated}
              />
              {/* Changed from NoAuthRoute to just Route to handle cases when user's have logged in but not yet confirmed their email and also when they are not logged in.  */}
              <Route
                exact
                path="/users/:token/confirm_email"
                component={ConfirmEmail}
              />
              <NoAuthRoute
                exact
                path="/verify_auth/:token"
                component={VerifyAuth}
                isAuthenticated={isAuthenticated}
              />
              <NoAuthRoute
                path="/password_resets"
                component={ResetPassword}
                isAuthenticated={isAuthenticated}
              />
              <NoAuthRoute
                path="/account_setup/:token"
                component={AccountSetup}
                isAuthenticated={isAuthenticated}
              />
              <NoAuthRoute
                path="/resend_confirmation"
                component={ResendConfirmation}
                isAuthenticated={isAuthenticated}
              />
              <AuthRoute
                exact
                path="/verification/:step?"
                component={Verification}
                isAuthenticated={isAuthenticated}
              />
              <AuthRoute
                path="/transactions"
                component={Transactions}
                isAuthenticated={isAuthenticated}
                isAuthorized={
                  permissions &&
                  (permissions.transaction.read ||
                    permissions.sandbox_transaction.read)
                }
              />
              <AuthRoute
                path="/pos-terminals"
                component={PosTerminals}
                isAuthenticated={isAuthenticated}
                isAuthorized={permissions && permissions.device_user.read}
              />
              <AuthRoute
                path="/hosted-payments"
                component={HostedPayments}
                isAuthenticated={isAuthenticated}
                isAuthorized={
                  permissions && permissions.hosted_payments_plugin.read
                }
              />

              <AuthRoute
                path="/payouts"
                component={Payouts}
                isAuthenticated={isAuthenticated}
                isAuthorized={permissions && permissions.payout.read}
              />
              <AuthRoute
                path="/support"
                component={MerchantSupport}
                isAuthenticated={isAuthenticated}
              />
              <AuthRoute
                path="/settings"
                component={Settings}
                isAuthenticated={isAuthenticated}
                isAuthorized={permissions && hasSettingsAccess(permissions)}
              />
              <AuthRoute
                path="/account"
                component={Account}
                isAuthenticated={isAuthenticated}
              />
              <AuthRoute
                path="/marketing"
                component={Marketing}
                isAuthenticated={isAuthenticated}
              />
              <AuthRoute
                path="/fees"
                component={Fees}
                isAuthenticated={isAuthenticated && verified}
              />
              <AuthRoute
                path="/ecommerce-plugins"
                component={EcommercePlugins}
                isAuthenticated={isAuthenticated}
                isAuthorized={permissions && permissions.ecommerce_plugin.read}
              />
              <AuthRoute
                path="/asset-management"
                component={AssetManagement}
                isAuthenticated={isAuthenticated}
                isAuthorized={
                  permissions &&
                  assetManagementEnabled &&
                  verified &&
                  permissions.asset_transaction.read
                }
              />
              <AuthRoute
                path="/invoices"
                component={Invoices}
                isAuthenticated={isAuthenticated}
                isAuthorized={permissions && permissions.invoice.read}
              />
              <AuthRoute
                path="/refunds"
                component={Refunds}
                isAuthenticated={isAuthenticated}
                isAuthorized={
                  permissions && refundsEnabled && permissions.refund.read
                }
              />
              <Route
                path="/refund_confirmation/:token"
                component={AcceptRefund}
              />
              <AuthRoute
                exact
                path="/upload-documents/:step?"
                component={MerchantDocumentCollection}
                isAuthenticated={isAuthenticated}
              />
              <Route path="/error" component={Error500} />
              <Route path="/maintenance" component={Maintenance} />
              <Route path="/page_not_found" component={Error404} />
              <Redirect to="/page_not_found" />
            </Switch>
          </Suspense>
        </Layout>
        <Navbar simple={simple} />
        <ModalConductor
          preventClose={requiresConditionalApprovalAcknowledgement}
        />
      </>
    );
  };

  render() {
    return (
      <ThemeProvider
        theme={theme({
          brand: this.props.brandColor,
          sidebarOpened: this.props.sidebarToggle,
          isWhiteLabel: this.props.isWhiteLabel
        })}
      >
        {this.props.loading ? <PageLoader /> : this.handleRender()}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  merchantApplicationState: getMerchantApplicationState(state),
  hasAcknowledgedConditionalApproval: (state.global.user || {})
    .acknowledged_conditional_approval,
  user: state.global.user,
  sidebarToggle: state.global.sidebarToggle,
  loading: state.global.loading,
  permissions: state.global.permissions,
  companyName: state.global.companyName,
  isWhiteLabel: state.global.isWhiteLabel,
  brandColor: state.global.primaryBrandColor,
  portalBaseUrl: state.global.config.portalBaseUrl
});

const mapDispatchToProps = {
  setUser,
  logout,
  routeChanged,
  handleAppLoad,
  refreshSession,
  setUserType,
  setReferralCookie,
  showModal,
  setLanguageData
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation(['global'])(App))
);
