//@flow
import React from 'react';
import {
  DashboardIcon,
  PayoutsIcon,
  WebPluginIcon,
  HostedPaymentsIcon,
  CreateInvoiceIcon,
  DevicesIcon,
  AssetManagementIcon,
  TransactionsIcon
} from '../modules/Layout/Sidebar/SidebarItemStyles';

export function navIcon(name: string, active?: boolean) {
  switch (name) {
    case 'dashboard':
      return <DashboardIcon name="home" active={active} />;
    case 'transactions':
      return <TransactionsIcon name="transactions" active={active} />;
    case 'payouts':
      return <PayoutsIcon name="payouts" active={active} />;
    case 'ecommerce plugins':
      return <WebPluginIcon name="cart" active={active} />;
    case 'hosted payments':
      return <HostedPaymentsIcon name="browser" active={active} />;
    case 'pos terminals':
      return <DevicesIcon name="devices" active={active} />;
    case 'invoices':
      return <CreateInvoiceIcon name="invoice" active={active} />;
    case 'asset management':
      return <AssetManagementIcon name="pieChart" active={active} />;
    default:
  }
}
