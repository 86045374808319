// @flow
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { withTranslation, TFunction, Trans } from 'react-i18next';
import { TextLink } from './LinkStyles';
import CheckboxInput from './CheckboxInput';
import { requiredField } from '../utilities/validations';

type Props = {
  termsOfServiceUrl: string,
  t: TFunction
};

class TermsOfServiceField extends Component<Props> {
  render() {
    return (
      <Field
        renderLabel={() => (
          <span>
            <Trans t={this.props.t} i18nKey="additionalComponents.terms">
              I acknowledge that I have read and understood these{' '}
              <TextLink
                href={this.props.termsOfServiceUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms and Conditions
              </TextLink>
              , and I agree to be bound by them.
            </Trans>
          </span>
        )}
        name="terms_of_service"
        validate={requiredField}
        component={CheckboxInput}
      />
    );
  }
}
export default withTranslation(['unauthenticated'])(TermsOfServiceField);
