import { ADD_DEVICE, UPDATE_DEVICE, SET_DEVICE_DELETED } from '.';
import { SET_INITIAL_STATE } from '../../reducer';

const initialState = [];
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return action.payload.devices;
    case ADD_DEVICE:
      return [...state, action.device];
    case UPDATE_DEVICE:
      return state.map(device =>
        device.id === action.device.id ? action.device : device
      );
    case SET_DEVICE_DELETED:
      return state.filter(device => device.id !== action.id);
    default:
      return state;
  }
};
