import { takeLatest, put } from 'redux-saga/effects';
import { getRequest } from '../../utilities/requests';
import { history } from '../../utilities/history';
import { LOAD_ASSET_BALANCES, setAssetBalances } from './reducer/balances';
import {
  setDepositAddress,
  LOAD_DEPOSIT_ADDRESS,
  failedDepositAddressLoad
} from './reducer/addresses';
import { setUsageDetails, LOAD_USAGE_DETAILS } from './reducer/usage';

function* handleLoadBalances() {
  const url = '/asset_management';
  try {
    const response = yield getRequest(url);
    const assets = response.data.asset_balances.reduce((acc, asset) => {
      acc[asset.currency] = asset;
      return acc;
    }, {});
    yield put(setAssetBalances(assets));
  } catch (error) {
    // yield put(failedUsersLoad());
  }
}

export function* loadAssetBalancesSaga() {
  yield takeLatest(LOAD_ASSET_BALANCES, handleLoadBalances);
}

function* handleLoadAddress(action) {
  const url = `/asset_management/deposit_create?currency=${action.currency}`;
  try {
    const response = yield getRequest(url);
    yield put(
      setDepositAddress(
        response.data.currency,
        response.data.deposit_address,
        response.data.destination_tag
      )
    );
  } catch (error) {
    yield put(failedDepositAddressLoad(action.currency));
  }
}

export function* loadDepositAddressSaga() {
  yield takeLatest(LOAD_DEPOSIT_ADDRESS, handleLoadAddress);
}

function* handleLoadUsage() {
  const url = '/asset_management/usage';
  try {
    const response = yield getRequest(url);
    yield put(setUsageDetails(response.data));
  } catch (error) {
    history.push('/error');
  }
}

export function* loadUsageDetailsSaga() {
  yield takeLatest(LOAD_USAGE_DETAILS, handleLoadUsage);
}
