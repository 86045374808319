import styled, { css } from 'styled-components/macro';
import {
  outlineButtonStyles,
  PrimaryButtonLink
} from '../../components/ButtonStyles';
import { BrandedTextLink } from '../../components/LinkStyles';
import {
  marginDefault,
  marginDesktop,
  marginTopDesktop,
  paddingDefault,
  paddingDesktop
} from '../../styles/utils';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import { LottieDiv } from '../../components/Lottie';
import { StyledSpan } from '../../components/TextStyles';

// WelcomeHeader
export const WelcomeHeaderDiv = styled.div`
  ${props => props.theme.bottomDropShadow}
`;

export const HeaderDiv = styled.div`
  align-items: flex-start;
  max-width: 1520px;
  position: relative;
  p {
    margin: 1.5rem 0 0;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    padding: 6rem 2rem;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    padding: 2rem 4rem 6rem;
  }
  @media screen and (min-width: 1024px) {
    padding: 7rem 8rem;
  }
  ${PrimaryButtonLink} {
    width: 28.2rem;
    padding: 1.5rem;
    margin: 1.5rem 0 0 0;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      height: auto;
      margin-left: 1.5rem;
    }
  }
`;

export const GetVerifiedDiv = styled.div`
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    display: flex;
    align-items: center;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 2rem;
    border-left: ${props => props.theme.defaultBorder};
  }
`;

export const HeaderTextDiv = styled.div`
  @media screen and (min-width: 1024px) {
    padding-right: 2rem;
  }
  ${StyledSpan} {
    margin-left: 5px;
  }
`;

export const HeaderContentDiv = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const SectionHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 7rem 20px 10px;
  padding-bottom: 0.6rem;
  @media screen and (max-width: 1023px) {
    border-bottom: 1px solid black;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 768px) {
    margin: ${marginTopDesktop} ${marginDefault} 10px;
  }
  @media screen and (min-width: 1024px) {
    margin: ${marginTopDesktop} ${marginDesktop} 10px;
  }
`;

export const BlueDotDiv = styled.div`
  position: absolute;
  right: -2px;
  top: -4px;
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: ${props => props.theme.brand};
`;

export const ExtraTransactionsLink = styled(BrandedTextLink)`
  display: block;
  margin-top: -50px;
  margin-left: 1rem;
  @media screen and (min-width: 768px) {
    margin-left: 5rem;
  }
  @media screen and (min-width: 1024px) {
    margin-left: 3.5rem;
  }
`;

export const EmptyTransactionsDiv = styled.div`
  ${LottieDiv} {
    height: 100px;
    width: 100px;
    margin: 0 auto;
  }
  svg {
    width: 28px;
    fill: ${props => props.theme.blue};
  }
`;

export const EmptyTransactionsP = styled.p`
  color: ${props => props.theme.dustyGray};
  font-style: italic;
  margin-top: 2.5rem;
`;

export const InProgressBannerContainer = styled.div`
  > div {
    background-color: ${props => props.theme.brand};
    min-height: 148px;
    display: ${props => (props.action ? 'flex' : 'none')};
    padding-top: 35px;
    padding-left: 30px;
    padding-bottom: 30px;
    margin-top: 40px;
    @media screen and (max-width: 1023px) {
      padding-right: 30px;
    }
  }
  max-width: 1520px;
  padding-left: 2rem;
  padding-right: 2rem;
  @media screen and (min-width: 768px) {
    padding-left: ${paddingDefault};
    padding-right: ${paddingDefault};
  }
  @media screen and (min-width: 1024px) {
    padding-left: ${paddingDesktop};
    padding-right: ${paddingDesktop};
    ${props =>
      !props.dashboardScreen &&
      css`
        padding-left: ${paddingDefault};
        padding-right: ${paddingDefault};
      `}
  }
  ${props =>
    !props.dashboardScreen &&
    css`
      @media screen and (min-width: 1440px) {
        padding-left: ${paddingDesktop};
        padding-right: ${paddingDesktop};
      }
    `}
  ${props =>
    props.merchantSupportScreen &&
    css`
      && {
        padding: unset;
        max-width: unset;
      }
    `}
`;

export const InProgressBannerWrapper = styled.div`
  padding-right: ${props => (props.action ? '305px' : '94px')};
  @media screen and (max-width: 1023px) {
    padding-right: 0;
  }
`;

export const InProgressBannerDiv = styled.div`
  display: flex;
`;

export const InProgressBannerImg = styled.img`
  height: 60px;
  padding-right: 20px;
`;

export const InProgressTitleP = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  ${props => props.theme.fontStackBody};
  color: ${props => props.theme.white};
  padding-bottom: 5px;
`;

export const InProgressDescriptionRegularP = styled.p`
  font-size: 1.3rem;
  font-weight: 300;
  ${props => props.theme.fontStackBody};
  color: ${props => props.theme.white};
  line-height: 17.5px;
`;

export const ActionButtonDiv = styled.div`
  width: 193px;
  height: 34px;
`;

export const InProgressDescriptionSpan = styled.span`
  font-size: 1.3rem;
  font-weight: 700;
  ${props => props.theme.fontStackBody};
  color: ${props => props.theme.white};
  line-height: 17.5px;
`;

const WhiteBorderOutlineButtonStyles = css`
  ${outlineButtonStyles}
  color: ${props => props.theme.white};
  box-shadow: 0px 0px 0px 1px ${props => props.theme.white};
  height: 46px;
  width: 220px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 20px;
  &:hover {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.white};
  }`;

export const DashboardBannerActionA = styled.a`
  ${WhiteBorderOutlineButtonStyles}

  @media screen and (min-width: 1440px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 1439px) {
    margin-top: 20px;
  }
  font-size: 1.3rem;
`;

export const DashboardBannerActionLink = styled(Link)`
  ${WhiteBorderOutlineButtonStyles}

  @media screen and (min-width: 1440px) {
    margin-left: 20px;
  }
  @media screen and (max-width: 1439px) {
    margin-top: 20px;
  }
  font-size: 1.3rem;
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 7px;
  width: 12px;
  margin: 15px 15px;
  cursor: pointer;
  fill: ${props => props.theme.white};
`;

export const CompanyDetailsButton = styled(PrimaryButtonLink)`
  font-weight: 600;
  @media screen and (max-width: 767px) {
    && {
      margin: 30px auto 0px;
    }
  }
  @media screen and (min-width: 768px) {
    && {
      margin-left: unset;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  ${props =>
    props.merchantSupportScreen &&
    css`
      && {
        margin: unset;
      }
    `}
`;
