// @flow
import React, { Component } from 'react';
import { withTranslation, TFunction, Trans } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import { requiredField } from '../../../utilities/validations';
import {
  TwoFactorModalContentDiv,
  EnableEmailTwoFactorForm,
  GoogleAuthQrDiv,
  EmphasizeText,
  QrDiv,
  BackupCodeDiv,
  BackupCode,
  BackupInstructionsP,
  BackupCodeH5,
  StyledInputLarge
} from './TwoFactorAuthModalsStyles';

import { showSuccessModal } from '../reducer';
import {
  EnableTwoFactorModalDiv,
  ConfirmationModalButtons,
  ConfirmationModalA
} from '../ModalConductorStyles';
import { defaultSubmitHandler } from '../../../utilities/requests';
import { updateUserTwoFactorMethod } from '../../../App/Company/reducer';
import { ErrorP } from '../../../components/SaveOverlayStyles';
import QRCode from 'qrcode.react';

type Props = {
  closeModal: () => void,
  handleSubmit: (values: any) => void,
  error: ?string,
  qrUri: string,
  backupCode: string,
  t: TFunction,
  lang: string
};

class EnableGoogleAuthModal extends Component<Props> {
  handleConfirmClick = () => {
    this.props.handleSubmit();
  };
  render() {
    const { t } = this.props;
    return (
      <EnableTwoFactorModalDiv>
        <TwoFactorModalContentDiv>
          <h4> {t('verification:twoFactorAuth.google.form.title')}</h4>
          <GoogleAuthQrDiv>
            <QrDiv>
              <QRCode value={this.props.qrUri} size={100} />
            </QrDiv>
            <p>
              <Trans
                t={t}
                i18nKey="verification:twoFactorAuth.google.form.steps.1"
              >
                <EmphasizeText brand bold>
                  1. Scan the QR code{' '}
                </EmphasizeText>
                with your Google Authenticator app.
              </Trans>
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="verification:twoFactorAuth.google.form.steps.2"
              >
                <EmphasizeText brand bold>
                  2. Enter the code{' '}
                </EmphasizeText>
                shown on your device below:
              </Trans>
            </p>
          </GoogleAuthQrDiv>

          <EnableEmailTwoFactorForm onSubmit={this.props.handleSubmit}>
            <Field
              name="otp_code"
              hasPlaceholder
              component={StyledInputLarge}
              placeholder="XXXXXX"
              validate={requiredField}
            />
          </EnableEmailTwoFactorForm>
          <BackupCodeDiv>
            <BackupCode>
              <h5>
                {t('verification:twoFactorAuth.google.form.steps.backup.title')}
              </h5>
              <BackupCodeH5>{this.props.backupCode}</BackupCodeH5>
            </BackupCode>
            <BackupInstructionsP>
              {t('verification:twoFactorAuth.google.form.steps.backup.message')}
            </BackupInstructionsP>
          </BackupCodeDiv>

          {this.props.error && <ErrorP>{this.props.error}</ErrorP>}
        </TwoFactorModalContentDiv>
        <ConfirmationModalButtons>
          <ConfirmationModalA
            onClick={this.handleConfirmClick}
            lang={this.props.lang}
          >
            {t('global:actions.enable2fa')}
          </ConfirmationModalA>
          <ConfirmationModalA
            onClick={this.props.closeModal}
            lang={this.props.lang}
          >
            {t('global:actions.cancel')}
          </ConfirmationModalA>
        </ConfirmationModalButtons>
      </EnableTwoFactorModalDiv>
    );
  }
}

const onSubmitSuccess = (response, dispatch, props) => {
  dispatch(updateUserTwoFactorMethod(response.two_factor_method));
  dispatch(
    showSuccessModal(
      props.t('verification:modal:enableGoogleAuthConfirmation.title'),
      props.t('verification:modal:enableGoogleAuthConfirmation.message'),
      'payment-check'
    )
  );
};

export default reduxForm({
  form: 'EnableGoogleAuthModal',
  onSubmit: defaultSubmitHandler('/two_factor_authentication/enable_2fa'),
  initialValues: { two_factor_method: 'totp' },
  onSubmitSuccess
})(withTranslation(['global', 'verification'])(EnableGoogleAuthModal));
