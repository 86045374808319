export const LOAD_MERCHANT_DOCUMENT_COLLECTION =
  'LOAD_MERCHANT_DOCUMENT_COLLECTION';
export const SET_MERCHANT_DOCUMENT_COLLECTION =
  'SET_MERCHANT_DOCUMENT_COLLECTION';
export const SET_VISIBLE_TOOLTIP_ID = 'SET_VISIBLE_TOOLTIP_ID';
export const STAGE_FOR_DELETE = 'STAGE_FOR_DELETE';
export const NORMALIZE_STAGE_FOR_DELETE = 'NORMALIZE_STAGE_FOR_DELETE';

export const setMerchantDocumentCollection = documents => ({
  type: SET_MERCHANT_DOCUMENT_COLLECTION,
  documents
});
export const loadMerchantDocumentCollection = () => ({
  type: LOAD_MERCHANT_DOCUMENT_COLLECTION
});

export const setVisibleTooltipId = activeTooltip => ({
  type: SET_VISIBLE_TOOLTIP_ID,
  activeTooltip
});

export const stageForDelete = id => ({
  type: STAGE_FOR_DELETE,
  id
});

export const normalizeStageForDelete = () => ({
  type: NORMALIZE_STAGE_FOR_DELETE
});

const initialState = {
  loading: true,
  documents: [],
  stagedForDelete: [],
  activeTooltip: ''
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MERCHANT_DOCUMENT_COLLECTION:
      return {
        ...state,
        documents: [...action.documents],
        loading: false
      };
    case SET_VISIBLE_TOOLTIP_ID:
      return {
        ...state,
        activeTooltip: action.activeTooltip
      };
    case STAGE_FOR_DELETE:
      return {
        ...state,
        stagedForDelete: [...state.stagedForDelete, action.id]
      };
    case NORMALIZE_STAGE_FOR_DELETE:
      return {
        ...state,
        stagedForDelete: []
      };
    default:
      return state;
  }
};
