// @flow
import React, { Component } from 'react';
import { ButtonInput } from './ButtonStyles';
import { SubmitValidationSmall } from './InputStyles';

type P = {|
  value?: string,
  label?: string,
  disabled: boolean,
  error: ?string,
  marginTop: string,
  dataCy?: string
|};

class SubmitButton extends Component<P> {
  static defaultProps = {
    marginTop: '5rem',
    dataCy: ''
  };

  render() {
    return (
      <>
        <ButtonInput
          type="submit"
          data-cy={this.props.dataCy}
          {...this.props}
          marginTop={this.props.marginTop}
        />
        {this.props.error &&
          (Array.isArray(this.props.error) ? (
            this.props.error.map((error, index) => (
              <SubmitValidationSmall
                data-cy="submit-validation-small"
                list
                key={index}
              >
                {error}
              </SubmitValidationSmall>
            ))
          ) : (
            <SubmitValidationSmall data-cy="submit-validation-small">
              {this.props.error}
            </SubmitValidationSmall>
          ))}
      </>
    );
  }
}

export default SubmitButton;
