//@flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { NavbarWrapper, NavButtonAction, NavIcon } from './NavbarStyles';
import { hasSettingsAccess } from '../../utilities/misc';
import { type Permissions } from '../../utilities/types';
import NavbarItem from './NavbarItem';
import { withTranslation, TFunction } from 'react-i18next';
import LanguageDropDown from '../../components/LanguageDropDown/LanguageDropDown';

type Props = {
  desktop?: boolean,
  logout: () => void,
  permissions: Permissions,
  location: Location,
  t: TFunction,
  open: boolean
};
type State = {
  scrolled: boolean
};

class NavbarItems extends Component<Props, State> {
  state = { scrolled: window.pageYOffset };
  componentDidMount() {
    if (this.props.desktop) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }
  componentWillUnmount() {
    if (this.props.desktop) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }
  handleScroll = () => {
    if (window.pageYOffset >= 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <NavbarWrapper
        desktop={this.props.desktop}
        sticky={this.state.scrolled}
        open={this.props.open}
      >
        <NavbarItem
          to="/account"
          borderRight={!this.props.location.pathname.startsWith('/settings')}
          name="account"
          displayName={t('routeNames.account')}
        />
        {this.props.permissions &&
          hasSettingsAccess(this.props.permissions) && (
            <NavbarItem
              to="/settings"
              borderRight={
                !this.props.location.pathname.startsWith('/marketing')
              }
              name="settings"
              displayName={t('routeNames.settings')}
            />
          )}
        <NavbarItem
          to="/marketing"
          name="marketing"
          displayName={t('routeNames.marketing')}
          borderRight={!this.props.location.pathname.startsWith('/support')}
        />
        <NavbarItem to="/support" name="questionmark" iconOnly borderRight />
        <NavButtonAction onClick={this.props.logout}>
          <NavIcon name="logout" />
        </NavButtonAction>
        <LanguageDropDown />
      </NavbarWrapper>
    );
  }
}

export default withRouter(withTranslation(['global'])(NavbarItems));
